import React, { Component } from 'react';
import './SwitchesMap.scss';
import axios from 'axios';
import SwitchesMapLayout from '../SwitchesMapLayout';
import Loading from '../Loading';
const config = require('../../config');

class SwitchesMap extends Component {
  constructor() {
    super();
    this.state = {
      isToggleAvgTempOn: true,
      portsData: {},
      isInDetailPhysicalView: true,
      isInDetailView: false,
      clickedSwitchedData: {},
      clickedCoreSwitchedData: {},
      clickedPortData: {},
      showTooltip: false,
      isPortClicked: false,
      coreSwitchData: [],
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    const token = window.localStorage.getItem('token');
    await this.getData(token, 'Loading');
  };

  componentDidUpdate = async (prevprops) => {
    const token = window.localStorage.getItem('token');
    if (this.props.currentPowerData !== prevprops.currentPowerData) {
      await this.getData(token, 'Updating');
    }
  };

  async getData(token, alertMsg) {
    if (alertMsg === 'Loading') {
      this.setState({ isLoading: true });
    }

    await axios
      .get(`${config.urls.nodeServer}/user/miners`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        this.setState({ portsData: res.data.json_response });
        this.setState({ isLoading: false });
      });

    await axios
      .get(`${config.urls.nodeServer}/user/switches`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        this.setState({ coreSwitchData: res.data });
      });
  }

  render() {
    return (
      <div className="chartsSection">
        <div style={{ top: '-100px', left: '-250px', position: 'absolute' }}>
          {this.state.isLoading && !this.props.isLoading && <Loading />}
        </div>
        <div id="mapContainer">
          <div id="viewContainer">
            <div id="adminLabel">Switch Map</div>
            {this.state.portsData && (
              <SwitchesMapLayout
                portsData={this.state.portsData}
                coreSwitchData={this.state.coreSwitchData}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SwitchesMap;
