import React, { Component } from 'react';
import * as d3 from 'd3';
import './SwitchesMapLegend.scss';

const colorScale = d3.scaleSequential(d3.interpolateRdYlGn).domain([100, 60]);
const performanceColorScale = d3
  .scaleSequential(d3.interpolateRdYlGn)
  .domain([0, 100]);

class SwitchesMapLegend extends Component {
  constructor() {
    super();
    this.state = {
      isToggleAvgTempOn: true,
    };
  }

  componentDidMount() {
    this.drawLegend();
  }

  componentDidUpdate(prevprops) {
    if (this.props.title !== prevprops.title) {
      this.drawLegend();
    }
  }

  drawLegend() {
    let data = [];
    d3.select('.switchMapLegend').selectAll('svg').remove();
    if (this.props.title.includes('Performance')) {
      data = [
        { color: performanceColorScale(0), value: 0 },
        { color: performanceColorScale(20), value: 20 },
        { color: performanceColorScale(40), value: 40 },
        { color: performanceColorScale(60), value: 60 },
        { color: performanceColorScale(80), value: 80 },
        { color: performanceColorScale(100), value: 100 },
      ];
    } else {
      data = [
        { color: colorScale(0), value: 0 },
        { color: colorScale(20), value: 20 },
        { color: colorScale(40), value: 40 },
        { color: colorScale(60), value: 60 },
        { color: colorScale(80), value: 80 },
        { color: colorScale(100), value: 100 },
      ];
    }
    var extent = d3.extent(data, (d) => d.value);
    var padding = 9;
    var width = 320;
    var innerWidth = width - padding * 2;
    var barHeight = 8;
    var height = 28;

    var xScale = d3.scaleLinear().range([0, innerWidth]).domain(extent);

    var xTicks = data.map((d) => d.value);

    var xAxis = d3
      .axisBottom(xScale)
      .tickSize(barHeight * 2)
      .tickValues(xTicks);

    var svg = d3
      .select('.switchMapLegend')
      .append('svg')
      .attr('width', width)
      .attr('height', height);
    var g = svg.append('g').attr('transform', 'translate(' + padding + ', 0)');

    var defs = svg.append('defs');
    var linearGradient = defs.append('linearGradient').attr('id', 'myGradient');
    linearGradient
      .selectAll('stop')
      .data(data)
      .enter()
      .append('stop')
      .attr(
        'offset',
        (d) => ((d.value - extent[0]) / (extent[1] - extent[0])) * 100 + '%'
      )
      .attr('stop-color', (d) => d.color);

    g.append('rect')
      .attr('width', innerWidth)
      .attr('height', barHeight)
      .style('fill', 'url(#myGradient)');

    g.append('g').call(xAxis).select('.domain').remove();
  }

  render() {
    return (
      <div className="legendContainer">
        <div className="legendTitle">{this.props.title}</div>
        <div className="switchMapLegend"></div>
      </div>
    );
  }
}

export default SwitchesMapLegend;
