import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import TextInput from '../../TextInput';
import SelectInput from '../../SelectInput';
import { connect } from 'react-redux';
import './AddOrEditAlertSetting.scss';
import schema from './Schema';
import axios from 'axios';
import { ALERT_TYPE } from '../../../constants';
const config = require('../../../config');

class AddOrEditAlertSettingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: props.data ? props.data.description : '',
      alertType: props.data ? props.data.alert_type : null,
      timeThresholdOptions: [
        { value: '1 hours', label: 'Last hour' }, // option format to be optimized... JJ
      ],
      threshold: 'X', // for display purpose only
    };
  }

  componentDidUpdate = (prevprops) => {
    if (this.props.data !== prevprops.data) {
      this.setState({
        description: this.props.data ? this.props.data.description : '',
        alertType: this.props.data ? this.props.data.alert_type : null,
      });
    }
  };

  sendRequest = async (formValues) => {
    let newAlertSetting = {
      alert_name: formValues.alertName,
      alert_type: formValues.alertType,
      hashrate_threshold: formValues.hashrateThreshold,
      worker_threshold: formValues.workerThreshold,
      time_threshold: formValues.timeThreshold,
      notification_channels: formValues.notificationChannels,
      enabled: formValues.enabled,
    };
    if (this.props.modalType === 'add') {
      await this.createAlertSettings(newAlertSetting);
    } else if (this.props.modalType === 'edit') {
      await this.updateAlertSettings(newAlertSetting);
    }
  };

  cleanAndClose = () => {
    this.setState({
      threshold: 'X',
      description: this.props.data ? this.props.data.description : '',
      alertType: this.props.data ? this.props.data.alert_type : null,
    });
    this.props.handleCloseModal();
  };

  updateAlertDescription = (fieldType, fieldValue) => {
    let alertType = this.state.alertType;
    let threshold = this.state.threshold;
    let description = this.state.description;
    if (fieldType === 'alertType') {
      alertType = fieldValue;
    }
    if (fieldType === 'threshold') {
      threshold = fieldValue;
    }
    // composing the description string
    switch (alertType) {
      case ALERT_TYPE.BLOCK_WON:
        description = 'Any block won';
        break;
      // case ALERT_TYPE.PAYOUT_SENT:
      //   description = 'Any payout sent';
      //   break;
      case ALERT_TYPE.ONLINE_WORKERS:
      case ALERT_TYPE.OFFLINE_WORKERS:
      case ALERT_TYPE.HASHRATE:
        description = `Any pool's ${alertType.toLowerCase()} drops greater than ${threshold}% in last hour`;
        break;
      default:
        description = '';
        break;
    }
    this.setState({
      alertType,
      description,
      threshold,
    });
  };

  createAlertSettings = async (settings) => {
    await axios
      .post(`${config.urls.nodeServer}/user/alerts/settings`, settings, {
        headers: { Authorization: this.props.auth.token },
      })
      .then((res) => {
        this.props.handleResponseSuccess(res, 'create');
      })
      .catch((error) => {
        this.props.handleResponseError(error, 'create');
      });
  };

  updateAlertSettings = async (settings) => {
    await axios
      .put(`${config.urls.nodeServer}/user/alerts/settings`, settings, {
        headers: { Authorization: this.props.auth.token },
      })
      .then((res) => {
        this.props.handleResponseSuccess(res, 'edit');
      })
      .catch((error) => {
        this.props.handleResponseError(error, 'edit');
      });
  };

  render() {
    let initValue = this.props.data || {};
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        className="reactModalOverRide"
        style={{
          overlay: {
            zIndex: 10,
            backgroundColor: '#FFF',
            height: 600,
            width: 600,
            border: '1px solid #4F76DB',
            boxShadow: '0 0 3px 0 #CEDCF2',
            borderRadius: 5,
            boxSizing: 'border-box',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className="modalHeader">
          <span>
            {this.props.modalType === 'add'
              ? 'Add Alert Setting'
              : 'Edit Alert Setting'}
          </span>
          <img
            src="/addAlertSettingPopUp.png"
            id="addAlertSettingIcon"
            alt=""
          ></img>
        </div>

        <Formik
          initialValues={{
            alertName: initValue.alert_name || '',
            alertType: initValue.alert_type || '',
            description: initValue.description || '',
            hashrateThreshold: initValue.hashrate_threshold || '',
            workerThreshold: initValue.worker_threshold || '',
            timeThreshold: initValue.time_threshold || '1 hours',
            notificationChannels: initValue.notification_channels || [],
            phoneNumber: this.props.auth.phone || '',
            enabled: initValue.enabled || false,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            await this.sendRequest(values);
            resetForm();
            setSubmitting(false);
            this.cleanAndClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="modalFields">
                <div className="alertInputContainer">
                  <TextInput
                    name="alertName"
                    controlId="alert_name"
                    label="Name *"
                  />
                </div>
                <SelectInput
                  name="alertType"
                  options={
                    this.props.modalType === 'add'
                      ? this.props.userOptions
                      : [this.props.alertTypeOptions[initValue.alert_type]]
                  }
                  disabled={this.props.modalType === 'add' ? false : true}
                  onChange={(e) => {
                    this.updateAlertDescription('alertType', e.target.value);
                  }}
                  controlId="alert_type"
                  label="Type *"
                  placeholder="Please select..."
                />
              </div>
              <div
                className="modalFields"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label className="fieldLabel">Description</label>
                <span className="descriptionValue">
                  {this.state.description}
                </span>
              </div>
              <div className="modalFields">
                {this.state.alertType === ALERT_TYPE.HASHRATE && (
                  <div className="alertInputContainer">
                    <TextInput
                      name="hashrateThreshold"
                      controlId="hashrate_threshold"
                      label="Hashrate Threshold (%) *"
                      onBlur={(e) => {
                        this.updateAlertDescription(
                          'threshold',
                          e.target.value
                        );
                      }}
                    />
                  </div>
                )}
                {[
                  ALERT_TYPE.OFFLINE_WORKERS,
                  ALERT_TYPE.ONLINE_WORKERS,
                ].includes(this.state.alertType) && (
                  <TextInput
                    name="workerThreshold"
                    controlId="worker_threshold"
                    label="Worker Threshold (%) *"
                    onBlur={(e) => {
                      this.updateAlertDescription('threshold', e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="modalFields">
                {![ALERT_TYPE.BLOCK_WON].includes(this.state.alertType) && (
                  <div className="alertInputContainer">
                    <SelectInput
                      name="timeThreshold"
                      options={this.state.timeThresholdOptions}
                      disabled={true}
                      controlId="time_threshold"
                      label="Time Threshold"
                    />
                  </div>
                )}
                <div className="notificationContainer">
                  <div role="group" aria-labelledby="checkbox-group">
                    <div className="alertFieldLabel">Notification Channels</div>
                    <label className="notificationOption">
                      <Field
                        type="checkbox"
                        name="notificationChannels"
                        className="notificationCheckbox"
                        value="SMS"
                      />
                      SMS
                    </label>
                    <label className="notificationOption">
                      <Field
                        type="checkbox"
                        name="notificationChannels"
                        className="notificationCheckbox"
                        value="Email"
                      />
                      Email
                    </label>
                  </div>
                  <div className="notificationErrors">
                    {errors.notificationChannels || errors.phoneNumber}
                  </div>
                </div>
              </div>
              <div className="modalFields">
                <label>
                  <div className="fieldLabel">Enable</div>
                  <Field type="checkbox" name="enabled" />
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  marginRight: 24,
                  marginTop: 25,
                  gap: 12,
                }}
              >
                <Button
                  variant="outline-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="filterBtnModal"
                >
                  Save
                </Button>
                <Button onClick={this.cleanAndClose} className="filterBtnModal">
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(AddOrEditAlertSettingModal);
