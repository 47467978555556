import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import './DeletePoolModal.scss';
import axios from 'axios';
const config = require('../../../config');

function DeletePoolModal(props) {
  const token = useSelector((state) => state.auth.token);

  async function handleDeleteRow(data) {
    await axios
      .delete(`${config.urls.nodeServer}/admin/pools`, {
        headers: { authorization: token },
        data: data,
      })
      .then(() => {
        props.onHide();
        swal({
          title: 'Successfully deleted pool',
          onClose: async () => {
            window.location.reload(false);
          },
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: 'Failed to delete pool',
        });
      });
  }

  return (
    <Modal
      {...props}
      data-backdrop="static"
      data-keyboard="false"
      centered
      dialogClassName="deletePoolModal"
    >
      <Modal.Header>
        <Modal.Title>
          Are you sure you want to delete pool {props.data.pool_name} ?
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          If you delete this pool, you will permanently lose all data related to
          this pool. We can't recover them once you delete.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDeleteRow(props.data)}>
          Delete Pool
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeletePoolModal;
