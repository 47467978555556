import React, { Component } from 'react';
import {
  getBlockseerWorkerStatus,
  getCustomerPools,
} from '../../../../actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

class BlockseerWorkerStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allWorkers: 0,
      healthyWorkers: 0,
      offlineWorkers: 0,
      loading: false,
    };
  }

  componentDidMount = async () => {
    if (!this.props.pool_status) {
      this.setState({ loading: true });
      await this.props.getBlockseerWorkerStatus(
        this.props.auth.token,
        this.props.selectedCustomer,
        this.props.selectedPool
      );
    }
    this.setState({
      allWorkers:
        this.props.pool_status !== 0
          ? this.props.pool_status.data.allWorkers
          : 0,
      healthyWorkers:
        this.props.pool_status !== 0
          ? this.props.pool_status.data.healthyWorkers
          : 0,
      offlineWorkers:
        this.props.pool_status !== 0
          ? this.props.pool_status.data.offlineWorkers
          : 0,
      loading: false,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.selectedCustomer !== this.props.selectedCustomer ||
      prevProps.selectedPool !== this.props.selectedPool
    ) {
      this.setState({ loading: true });
      await this.props.getBlockseerWorkerStatus(
        this.props.auth.token,
        this.props.selectedCustomer,
        this.props.selectedPool
      );
      this.setState({
        allWorkers:
          this.props.pool_status !== 0
            ? this.props.pool_status.data.allWorkers
            : 0,
        healthyWorkers:
          this.props.pool_status !== 0
            ? this.props.pool_status.data.healthyWorkers
            : 0,
        offlineWorkers:
          this.props.pool_status !== 0
            ? this.props.pool_status.data.offlineWorkers
            : 0,
        loading: false,
      });
    }
  };

  render() {
    const { content } = this.props;
    return (
      <div>
        <p className="infoBoxLabel">{content.cCompanyName} Worker Status</p>
        <div className="tagContainer">
          {this.props.selectedCustomer &&
            this.props.selectedCustomer.map((customer) => {
              return (
                <div className="filterTag customerTag">{customer.value}</div>
              );
            })}
          {this.props.selectedPool &&
            this.props.selectedPool.map((pool) => {
              return <div className="filterTag poolTag">{pool.value}</div>;
            })}
        </div>
        <div className="container">
          <div className="item">
            <div className="itemLabelBlockseer">All Workers</div>
            <div className="value itemValue">
              {this.state.loading ? (
                <CircularProgress size="1.0vw" />
              ) : (
                this.state.allWorkers.length
              )}
            </div>
          </div>
          <div className="item">
            <div className="itemLabelBlockseer">Healthy Workers</div>
            <div className="value itemValue">
              {this.state.loading ? (
                <CircularProgress size="1.0vw" />
              ) : (
                this.state.healthyWorkers.length
              )}
            </div>
            <div className="colorBar green"></div>
          </div>
          <div className="item">
            <div className="itemLabelBlockseer">Offline Workers</div>
            <div className="value itemValue">
              {this.state.loading ? (
                <CircularProgress size="1.0vw" />
              ) : (
                this.state.offlineWorkers.length
              )}
            </div>
            <div className="colorBar gray"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, pool_status, content }) => {
  return {
    auth,
    pool_status,
    content,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, {
  getCustomerPools,
  getBlockseerWorkerStatus,
})(BlockseerWorkerStatus);
