import React, { Component } from 'react';
import './SwitchesMapLayout.scss';
import MapLayout from './MapLayout';
import * as d3 from 'd3';
import SwitchesMapDetailsView from '../SwitchesMapDetailsView';
import SwitchMapLegend from '../SwitchesMapLegend';

const colorScale = d3.scaleSequential(d3.interpolateRdYlGn).domain([100, 60]);
const performanceColorScale = d3
  .scaleSequential(d3.interpolateRdYlGn)
  .domain([0, 100]);

class Switch extends Component {
  render() {
    return (
      <div
        className="Switch"
        style={this.props.style}
        onClick={this.props.handleOnClickedSwitch}
      >
        {this.props.name} ({this.props.count || 0})
      </div>
    );
  }
}

class Container extends Component {
  render() {
    const style = {
      gridTemplateRows: `repeat(${this.props.rows ? this.props.rows : 4},1fr)`,
      gridTemplateColumns: `repeat(${
        this.props.columns ? this.props.columns : 1
      },1fr)`,
    };
    const type = `Container${this.props.type ? ` ${this.props.type}` : ''}`;
    return (
      <div
        className={type}
        style={this.props.style}
        onClick={this.props.handleOnClickedSwitch}
      >
        <div className="Container-Title">{this.props.title}</div>
        <div className="Container-Content" style={style}>
          {this.props.contents}
        </div>
      </div>
    );
  }
}

class SwitchesMapLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portsData: props.portsData,
      coreSwitchData: props.coreSwitchData,
      isInDetailView: false,
      isToggleAvgTempOn: true,
      clickedSwitchedData: {},
      clickedCoreSwitchedData: {},
      isCoreSwitchClicked: false,
      selectedFilter: 'avgTemp',
    };
    this.handleOnClickedSwitch = this.handleOnClickedSwitch.bind(this);
    this.handleSelectedFilter = this.handleSelectedFilter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      portsData: nextProps.portsData,
      coreSwitchData: nextProps.coreSwitchData,
    });
  }

  handleSelectedFilter(e) {
    this.setState({ selectedFilter: e.target.value });
  }

  getSwitchData(switchName, phase) {
    const { selectedFilter, portsData } = this.state;
    if (this.state.portsData) {
      let switchColor;
      let temperatureArr = [];
      let performanceArr = [];
      let minerArr = [];
      const phaseNumber = phase.match(/\d+/g);
      let switchId =
        phaseNumber[0] !== '1'
          ? `YCL-${phaseNumber[0]}-${switchName}`
          : `YCL-${switchName}`;
      if (portsData && portsData[switchId]) {
        const switchObj = portsData[switchId];
        Object.keys(switchObj).forEach((key, index) => {
          minerArr.push(switchObj);
          if (
            switchObj[key].hashrate_performance &&
            switchObj[key].hashrate_performance !== 'N/A' &&
            !switchObj[key].no_api_response
          ) {
            performanceArr.push(Number(switchObj[key].hashrate_performance));
          }
          if (
            switchObj[key].temperature &&
            switchObj[key].temperature !== 'N/A' &&
            !switchObj[key].no_api_response
          ) {
            temperatureArr.push(Number(switchObj[key].temperature));
          }
        });
      }

      if (temperatureArr.length > 0) {
        switch (selectedFilter) {
          case 'maxTemp':
            switchColor = colorScale(Math.max(...temperatureArr));
            break;
          case 'lowestPerformance':
            switchColor = performanceColorScale(Math.min(...performanceArr));
            break;
          case 'avgPerformance':
            const totalPerformance = performanceArr.reduce((a, b) => {
              return a + b;
            });
            switchColor = performanceColorScale(
              totalPerformance / performanceArr.length
            );
            break;
          case 'avgTemp':
          default:
            const totalTemperature = temperatureArr.reduce((a, b) => {
              return a + b;
            });
            switchColor = colorScale(totalTemperature / temperatureArr.length);
            break;
        }
      } else if (
        temperatureArr.length < 1 &&
        performanceArr.length < 1 &&
        minerArr.length > 0
      ) {
        switchColor = '#C0C0C0';
      }
      return {
        switchId: switchId,
        phaseNumber: Number(phaseNumber[0]),
        miners: minerArr.length,
        temperature: switchColor,
      };
    }
  }

  handleOnClickedSwitch(switchId, phaseNumber, isCoreSwitch) {
    this.setState({ isInDetailView: true });
    if (isCoreSwitch) {
      this.state.coreSwitchData.forEach((coreSwitch) => {
        if (coreSwitch.mine_controller === switchId) {
          this.setState({
            clickedCoreSwitchedData: coreSwitch,
            isCoreSwitchClicked: true,
          });
        }
      });
    } else {
      const { portsData } = this.state;
      const switchIdChunk = switchId.match(/[a-zA-Z]+/g);
      const switchIdLettersOnly =
        phaseNumber !== 1
          ? switchIdChunk[0] + '-' + phaseNumber + '-' + switchIdChunk[1]
          : switchIdChunk[0] + '-' + switchIdChunk[1];
      if (portsData) {
        const clickedSwitchedData = {};
        clickedSwitchedData[`${switchIdLettersOnly}1`] =
          portsData[`${switchIdLettersOnly}1`];
        clickedSwitchedData[`${switchIdLettersOnly}2`] =
          portsData[`${switchIdLettersOnly}2`];
        clickedSwitchedData[`${switchIdLettersOnly}3`] =
          portsData[`${switchIdLettersOnly}3`];
        clickedSwitchedData[`${switchIdLettersOnly}4`] =
          portsData[`${switchIdLettersOnly}4`];
        this.setState({ clickedSwitchedData, isCoreSwitchClicked: false });
      }
    }
  }

  traverse(component, hierarchy) {
    if (component.type === 'Switch') {
      const switchData = this.getSwitchData(component.title, hierarchy.Phase);
      const style = {
        backgroundColor:
          switchData.temperature || switchData.temperature === 0
            ? switchData.temperature
            : '#eaeaea',
        color:
          switchData.temperature || switchData.temperature === 0
            ? 'black'
            : 'rgba(0, 0, 0, 0.4)',
        cursor: 'pointer',
      };

      return (
        <Switch
          name={component.title}
          style={style}
          count={switchData.miners}
          switchData={switchData}
          handleOnClickedSwitch={() =>
            this.handleOnClickedSwitch(
              switchData.switchId,
              switchData.phaseNumber,
              false
            )
          }
        />
      );
    } else {
      hierarchy[component.type] = component.title;
      const contents = (component.contents || []).map((content) => {
        return this.traverse(content, hierarchy);
      });

      const placement = component.placement;
      const style = {};
      if (
        component.type === 'Pod' &&
        placement &&
        placement.row === 2 &&
        placement.row_span === 1 &&
        placement.column === 1 &&
        placement.column_span === 1
      ) {
        style['marginTop'] = '20px';
      }
      style['gridRow'] = placement
        ? `${placement.row}/span ${placement.row_span}`
        : null;
      style['gridColumn'] = placement
        ? `${placement.column}/span ${placement.column_span}`
        : null;

      const layout = component.layout;
      return (
        <Container
          type={component.type}
          // key={component.title+component.type+component.placement}
          title={component.title}
          columns={layout ? layout.columns : null}
          rows={layout ? layout.rows : null}
          style={style}
          contents={contents}
          handleOnClickedSwitch={() => {
            return component.type === 'Core-area'
              ? this.handleOnClickedSwitch(component.switchId, 1, true)
              : null;
          }}
        />
      );
    }
  }

  render() {
    const { portsData, isInDetailView, selectedFilter } = this.state;
    return (
      <div className="mapContainer">
        {!isInDetailView ? (
          <div className="radioBtn">
            <span>Show By: </span>
            <form>
              <label for="avgTemp" className="radio">
                <input
                  type="radio"
                  name="filterOptions"
                  className="hidden"
                  id="avgTemp"
                  value="avgTemp"
                  checked={this.state.selectedFilter === 'avgTemp'}
                  onChange={this.handleSelectedFilter}
                />
                <span className="label"></span>
                <span
                  className="labelText"
                  isChecked={selectedFilter === 'avgTemp' ? 'true' : 'false'}
                >
                  Average Temperature
                </span>
              </label>
              <label for="maxTemp" className="radio">
                <input
                  type="radio"
                  name="filterOptions"
                  className="hidden"
                  id="maxTemp"
                  value="maxTemp"
                  checked={this.state.selectedFilter === 'maxTemp'}
                  onChange={this.handleSelectedFilter}
                />
                <span className="label"></span>
                <span
                  className="labelText"
                  isChecked={selectedFilter === 'maxTemp' ? 'true' : 'false'}
                >
                  Highest Temperature
                </span>
              </label>
              <label for="avgPerformance" className="radio">
                <input
                  type="radio"
                  name="filterOptions"
                  className="hidden"
                  id="avgPerformance"
                  value="avgPerformance"
                  checked={this.state.selectedFilter === 'avgPerformance'}
                  onChange={this.handleSelectedFilter}
                />
                <span className="label"></span>
                <span
                  className="labelText"
                  isChecked={
                    selectedFilter === 'avgPerformance' ? 'true' : 'false'
                  }
                >
                  Average Performance
                </span>
              </label>
              <label for="lowestPerformance" className="radio">
                <input
                  type="radio"
                  name="filterOptions"
                  className="hidden"
                  id="lowestPerformance"
                  value="lowestPerformance"
                  checked={this.state.selectedFilter === 'lowestPerformance'}
                  onChange={this.handleSelectedFilter}
                />
                <span className="label"></span>
                <span
                  className="labelText"
                  isChecked={
                    selectedFilter === 'lowestPerformance' ? 'true' : 'false'
                  }
                >
                  Lowest Performance
                </span>
              </label>
            </form>
          </div>
        ) : (
          <div
            className="appBtn selectedBtn"
            style={{
              margin: '10px 0px 40px 15px',
              width: '100px',
              textAlign: 'center',
            }}
            onClick={() => {
              this.setState({
                isInDetailView: false,
                showTooltip: false,
                isInDetailPhysicalView: true,
                clickedSwitchedData: {},
                clickedCoreSwitchedData: {},
              });
            }}
          >
            <span>
              <i
                className="fas fa-arrow-left"
                style={{ marginRight: '10px' }}
              ></i>
              Back
            </span>
          </div>
        )}
        <SwitchMapLegend
          title={
            selectedFilter.includes('Performance')
              ? 'Performance (%)'
              : 'Temperature (°C)'
          }
        />
        {Object.entries(portsData).length !== 0 && !isInDetailView ? (
          <div className="gridMap">{this.traverse(MapLayout, {})}</div>
        ) : (
          <SwitchesMapDetailsView
            clickedSwitchedData={this.state.clickedSwitchedData}
            clickedCoreSwitchedData={this.state.clickedCoreSwitchedData}
            isCoreSwitchClicked={this.state.isCoreSwitchClicked}
            isInDetailView={this.state.isInDetailView}
            selectedFilter={this.state.selectedFilter}
            updateLegend={this.handleSelectedFilter}
          />
        )}
      </div>
    );
  }
}

export default SwitchesMapLayout;
