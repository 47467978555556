import React, { Component } from 'react';
import './Reward.scss';
import { connect } from 'react-redux';
import { getPoolRewards, getPoolNames } from '../../../actions';
import CircularProgress from '@material-ui/core/CircularProgress';

class Reward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalReward: null,
      lastFifteenMinutes: null,
      lastHour: null,
      lastDay: null,
      payoutThreshold: null,
      totalBalance: null,
      amountEligible: null,
    };
  }

  componentDidUpdate = async () => {
    if (
      this.props.pool_rewards &&
      this.props.pool_names &&
      !this.state.customer_pools
    ) {
      await this.setData();
    }
  };

  componentDidMount = async () => {
    await this.props.getPoolRewards(this.props.auth.token);
    await this.props.getPoolNames(this.props.auth.token);
    if (this.props.pool_names && this.props.pool_rewards) {
      await this.setData();
    }
  };

  setData = async () => {
    this.setState({
      customer_pools: this.props.pool_names,
      totalReward: '0',
      lastFifteenMinutes: '0',
      lastHour: '0',
      lastDay: '0',
      payoutThreshold: '0',
      totalBalance: '0',
      amountEligible: '0',
    });
    const selected = this.props.pool_rewards[0];
    let selectedRewards = selected[Object.keys(selected)[0]];
    Object.keys(selectedRewards).forEach((key) => {
      if (selectedRewards[key] !== null) {
        this.setState({
          [key]: selectedRewards[key],
        });
      }
    });
  };

  formatRewards = (reward, convertToBTC = false) => {
    if (convertToBTC && reward !== 'N/A') {
      // Convert Satoshi to BTC
      return reward / 100000000;
    }
    return reward;
  };

  onDropdownSelected = async (e) => {
    this.setState({
      totalReward: null,
      lastFifteenMinutes: null,
      lastHour: null,
      lastDay: null,
      payoutThreshold: null,
      totalBalance: null,
      amountEligible: null,
    });
    await this.props.getPoolRewards(this.props.auth.token, e.target.value);
    if (this.props.pool_names && this.props.pool_rewards) {
      await this.setData();
    }
  };

  render() {
    return (
      <div className="infoBox">
        <p className="infoBoxLabel">Reward</p>
        <select id="mySelect" onChange={this.onDropdownSelected}>
          {this.state.customer_pools &&
            this.state.customer_pools.map((poolName, index) => (
              <option key={index}>{poolName}</option>
            ))}
        </select>
        <p className="p12 mainLabel">Total Balance</p>
        <div className="value mainValue">
          {this.state['totalBalance'] === null ? (
            <CircularProgress size="1.5vw" />
          ) : (
            this.formatRewards(this.state['totalBalance'], true) + ' BTC'
          )}
        </div>
        <hr style={{ marginTop: '40px' }} />

        <div style={{ marginTop: '30px' }}>
          <div className="flexSpaceBetween">
            <p className="p12">Payout Threshold</p>
            <div className="value">
              {this.state['payoutThreshold'] === null ? (
                <CircularProgress size="1vw" />
              ) : (
                this.formatRewards(this.state['payoutThreshold']) + ' BTC'
              )}
            </div>
          </div>
          <div className="flexSpaceBetween">
            <p className="p12">Total Reward Earned</p>
            <div className="value">
              {this.state['totalReward'] === null ? (
                <CircularProgress size="1vw" />
              ) : (
                this.formatRewards(this.state['totalReward'], true) + ' BTC'
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  customer_pools,
  pool_rewards,
  pool_names,
}) => {
  return {
    auth,
    customer_pools,
    pool_rewards,
    pool_names,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, { getPoolRewards, getPoolNames })(
  Reward
);
