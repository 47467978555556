import React, { Component } from 'react';
import loadingIcon from '../../assets/loadinganimation.gif';
import styled from 'styled-components';

const LoadingIcon = styled.img`
  position: absolute;
  transition: all 0.15s;
  z-index: 100000;
  left: ${(window.innerWidth - 250 + 200 + 64) / 2}px;
  top: ${(window.innerHeight - 70 - 234) / 2}px;
  width: 200px;
  @media (max-width: 1200px) {
    width: 150px;
    left: ${(window.innerWidth - 150 + 150) / 2}px;
  }
`;

class Loading extends Component {
  render() {
    return <LoadingIcon src={loadingIcon} alt="loading..." />;
  }
}

export default Loading;
