import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './LoginModal.scss';
import { connect } from 'react-redux';
import {
  loginRequest,
  tokenLoginRequest,
  lockedAccountRequest,
  getStrapiContent,
} from './actions';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import history from './history';
import hidePassword from './assets/Password-HidePassword.png';
import showPassword from './assets/Password-showPassword.png';
import swal from 'sweetalert2';
import MfaModal from './MfaModal';
import ReCAPTCHA from 'react-google-recaptcha';

class Modal extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      invalidLogin: false,
      showPassword: false,
      showMfaModal: false,
      loginData: {},
      captchaValue: null,
      content: {},
    };
  }

  componentDidMount = async () => {
    await this.props.getStrapiContent(true);
    this.setState({
      content: this.props.content,
    });
    const saved_token = window.localStorage.getItem('token');
    if (!get(this.props, 'auth.token', false) && saved_token !== null) {
      const result = await this.props.tokenLoginRequest(saved_token);
      if (result) {
        if (window.localStorage.getItem('url')) {
          history.push(window.localStorage.getItem('url'));
        } else {
          history.push('/mineManagement/home');
        }
      }
    }
  };

  onCaptchaChange = (value) => {
    this.setState({
      captchaValue: value,
    });
  };

  validateLoginForm = () => {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      (this.state.captchaValue !== null ||
        (process.env.REACT_APP_ENV !== 'production' &&
          process.env.REACT_APP_ENV !== 'staging' &&
          process.env.REACT_APP_ENV !== 'beta' &&
          process.env.REACT_APP_ENV !== 'marapool'))
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleLoginSubmit = async (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    try {
      const response = await loginRequest(username, password);
      if (response.result && response.response.status === 200) {
        this.setState({
          showMfaModal: true,
          invalidLogin: false,
          loginData: response.response.data,
        });
      } else if (
        !response.result &&
        response.response.response.status === 403
      ) {
        this.props.lockedAccountRequest();
      } else if (
        !response.result &&
        response.response.response.status === 401
      ) {
        this.setState({ invalidLogin: true });
      } else {
        throw new Error('Server error');
      }
    } catch (err) {
      swal({
        title: 'Server error, please try again later',
      });
    }
  };

  render() {
    const { content } = this.state;
    return (
      <div>
        <Helmet>
          <title>{content.cCompanyName}</title>
          <link rel="icon" type="image/png" sizes="16x16" href={content.cFavicon} />
        </Helmet>
        {!document.documentMode && (
          <div id="customer-login">
            <div>
              {content.cLogo && (
                <img
                  src={content.cLogo}
                  alt="Logo-full-cropped"
                  className="logoFullLogin"
                />
              )}
            </div>
            <div className="input-fields">
              {!this.state.showMfaModal && (
                <Form>
                  <Form.Group controlId="username">
                    <Form.Label>
                      <p className="p20">Username</p>
                    </Form.Label>
                    <Form.Control
                      autoFocus
                      type="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      invalidLogin={this.state.invalidLogin ? 'true' : 'false'}
                    />
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>
                      <p className="p20">Password</p>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handleChange}
                        invalidLogin={
                          this.state.invalidLogin ? 'true' : 'false'
                        }
                      />
                      <InputGroup.Append>
                        <InputGroup.Text
                          id="passwordHideShowIcon"
                          validateForm={
                            this.state.username.length > 0 &&
                            this.state.password.length > 0 &&
                            !this.state.invalidLogin
                              ? 'true'
                              : 'false'
                          }
                          invalidLogin={
                            this.state.invalidLogin ? 'true' : 'false'
                          }
                          onClick={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                        >
                          <img
                            src={
                              this.state.showPassword
                                ? hidePassword
                                : showPassword
                            }
                            alt="passwordHideShowIcon"
                          />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: !this.state.invalidLogin
                          ? 'flex-end'
                          : 'space-between',
                        height: '41px',
                      }}
                    >
                      {this.state.invalidLogin && (
                        <p className="errorText">
                          Incorrect Username or Password
                        </p>
                      )}
                      <Form.Text
                        className="text-muted"
                        style={{ textAlign: 'right' }}
                      >
                        <Link className="link" to="/requestResetPassword">
                          Forgot your password?
                        </Link>
                      </Form.Text>
                    </div>
                  </Form.Group>
                  {(process.env.REACT_APP_ENV === 'production' ||
                    process.env.REACT_APP_ENV === 'staging' ||
                    process.env.REACT_APP_ENV === 'beta' ||
                    process.env.REACT_APP_ENV === 'marapool') && (
                    <ReCAPTCHA
                      sitekey="6LczsnsaAAAAAL9Q0aHTd-062Uf14VGdMCRD6--2"
                      onChange={this.onCaptchaChange}
                    />
                  )}
                  <Button
                    type="submit"
                    disabled={!this.validateLoginForm()}
                    onClick={this.handleLoginSubmit}
                    className="loginButton"
                  >
                    Sign In
                  </Button>
                </Form>
              )}
              {this.state.showMfaModal && (
                <MfaModal loginData={this.state.loginData} />
              )}
            </div>
            <div id="label">
              <p>
                Do you have any questions? &nbsp;
                <a className="link" href="https://dmgblockchain.com/contact">
                  Contact Us
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, content }) => {
  return {
    auth,
    content,
  };
};

export default connect(mapStateToProps, {
  loginRequest,
  tokenLoginRequest,
  lockedAccountRequest,
  getStrapiContent,
})(Modal);
