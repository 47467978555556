export const ALERT_TYPE = {
  BLOCK_WON: 'Block won',
  ONLINE_WORKERS: 'Online workers',
  OFFLINE_WORKERS: 'Offline workers',
  HASHRATE: 'Hashrate',
};

export const ALERT_TYPES = [
  ALERT_TYPE.BLOCK_WON,
  ALERT_TYPE.ONLINE_WORKERS,
  ALERT_TYPE.OFFLINE_WORKERS,
  ALERT_TYPE.HASHRATE,
];

export const ERROR_MSG =
  'There is an error processing your request, please try again later.';

export const STRAPI_CUSTOMERS = {
  DMG: '6140e5ce7637f871e400fda5',
  TERRAPOOL: '6140e8687637f871e400fda8',
  MARAPOOL: '614231c00923e075e8604c6e',
};
