import React, { Component } from 'react';
import { connect } from 'react-redux';
import CanDisplay from '../../CanDisplay';
import './EditPoolSubMenu.scss';

class EditPoolSubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: false,
    };
  }

  render() {
    const remainder = this.props.poolData.index % this.props.tablePageSize;
    return (
      <div>
        <div
          className="editPoolSubMenu"
          style={{
            top:
              this.props.tablePage === 0 &&
              this.props.poolData.index < this.props.tablePageSize
                ? `calc(70px + 37px* ${this.props.poolData.index})`
                : `calc(70px + 37px* ${remainder})`,
          }}
        >
          <CanDisplay
            rules={this.props.rules}
            perform="edit_pools"
            yes={() => (
              <button
                id="editPoolBtn"
                onClick={() => {
                  this.props.openEditPoolModal();
                }}
              >
                Edit Pool
              </button>
            )}
          />
          <button
            id="deletePoolBtn"
            onClick={() => {
              this.props.openDeletePoolModal();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, rules }) => {
  return {
    auth,
    rules,
  };
};

export default connect(mapStateToProps, {})(EditPoolSubMenu);
