import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import TextInput from '../../TextInput';
import SelectInput from '../../SelectInput';
import { connect } from 'react-redux';
import './NewCustomerModal.scss';
import { createCustomerRequest, updateCustomerRequest } from '../../../actions';
import schema from './Schema';

class NewCustomerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendRequest = async (formValues) => {
    let newCustomer = {
      name: formValues.customerName,
      pool: formValues.minerGroupName,
      location: formValues.location,
    };
    if (this.props.modalType === 'add') {
      await this.props.createCustomerRequest(newCustomer, this.props.auth);
    } else if (this.props.modalType === 'edit') {
      await this.props.updateCustomerRequest(newCustomer, this.props.auth);
    }
  };

  render() {
    let initValue = this.props.data || {};
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        className="reactModalOverRide"
        style={{
          overlay: {
            zIndex: 10,
            backgroundColor: '#FFF',
            height: 460,
            width: 576,
            border: '1px solid #4F76DB',
            boxShadow: '0 0 3px 0 #CEDCF2',
            borderRadius: 5,
            boxSizing: 'border-box',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className="modalHeader">
          {this.props.modalType === 'add' ? (
            <>
              <span>Add Customer</span>
              <img src="/tableCopy.png" id="addUserIcon" alt=""></img>
            </>
          ) : (
            <>
              <span>Edit Customer</span>
              <img src="/Edit.png" id="addUserIcon" alt=""></img>
            </>
          )}
        </div>
        <Formik
          initialValues={{
            minerGroupName: initValue.pool || '',
            customerName: initValue.name || '',
            location: initValue.location || '',
            modalType: this.props.modalType,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            await this.sendRequest(values);
            resetForm();
            setSubmitting(false);
            this.props.handleCloseModal();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="modalFields">
                <TextInput
                  name="customerName"
                  controlId="name"
                  label="Name *"
                  readOnly={this.props.modalType === 'edit'}
                />
              </div>
              <div className="modalFields">
                <TextInput
                  name="location"
                  controlId="location"
                  label="Location"
                />
              </div>
              <div className="modalFields">
                <SelectInput
                  name="minerGroupName"
                  controlId="pool"
                  label="Miner Group"
                  options={this.props.pools['all_pools'].map(
                    (item) => item.pool_name
                  )}
                  placeholder="please select"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  marginRight: 24,
                  marginTop: 25,
                  gap: 12,
                }}
              >
                <Button
                  variant="outline-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="filterBtnModal"
                >
                  Save
                </Button>
                <Button
                  onClick={this.props.handleCloseModal}
                  className="filterBtnModal"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {
  createCustomerRequest,
  updateCustomerRequest,
})(NewCustomerModal);
