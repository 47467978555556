import React, { Component } from 'react';
import validator from 'validator';
import './Profile.scss';
import topBarProfileDefault from '../../assets/TopBar-Profile-Default.png';
import Unsaved from '../Popups/Unsaved';
import editPencil from '../../assets/Userflow_Pool Info-06.png';
import swal from 'sweetalert2';
import axios from 'axios';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const config = require('../../config');

class EditProfile extends Component {
  constructor(props) {
    super();
    this.state = {
      name: props.auth.name,
      phone: props.auth.phone,
      email: props.auth.email,
      mfa_type: props.auth.mfa_type,
      password: '',
      newPassword: '',
      confirmPassword: '',
      confirmSave: false,
      fieldError: false,
      fieldErrorMessage: '',
    };
  }

  validateEmail = (email) => {
    const isValidEmail = validator.isEmail(email);
    return isValidEmail;
  };

  validateForm = (fieldArr) => {
    return fieldArr.every((input) => this.state[input].length > 0);
  };

  handleChange = (event) => {
    this.setState({
      fieldError: false,
      fieldErrorMessage: '',
      [event.target.id]: event.target.value,
    });
  };

  handlePhoneNrChange = (phoneNr) => {
    this.setState({
      fieldError: false,
      fieldErrorMessage: '',
      phone: phoneNr,
    });
  };

  onDiscard = () => {
    this.setState({
      confirmSave: false,
    });
    this.props.cancelHandler();
  };

  handleCancel = () => {
    if (
      this.state.name !== this.props.auth.name ||
      this.state.phone !== this.props.auth.phone ||
      this.state.email !== this.props.auth.email ||
      this.state.mfa_type !== this.props.auth.mfa_type
    ) {
      this.setState({
        confirmSave: true,
      });
    } else {
      this.onDiscard();
    }
  };

  saveEdit = () => {
    if (
      !this.validateForm(['email', 'name']) &&
      (!this.state.email || !this.state.name)
    ) {
      this.setState({
        fieldError: true,
        fieldErrorMessage: 'Name and/or Email fields must not be empty',
      });
    } else if (!this.validateEmail(this.state.email)) {
      this.setState({
        fieldError: true,
        fieldErrorMessage: 'The Email address entered is not valid',
      });
    } else if (this.state.phone && !isValidPhoneNumber(this.state.phone)) {
      this.setState({
        fieldError: true,
        fieldErrorMessage: 'The Phone number entered is not valid',
      });
    } else {
      this.changeUserDetailsRequest();
    }
  };

  changeUserDetailsRequest = () => {
    axios
      .put(
        config.urls.nodeServer + '/user/user_details',
        {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          user_id: this.props.auth.user_id,
          mfa_type: this.state.mfa_type,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: this.props.auth.token,
          },
        }
      )
      .then((res) => {
        swal({
          title: 'User Details Updated',
          onClose: async () => {
            window.location.reload(false);
          },
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: 'Error updating user password',
        });
      });
  };

  changePassword = () => {
    if (
      !this.validateForm([
        'email',
        'password',
        'newPassword',
        'confirmPassword',
      ])
    ) {
      this.setState({
        fieldError: true,
        fieldErrorMessage:
          'Email field and all password fields are required to change password',
      });
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        fieldError: true,
        fieldErrorMessage: 'Confirm Password must match New Password',
      });
    } else {
      this.changePasswordRequest();
    }
  };

  changePasswordRequest = () => {
    axios
      .post(
        config.urls.nodeServer + '/change_password',
        {
          email: this.state.email,
          oldPassword: this.state.password,
          newPassword: this.state.newPassword,
          user_id: this.props.auth.user_id,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: this.props.auth.token,
          },
        }
      )
      .then((res) => {
        swal({
          title: res.data.msg,
          onClose: () => this.props.handleClose(),
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: 'Error updating user password',
        });
      });
  };

  render() {
    const { auth } = this.props;
    return (
      <div>
        <Unsaved
          show={this.state.confirmSave}
          auth={this.props.auth}
          onDiscard={this.onDiscard}
          onHide={() => {
            this.saveEdit();
            this.setState({ confirmSave: false });
          }}
        />
        <div className="edit-profile">
          <div className="profile-pic-and-name">
            <button
              className="profile-picture-button"
              onClick={() => console.log('need to add pics')}
            >
              <img
                className="profile-picture profile-picture-edit"
                src={topBarProfileDefault}
                alt="profile-icon"
              />
              <div className="profile-picture picture-overlay"></div>
              <img className="edit-picture" src={editPencil} alt="Edit mode" />
            </button>
            <h4 className="account-name">{auth.name}</h4>
          </div>
          <div className="vertical-line"></div>
          <div className="edit-info">
            <div className="edit-title">
              <h4 className="header-label">General Account Information</h4>
            </div>
            <div>
              <p id="edit-profile-label">Edit Profile</p>
              {this.state.fieldError && (
                <div>
                  <p style={{ fontSize: '12px' }}>
                    {this.state.fieldErrorMessage}
                  </p>
                </div>
              )}
            </div>
            <form>
              <div className="edit-title">
                <div className="label-input">
                  <label className="profile-label">Name</label>
                  <input
                    className="username-password-input"
                    id="name"
                    type="username"
                    defaultValue={auth.name}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="label-input">
                  <label className="profile-label">Phone</label>
                  <div>
                    <PhoneInput
                      international
                      flagUrl="https://flag.pk/flags/4x3/{xx}.svg"
                      countryCallingCodeEditable={false}
                      defaultCountry="CA"
                      value={auth.phone}
                      onChange={(event) => this.handlePhoneNrChange(event)}
                    />
                  </div>
                </div>
              </div>
              <br></br>
              <div>
                <div className="label-input">
                  <label className="profile-label">Email Address</label>
                  <input
                    className="email-input"
                    id="email"
                    type="text"
                    defaultValue={auth.email}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <br></br>
              <div>
                <div className="label-input">
                  <label className="profile-label">2FA</label>
                  <select
                    name="mfa-input"
                    className="mfa-input"
                    id="mfa_type"
                    value={this.state.mfa_type}
                    onChange={this.handleChange}
                  >
                    <option value="sms">sms</option>
                    <option value="qr">qr</option>
                    <option value="email">email</option>
                  </select>
                </div>
              </div>
              <br></br>
              <div className="save-cancel">
                <button
                  className="save-button"
                  type="button"
                  onClick={this.saveEdit}
                >
                  Save Changes
                </button>
                <button
                  className="cancel-button"
                  type="button"
                  onClick={this.handleCancel}
                  onContinue={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
              <br></br>
              <div>
                <h5 className="header-label">Change Password</h5>
              </div>
              <br></br>
              <div>
                <div className="label-input">
                  <label className="profile-label">Old Password</label>
                  <input
                    className="password-input"
                    id="password"
                    type="password"
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <br></br>
              <div>
                <div className="label-input">
                  <label className="profile-label">New Password</label>
                  <input
                    className="password-input"
                    id="newPassword"
                    type="password"
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <br></br>
              <div>
                <div className="label-input">
                  <label className="profile-label">Confirm New Password</label>
                  <input
                    className="password-input"
                    id="confirmPassword"
                    type="password"
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <br></br>
              <div>
                <button
                  className="change-password"
                  type="button"
                  onClick={this.changePassword}
                >
                  Change Password
                </button>
              </div>
              <br></br>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EditProfile;
