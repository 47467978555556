import React, { Component } from 'react';
import './ReportExportMenu.scss';

class ReportExportMenu extends Component {
  render() {
    return (
      <div className="reportExportMenu">
        <div
          className="reportMenuItem"
          onClick={() => this.props.getReportType('blocks')}
        >
          Pool Blocks report
        </div>
      </div>
    );
  }
}

export default ReportExportMenu;
