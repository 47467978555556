import React, { Component } from 'react';
import './DashboardStats.scss';
import WorkerStatus from './WorkerStatus';
import PoolWorkerStats from './PoolWorkerStats';
import Reward from './Reward';
import BlockseerPoolHashrate from './BlockseerPoolHashrate';
import CanDisplay from '../CanDisplay';
import { connect } from 'react-redux';
import { getPools } from '../../actions';

class DashboardStats extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    await this.props.getPools(this.props.auth);
  };

  render() {
    return (
      <div className="filteredCharts">
        <CanDisplay
          rules={this.props.rules}
          perform="view_managed_workers_stats_card"
          yes={() => (
            <PoolWorkerStats
              onSelect={this.props.onSelect}
              selectedPool={this.props.selectedPool}
            />
          )}
        />
        <CanDisplay
          rules={this.props.rules}
          perform="view_workers_status_card"
          yes={() => (
            <WorkerStatus
              minersData={this.props.minersData}
              selectedCustomer={this.props.selectedCustomer}
              selectedPool={this.props.selectedPool}
            />
          )}
        />
        <CanDisplay
          rules={this.props.rules}
          perform="view_blockseer_pool_hashrate_card"
          yes={() => <BlockseerPoolHashrate />}
        />
        <CanDisplay
          rules={this.props.rules}
          perform="view_rewards_card"
          yes={() => (
            <Reward
              onSelect={this.props.onSelect}
              selectedCustomer={this.props.selectedCustomer}
              selectedPool={this.props.selectedPool}
            />
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, rules }) => {
  return {
    auth,
    rules,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, { getPools })(DashboardStats);
