import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import './DeleteCustomerModal.scss';
import axios from 'axios';
const config = require('../../../config');

function DeleteCustomerModal(props) {
  const token = useSelector((state) => state.auth.token);

  async function handleDeleteRow(data) {
    await axios
      .delete(`${config.urls.nodeServer}/admin/customers`, {
        headers: { authorization: token },
        data: data,
      })
      .then((res) => {
        props.onHide();
        if (res.status === 200) {
          swal({
            title: 'Successfully deleted customer',
            onClose: async () => {
              window.location.reload(false);
            },
          });
        } else {
          swal({
            title: 'Failed to delete customer',
          });
        }
      });
  }

  return (
    <Modal
      {...props}
      data-backdrop="static"
      data-keyboard="false"
      centered
      dialogClassName="deleteCustomerModal"
    >
      <Modal.Header>
        <Modal.Title>
          Are you sure you want to delete customer {props.data.name} ?
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          If you delete this customer, you will permanently lose all data
          related to this customer. We can't recover them once you delete.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDeleteRow(props.data)}>
          Delete Customer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteCustomerModal;
