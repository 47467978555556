import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class MMWorkerStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minersData: null,
    };
  }

  componentDidMount = async () => {
    this.setState({
      minersData: this.props.minersData.json_response,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.selectedCustomer !== this.props.selectedCustomer &&
      prevProps.selectedPool !== this.props.selectedPool
    ) {
      this.updateMinerData(
        this.props.selectedCustomer,
        this.props.selectedPool
      );
    } else if (prevProps.selectedPool !== this.props.selectedPool) {
      this.updateMinerData([], this.props.selectedPool);
    } else if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.updateMinerData(this.props.selectedCustomer, []);
    }
  };

  updateMinerData = (customers, pools) => {
    this.setState({
      minersData: null,
    });
    let minersData = this.props.minersData.json_response;
    let customerNames = [];
    let poolNames = [];

    let allMiners = [];
    let healthyMiners = [];
    let issueMiners = [];
    let noAPIResponseMiners = [];
    let offlineMiners = [];
    let inRepairMiners = [];

    if (customers !== null && customers.length > 0) {
      customerNames = customers.map((customer) => {
        return customer.value;
      });
    }
    if (pools !== null && pools.length > 0) {
      poolNames = pools.map((pool) => {
        return pool.value;
      });
    }

    if (customerNames.length > 0 || poolNames.length > 0) {
      minersData['allMiners'].forEach((miner) => {
        if (
          customerNames.includes(miner.customer_name) ||
          poolNames.includes(miner.pool)
        ) {
          allMiners.push(miner);
          if (
            minersData['healthyMiners'].some(
              (healthyMiner) => healthyMiner.nodename === miner.nodename
            )
          ) {
            healthyMiners.push(miner);
          } else if (
            minersData['issueMiners'].some(
              (issueMiner) => issueMiner.nodename === miner.nodename
            )
          ) {
            issueMiners.push(miner);
          } else if (
            minersData['noAPIResponseMiners'].some(
              (noAPIResponseMiner) =>
                noAPIResponseMiner.nodename === miner.nodename
            )
          ) {
            noAPIResponseMiners.push(miner);
          } else if (
            minersData['offlineMiners'].some(
              (offlineMiner) => offlineMiner.nodename === miner.nodename
            )
          ) {
            offlineMiners.push(miner);
          } else {
            inRepairMiners.push(miner);
          }
        }
      });
      this.setState({
        minersData: {
          allMiners: allMiners,
          healthyMiners: healthyMiners,
          issueMiners: issueMiners,
          noAPIResponseMiners: noAPIResponseMiners,
          offlineMiners: offlineMiners,
          inRepairMiners: inRepairMiners,
        },
      });
    } else {
      this.setState({
        minersData: this.props.minersData.json_response,
      });
    }
  };

  render() {
    return (
      <div>
        <p className="infoBoxLabel">Mine Management Workers Status</p>
        <div className="tagContainer">
          {this.props.selectedCustomer &&
            this.props.selectedCustomer.map((customer) => {
              return (
                <div className="filterTag customerTag">{customer.value}</div>
              );
            })}
          {this.props.selectedPool &&
            this.props.selectedPool.map((pool) => {
              return <div className="filterTag poolTag">{pool.value}</div>;
            })}
        </div>
        <div className="container">
          <div className="item">
            <div className="itemLabel">All Workers</div>
            <div className="value itemValue">
              {this.state.minersData !== null &&
              'allMiners' in this.state.minersData ? (
                this.state.minersData.allMiners.length
              ) : (
                <CircularProgress size="1.0vw" />
              )}
            </div>
          </div>
          <div className="item">
            <div className="itemLabel">Healthy Workers</div>
            <div className="value itemValue">
              {this.state.minersData !== null &&
              'healthyMiners' in this.state.minersData ? (
                this.state.minersData.healthyMiners.length
              ) : (
                <CircularProgress size="1.0vw" />
              )}
            </div>
            <div className="colorBar green"></div>
          </div>
          <div className="item">
            <div className="itemLabel">Workers with Issues</div>
            <div className="value itemValue">
              {this.state.minersData !== null &&
              'issueMiners' in this.state.minersData ? (
                this.state.minersData.issueMiners.length
              ) : (
                <CircularProgress size="1.0vw" />
              )}
            </div>
            <div className="colorBar orange"></div>
          </div>
          <div className="item">
            <div className="itemLabel">Workers with no API response</div>
            <div className="value itemValue">
              {this.state.minersData !== null &&
              'noAPIResponseMiners' in this.state.minersData ? (
                this.state.minersData.noAPIResponseMiners.length
              ) : (
                <CircularProgress size="1.0vw" />
              )}
            </div>
            <div className="colorBar red"></div>
          </div>
          <div className="item">
            <div className="itemLabel">Offline Workers</div>
            <div className="value itemValue">
              {this.state.minersData !== null &&
              'offlineMiners' in this.state.minersData ? (
                this.state.minersData.offlineMiners.length
              ) : (
                <CircularProgress size="1.0vw" />
              )}
            </div>
            <div className="colorBar gray"></div>
          </div>
          <div className="item">
            <div className="itemLabel">Workers in Repairs</div>
            <div className="value itemValue">
              {this.state.minersData !== null &&
              'inRepairMiners' in this.state.minersData ? (
                this.state.minersData.inRepairMiners.length
              ) : (
                <CircularProgress size="1.0vw" />
              )}
            </div>
            <div className="colorBar yellow"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default MMWorkerStatus;
