import * as Yup from 'yup';

// this expression do not require http(s) protocol to start with
const urlRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(?::\d+)?(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const btcRegex = /^[a-zA-Z0-9]*$/; // /^([13][a-km-zA-HJ-NP-Z1-9]{25,34}|bc1[ac-hj-np-zAC-HJ-NP-Z02-9]{11,71})$/;
const twoDecimalRegex = /^[0-9]+(\.[0-9]{1,2})?$/;
const navierGroupNameRegex = /^[a-z,0-9]([a-z,0-9]|(-[a-z,0-9]))*$/;

const isInRange = (str, min, max) => {
  let x = Number(str);
  return x >= min && x <= max;
};

const minerGroupSchema = Yup.object().shape({
  modalType: Yup.string().oneOf(['add', 'edit']),
  minerGroupName: Yup.string()
    .min(3, 'Names must be 3-64 characters') // range 3-64 is provided by Navier
    .max(64, 'Names must be 3-64 characters')
    .matches(/^[a-z0-9-]*$/, 'Please use only a-z 0-9 -')
    .matches(navierGroupNameRegex, 'Hyphens only in between')
    .required('* Required field'),
  externalUrl: Yup.string()
    .matches(urlRegex, 'Please put a valid url address')
    .max(100, 'URL must be less than 100 characters'),
  payoutAddress: Yup.string()
    .matches(btcRegex, 'Please put a valid wallet address')
    .when('modalType', {
      is: 'add',
      then: Yup.string().required('* Required field'),
    }),
  payoutThreshold: Yup.string()
    .max(5, 'Please put a value in valid format')
    .matches(twoDecimalRegex, 'Please put 2 decimals at most')
    .test(
      'range',
      'Please put a value between 0.01-100',
      (x) => !x || isInRange(x, 0.01, 100)
    )
    .when('modalType', {
      is: 'add',
      then: Yup.string().required('* Required field'),
    }),
  payoutFee: Yup.string()
    .max(4, 'Please put a value in valid format')
    .matches(twoDecimalRegex, 'Please put 2 decimals at most')
    .test(
      'range',
      'Please put a percentage between 0.5-2',
      (x) => !x || isInRange(x, 0.5, 2)
    ),
  purgeAfterHours: Yup.number()
    .integer()
    .min('0', 'Please use a value above 0')
    .max('4560', 'Please use a value below 4560')
    .default('0'),
});

export default minerGroupSchema;
