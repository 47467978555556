import React, { Component } from 'react';
import './PoolWorkerGraph.scss';
import { connect } from 'react-redux';
import { getRulesFeaturesRequest, getCustomerPools } from '../../../actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReportPopup from '../../Popups/ReportPopup';
import ReportExportMenu from '../../ReportExportMenu/ReportExportMenu';
import drawPoolWorkerGraph from './drawPoolWorkerGraph';
import axios from 'axios';
import {
  transformHashrate,
  convertHashrateValueToSmallerUnit,
} from '../../../helpers';
import _ from 'lodash';
import Loading from '../../Loading';

const moment = require('moment');
const config = require('../../../config');

class PoolWorkerGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      openReportPopup: false,
      openReportExportMenu: false,
      graphTime: 'hour',
      reportType: null,
      showAverageHashrateWorker: true,
      showblocksFoundWorker: true,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    const saved_token = window.localStorage.getItem('token');
    if (!this.props.customer_pools) {
      await this.props.getCustomerPools(this.props.auth);
    }
    await this.getGraphData(saved_token);
  };

  componentDidUpdate = async (prevProps, prevStates) => {
    const saved_token = window.localStorage.getItem('token');
    if (
      this.state.graphTime !== prevStates.graphTime ||
      this.state.showAverageHashrateWorker !==
        prevStates.showAverageHashrateWorker ||
      this.state.showblocksFoundWorker !== prevStates.showblocksFoundWorker ||
      this.state.startDate !== prevStates.startDate ||
      this.state.endDate !== prevStates.endDate ||
      this.props.selectedPool !== prevProps.selectedPool
    ) {
      await this.getGraphData(saved_token);
    }
  };

  getGraphData = async (token) => {
    this.setState({ isLoading: true });
    const filters = [...this.props.selectedPool];
    const uniqueFilters = _.uniqBy(filters, function (e) {
      return e.value;
    }).map((ele) => {
      return ele.value;
    });
    const startDate = moment(this.state.startDate, 'YYYY-MM-DD')
      .startOf('day')
      .toISOString();
    const endDate = moment(this.state.endDate, 'YYYY-MM-DD')
      .endOf('day')
      .toISOString();
    axios
      .get(
        `${config.urls.nodeServer}/user/pool_graph_data/${this.state.graphTime}?startDate=${startDate}&endDate=${endDate}&filter=${uniqueFilters}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(async (res) => {
        if (res.data) {
          this.setState({ isLoading: false });
        }
        let data;
        if (this.props.rules) {
          if (this.props.rules.features.includes('create_technician')) {
            data = res.data.all;
          } else {
            data = res.data['By_user'];
          }
          if (this.props.selectedPool.length > 0) {
            data = res.data.filteredData;
          }
        }
        if (data.length > 0) {
          let totalHashrate = 0;
          const minHashrate = data.reduce(
            (min, val) => (parseInt(val.sum) < parseInt(min) ? val.sum : min),
            data[0].sum
          );
          let smallestHashrateUnit = transformHashrate(
            minHashrate / 1000,
            'KH/s'
          ).split(' ')[1];
          const graphData = data.map((data) => {
            const date = new Date(data.dt);
            const hashrateObj = convertHashrateValueToSmallerUnit(
              data.sum,
              smallestHashrateUnit
            );
            totalHashrate += parseInt(data.sum);
            return {
              date,
              hashrate: parseFloat(hashrateObj.value),
              hashrateDisplay: hashrateObj.display,
              activeWorker: data.activeWorker,
              totalBlocksFound: data.blocksDetails.length,
              blocksDetails: data.blocksDetails,
            };
          });
          const averageHashrateWorkerObj = convertHashrateValueToSmallerUnit(
            totalHashrate / data.length,
            smallestHashrateUnit
          );
          drawPoolWorkerGraph(
            graphData,
            averageHashrateWorkerObj,
            this.state.showAverageHashrateWorker,
            this.state.showblocksFoundWorker
          );
        } else {
          data.push({
            activeWorker: '0',
            blocksDetails: [],
            date: new Date(),
            hashrate: '0',
            hashrateDisplay: '0 KH/s',
            totalBlocksFound: 0,
            worker: '0',
          });
          drawPoolWorkerGraph(
            data,
            { value: 0, display: '0 KH/s' },
            this.state.showAverageHashrateWorker,
            this.state.showblocksFoundWorker
          );
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
  };

  closeReportPopup = () => {
    this.setState({ openReportPopup: false });
  };

  closeReportExportMenu = () => {
    this.setState({ openReportExportMenu: false });
  };

  getReportType = (reportType) => {
    this.setState({
      reportType,
      openReportPopup: true,
      openReportExportMenu: false,
    });
  };

  handleCheckedBox = (event) => {
    if (event.target.value === 'averageHashrateWorker') {
      this.setState({
        showAverageHashrateWorker: !this.state.showAverageHashrateWorker,
      });
    }
    if (event.target.value === 'blocksFoundWorker') {
      this.setState({
        showblocksFoundWorker: !this.state.showblocksFoundWorker,
      });
    }
  };

  render() {
    return (
      <div className="infoBox graphBox">
        <div style={{ position: 'absolute', left: '-15rem' }}>
          {this.state.isLoading && <Loading />}
        </div>
        {/* Headline: Title + Export btn */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="infoBoxLabel">My Pool Workers Graph</div>
          <div>
            <div className="exportBtnContainer">
              <button className="exportBtn">
                <span>Export</span>
              </button>
              <ReportExportMenu getReportType={this.getReportType} />
            </div>
          </div>
        </div>
        <div className="tagContainer">
          {this.props.selectedPool &&
            this.props.selectedPool.map((pool) => {
              return <div className="filterTag poolTag">{pool.value}</div>;
            })}
        </div>

        <div className="graphOptionsContainer">
          {/* Date picker */}
          <div className="datepickerOptions">
            <div style={{ marginRight: '20px' }}>
              <div className="datePickerTitle">Start Date</div>
              <DatePicker
                minDate={new Date(this.props.minDateReport)}
                maxDate={this.state.endDate}
                selected={this.state.startDate}
                onChange={(startDate) => {
                  this.setState({ startDate })
                }}
              />
            </div>
            <div>
              <div className="datePickerTitle">End Date</div>
              <DatePicker
                minDate={this.state.startDate}
                maxDate={new Date()}
                selected={this.state.endDate}
                onChange={(endDate) => {
                  this.setState({ endDate })
                }}
              />
            </div>
          </div>
          {/* End Date picker */}

          {/* Interval option: 5mins / hour/ day */}
          <div className="intervalOptions">
            {!this.state.disableInterval && (
              <div
                className="graphTime"
                selectedGraphTime={
                  this.state.graphTime === '15_min' ? 'true' : 'false'
                }
                onClick={() => this.setState({ graphTime: '15_min' })}
              >
                15 Minutes
              </div>
            )}
            <div
              className="graphTime"
              selectedGraphTime={
                this.state.graphTime === 'hour' ? 'true' : 'false'
              }
              onClick={() => this.setState({ graphTime: 'hour' })}
            >
              Hour
            </div>
          </div>
        </div>
        {this.state.openReportPopup && (
          <ReportPopup
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            closeReportPopup={this.closeReportPopup}
            reportType={this.state.reportType}
          />
        )}
        <div id="poolWorkerGraph"></div>
        <div className="legend">
          <div className="legendItem">
            <span className="dot" style={{ backgroundColor: '#5E89EB' }}></span>
            Actual Hashrate
          </div>
          <div className="legendItem">
            <span className="dot" style={{ backgroundColor: '#D3CFFC' }}></span>
            <label for="averageHashrateWorker">Average Hashrate</label>
            <input
              type="checkbox"
              id="averageHashrateWorker"
              name="averageHashrateWorker"
              value="averageHashrateWorker"
              onChange={this.handleCheckedBox}
              checked={this.state.showAverageHashrateWorker}
            />
          </div>
          <div className="legendItem">
            <span className="dot" style={{ backgroundColor: '#FAE18A' }}></span>
            <label for="blocksFoundWorker">Blocks Found</label>
            <input
              type="checkbox"
              id="blocksFoundWorker"
              name="blocksFoundWorker"
              value="blocksFoundWorker"
              onChange={this.handleCheckedBox}
              checked={this.state.showblocksFoundWorker}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, customer_pools, rules }) => {
  return {
    auth,
    customer_pools,
    rules,
  };
};

export default connect(mapStateToProps, {
  getCustomerPools,
  getRulesFeaturesRequest,
})(PoolWorkerGraph);
