import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { get, uniqBy } from 'lodash';
import ReactTable from 'react-table';
import AddEditAccountModal from './AddEditAccountModal.js';
import './UserManagement.scss';
import EditUserSubMenu from './EditUserSubMenu.js';
import CanDisplay from '../../CanDisplay';
import DeleteAccountModal from './DeleteAccountModal.js';
const roles = [
  'technician',
  'mm-pool-admin',
  'mm-admin',
  'pool-admin',
  'mm-pool-viewer',
  'mm-viewer',
  'pool-viewer',
];

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      selectedCustomer: [],
      selectedRole: [],
      filteredData: null,
      filter: null,
      disabledValue: true,
      showMenu: false,
      showSubMenu: false,
      editUserData: null,
      page: 0,
      pageSize: 10,
      isFilterApplied: false,
    };
  }

  componentDidMount = () => {
    let permissionValues = [];
    this.props.rules.features.forEach((feature) => {
      if (
        feature.includes('create') &&
        roles.includes(feature.slice(7).replace(/_/g, '-'))
      ) {
        permissionValues.push(feature.slice(7).replace(/_/g, '-'));
      }
    });
    this.setState({
      permissionValues,
    });
  };

  displayOptions = (category) => {
    const data = this.props.userManagementData;
    let arr = data.map((val) => {
      return val[`${category}`];
    });
    const uniqueArr = uniqBy(arr, (e) => {
      return e;
    });
    const optionsList = uniqueArr.map((val, i) => {
      return { id: i, value: val, label: val };
    });

    return optionsList;
  };

  showMenu = (row) => {
    this.setState({ showMenu: row }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = (event) => {
    // this.setState({ showSubMenu: false })
    this.setState({ showSubMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  handleFilterChange = (value, accessor) => {
    let filtered = this.state.filtered;
    let isInsertNewFilter = true;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) {
            filtered.splice(i, 1);
          } else filter['value'] = value;
          isInsertNewFilter = false;
        }
      });
    }

    if (isInsertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({
      filtered,
    });
  };

  handleOpenModal = (modal) => {
    this.setState({ displayAccountModal: modal });
  };

  handleCloseAccountModal = () => {
    this.setState({ displayAccountModal: false });
  };

  handleSearchChange = (event) => {
    this.setState({
      filter: event.target.value === '' ? null : event.target.value,
    });
  };

  updateDataSetBasedonFiter = (filtered) => {
    if (!filtered) {
      filtered = this.state.filtered;
    }
    if (get(filtered, 'length', 0) < 1) {
      this.setState({
        filteredData: null,
        disabledValue: true,
      });
      return;
    }
    //Code for filtering data based on filters
    const dataToFilter = this.props.userManagementData;

    const filteredData = dataToFilter.filter((row) => {
      let displayRow = false;
      let filterApplied = false;
      filtered.forEach((filter) => {
        const id = filter.pivotId || filter.id;
        if (displayRow || !filterApplied) {
          filterApplied = true;
          displayRow =
            row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
        }
      });

      return displayRow;
    });
    this.setState({
      filteredData,
      disabledValue: false,
    });
  };

  removeFilterOption = (value, accessor) => {
    let { filtered, selectedRole, selectedCustomer } = this.state;
    filtered.forEach((filter, i) => {
      if (filter.id === accessor && filter.value.includes(value)) {
        const valueIndex = filter.value.indexOf(value);
        filter.value.splice(valueIndex, 1);
        if (filter.id === 'customer_name')
          selectedCustomer.splice(valueIndex, 1);
        if (filter.id === 'role_type') selectedRole.splice(valueIndex, 1);
      }
      if (filter.value.length === 0) filtered.splice(i, 1);
    });

    this.setState({ filtered, selectedCustomer, selectedRole });
  };

  render() {
    let data = null;
    if (!this.state.filteredData) {
      data = this.props.userManagementData;
    } else {
      data = this.state.filteredData;
    }
    if (this.state.filter !== null) {
      const filteredSearchData = data.filter((item) => {
        return Object.keys(item).some((key) => {
          if (
            item !== null &&
            key !== null &&
            item[key] !== null &&
            item[key].toString().includes(this.state.filter)
          ) {
            return item[key];
          } else return null;
        });
      });
      data = filteredSearchData;
    }
    let columns = [
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Login
              Name
            </div>
          );
        },
        accessor: 'login',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Customer
            </div>
          );
        },
        accessor: 'customer_name',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Name
            </div>
          );
        },
        accessor: 'name',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Role
            </div>
          );
        },
        accessor: 'role_type',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Email
            </div>
          );
        },
        accessor: 'email',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>MFA
              Type
            </div>
          );
        },
        accessor: 'mfa_type',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Last
              Login
            </div>
          );
        },
        accessor: 'last_login',
        minWidth: 113,
      },
      {
        id: 'action',
        accessor: '',
        Cell: (row) => {
          return (
            <div>
              {this.state.permissionValues &&
                this.state.permissionValues.includes(
                  row.original.role_type
                ) && (
                  <img
                    src="/tableHamburger.png"
                    id="actionRowBtn"
                    alt=""
                    onClick={() => {
                      this.showMenu(row);
                      this.setState({ showSubMenu: true });
                    }}
                  ></img>
                )}
            </div>
          );
        },
        Header: '',
        width: 45,
      },
    ];
    return (
      <div>
        <div className="filterContainer">
          <div id="filterHeader">
            <span>Filter</span>
          </div>
          <div className="filters" style={{ marginLeft: '20px' }}>
            <div id="filterInput">
              {this.state.selectedCustomer.length > 0 && (
                <div className="filterNumber" id="customerFilterNumber">
                  {this.state.selectedCustomer.length}
                </div>
              )}
              <Select
                value={this.state.selectedCustomer}
                placeholder="Customer"
                onChange={(entry) => {
                  this.setState({ selectedCustomer: entry });
                  this.handleFilterChange(
                    entry.map((o) => {
                      return o.value;
                    }),
                    'customer_name'
                  );
                }}
                isMulti
                options={this.displayOptions('customer_name')}
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                tabSelectsValue={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#CEDCF2',
                    primary: '#CEDCF2',
                  },
                })}
              />
            </div>

            <div id="filterInput">
              {this.state.selectedRole.length > 0 && (
                <div className="filterNumber" id="poolFilterNumber">
                  {this.state.selectedRole.length}
                </div>
              )}
              <Select
                value={this.state.selectedRole}
                placeholder="Role"
                onChange={(entry) => {
                  this.setState({ selectedRole: entry });
                  this.handleFilterChange(
                    entry.map((o) => {
                      return o.value;
                    }),
                    'role_type'
                  );
                }}
                isMulti
                options={this.displayOptions('role_type')}
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                tabSelectsValue={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#CEDCF2',
                    primary: '#CEDCF2',
                  },
                })}
              />
            </div>

            <div className="filterInput" style={{ display: 'flex' }}>
              <button
                onClick={() => {
                  this.setState({ isFilterApplied: true });
                  this.updateDataSetBasedonFiter();
                }}
                id="filterBtn"
              >
                <span>Apply Filters</span>
              </button>

              <div
                disabled={this.state.disabledValue}
                onClick={() => {
                  this.setState({
                    filtered: [],
                    filteredData: null,
                    selectedRole: [],
                    selectedCustomer: [],
                    disabledValue: true,
                    isFilterApplied: false,
                  });
                }}
                className={
                  this.state.selectedCustomer.length > 0 ||
                  this.state.selectedRole.length > 0
                    ? 'appBtn clearBtn'
                    : 'appBtn disabledBtn'
                }
              >
                <span>Clear Filters</span>
              </div>
            </div>
          </div>
          {/* Filters'tags */}
          {(this.state.selectedCustomer.length > 0 ||
            this.state.selectedRole.length > 0) && (
            <div className="tagContainer" style={{ marginLeft: '20px' }}>
              <div className="tagContainer">
                {this.state.selectedCustomer.map((customer) => {
                  return (
                    <div
                      className="filterTag customerTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() =>
                        this.removeFilterOption(customer.value, 'customer_name')
                      }
                    >
                      {customer.value} &nbsp; x
                    </div>
                  );
                })}
              </div>
              <div className="tagContainer">
                {this.state.selectedRole.map((role) => {
                  return (
                    <div
                      className="filterTag poolTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() =>
                        this.removeFilterOption(role.value, 'role_type')
                      }
                    >
                      {role.value} &nbsp; x
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="searchInputContainer">
            <form className="inputBoxForm">
              <input
                className="inputBox"
                type="text"
                name="search"
                placeholder="Search for user(s)"
                onChange={this.handleSearchChange}
              ></input>
            </form>
            <CanDisplay
              rules={this.props.rules}
              perform="add_users"
              yes={() => (
                <button
                  className="filterAddBtn"
                  onClick={() => {
                    this.handleOpenModal('user');
                  }}
                >
                  <span>Add User</span>
                </button>
              )}
            />
          </div>
          <AddEditAccountModal
            rules={this.props.rules}
            isOpen={this.state.displayAccountModal === 'user'}
            customers={this.props.customers}
            handleCloseModal={this.handleCloseAccountModal}
            reloadData={this.props.reloadData}
            modalType="add"
          />
        </div>
        <div className="tableContainer">
          <ReactTable
            //If filters are applied, display filtered data. Else display healthy/broken miners depending on toggle.
            bordered={false}
            data={data}
            columns={columns}
            defaultPageSize={10}
            pageSize={this.state.pageSize}
            onPageChange={(page) => this.setState({ page })}
            onPageSizeChange={(pageSize, page) =>
              this.setState({ page, pageSize })
            }
          />
          {this.state.showSubMenu && (
            <EditUserSubMenu
              userData={this.state.showMenu}
              closeMenu={this.closeMenu}
              openEditAccountModal={() => this.handleOpenModal('edit_row')}
              openDeleteAccountModal={() => this.handleOpenModal('delete_row')}
              onDelete={this.props.reloadData}
              tablePage={this.state.page}
              tablePageSize={this.state.pageSize}
            />
          )}
          <AddEditAccountModal
            isOpen={this.state.displayAccountModal === 'edit_row'}
            handleCloseModal={this.handleCloseAccountModal}
            customers={this.props.customers}
            rules={this.props.rules}
            data={this.state.showMenu.original}
            reloadData={this.props.reloadData}
            modalType="edit"
          />
        </div>
        {this.state.displayAccountModal === 'delete_row' && (
          <DeleteAccountModal
            show={this.state.displayAccountModal === 'delete_row'}
            onHide={this.handleCloseAccountModal}
            data={this.state.showMenu.original}
            reloadData={this.props.reloadData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, rules }) => {
  return {
    auth,
    rules,
  };
};

export default connect(mapStateToProps, {})(UserManagement);
