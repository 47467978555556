import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Miners.scss';
import { map, get, uniqBy } from 'lodash';
import swal from 'sweetalert2';
import axios from 'axios';
import history from '../../history';
import {
  loginRequest,
  tokenLoginRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  logoutRequest,
  getUrls,
  getCustomerPools,
  deleteWorkers,
  getBlockseerWorkerStatus,
} from '../../actions';
import 'react-table/react-table.css';
import _ from 'lodash';
import moment from 'moment';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Select from 'react-select';
import GeneralTable from './GeneralTable';
import PerformanceTable from './PerformanceTable';
import generateQR from './QRCode';
import Loading from '../Loading';
const config = require('../../config');

class Miners extends Component {
  constructor() {
    super();
    this.state = {
      activeRows: {},
      nodes_to_action: [],
      indexToSortWith: '',
      last_selected: -1,
      displayReportModal: false,
      displayMode: null,
      data: [],
      allMiners: [],
      issueMiners: [],
      noAPIResponseMiners: [],
      offlineMiners: [],
      inRepairMiners: [],
      allMinerCount: 0,
      issueMinerCount: 0,
      noAPIResponseMinerCount: 0,
      healthyMinerCount: 0,
      offlineMinerCount: 0,
      inRepairMinerCount: 0,
      allPoolWorkers: [],
      healthyPoolWorkers: [],
      offlinePoolWorkers: [],
      filtered: [],
      filter: null,
      selectedPool: [],
      selectedLocation: [],
      selectedSwitch: [],
      selectedType: [],
      selectedCustomer: [],
      selectedStatus: [],
      filteredData: null,
      selectAll: 0,
      commands_response: false,
      alerts_response: false,
      displayModal: false,
      actionOnFilteredMiners: false,
      clock_freq_result: null,
      disabledValue: true,
      displayTable: null,
      filteredDataObj: null,
      isLoading: true,
      isFilterApplied: false,
      poolWorkers: [],
    };
  }

  componentDidUpdate = async (prevprops) => {
    if (this.props.minersData !== prevprops.minersData) {
      await this.getData();
    }
    if (this.props.performanceData !== prevprops.performanceData) {
      await this.setData();
    }
  };

  componentDidMount = async () => {
    const saved_token = window.localStorage.getItem('token');
    if (!get(this.props, 'auth.token', false) && saved_token !== null) {
      this.props.tokenLoginRequest(saved_token);
    } else if (!this.props.auth || !this.props.auth.token) {
      history.push('/login');
    }
    if (get(this.props, 'auth.token', false)) {
      await this.getData();
    }
    if (this.props.pool_hashrate) {
      await this.setData();
    }
    this.props.rules &&
    this.props.rules.features.includes('view_performance_table') &&
    !this.props.rules.features.includes('view_general_table')
      ? this.setState({ displayTable: 'performance' })
      : this.setState({ displayTable: 'general' });
  };

  setData = async () => {
    try {
      this.setState({
        poolWorkers: this.props.performanceData['all_data'],
      });
    } catch (err) {
      console.log('Failed to get pool data');
    }
  };

  deleteWorkers = async (miners, product) => {
    const result = await deleteWorkers(this.props.auth, miners, product);
    this.getData();
    if (result) {
      if (product === 'mm') {
        let data = null;
        let dataType = null;
        if (this.state.displayMode === 'healthyMiners') {
          data = this.state.data;
          dataType = 'data';
        } else if (this.state.displayMode === 'allMiners') {
          data = this.state.allMiners;
          dataType = 'allMiners';
        } else if (this.state.displayMode === 'issueMiners') {
          data = this.state.issueMiners;
          dataType = 'issueMiners';
        } else if (this.state.displayMode === 'offlineMiners') {
          data = this.state.offlineMiners;
          dataType = 'offlineMiners';
        } else if (this.state.displayMode === 'inRepairMiners') {
          data = this.state.inRepairMiners;
          dataType = 'inRepairMiners';
        } else {
          data = this.state.allMiners;
          dataType = 'allMiners';
        }
        data = data.filter((row) => {
          return !miners.includes(row['nodename']);
        });
        this.setState({
          [`${dataType}`]: data,
          nodes_to_action: [],
        });
        return true;
      }
      if (product === 'blockseer') {
        let data = this.state.poolWorkers;
        data = data.filter((row) => {
          return !miners.includes(row['worker_name']);
        });
        this.setState({
          poolWorkers: data,
          nodes_to_action: [],
        });
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    const lowercasedFilter = this.state.filter || null;
    let data = null;
    if (!this.state.filteredData) {
      data = this.getMinerDisplayData(this.state.displayMode).data;
    } else {
      data = this.state.filteredData;
    }
    const filteredSearchData = data.filter((item) => {
      return Object.keys(item).some((key) => {
        if (
          item !== null &&
          key !== null &&
          item[key] !== null &&
          item[key].toString().toLowerCase().includes(lowercasedFilter) &&
          lowercasedFilter !== null
        ) {
          return item[key];
        } else return null;
      });
    });
    if (filteredSearchData.length !== 0 && this.state.filter !== null) {
      data = filteredSearchData;
    } else if (filteredSearchData.length === 0 && this.state.filter !== null) {
      data = [];
    }

    let columns = [
      {
        id: 'checkbox',
        accessor: '',
        Cell: ({ original }) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={
                this.state.nodes_to_action.includes(original.nodename) === true
              }
              onClick={() => this.toggleRow(original.nodename)}
              onChange={() => {}}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkboxHeader"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        minWidth: 45,
      },
      {
        expander: true,
        Header: '',
        minwidth: 113,
        Expander: ({ isExpanded, ...rest }) => (
          <i
            className="fas fa-sort-up minerChevron"
            expanded={isExpanded ? 'true' : 'false'}
          ></i>
        ),
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Customer
            </div>
          );
        },
        accessor: 'customer_name',
        minwidth: 100,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Pool
            </div>
          );
        },
        accessor: 'pool',
        minWidth: 100,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Worker
              name
            </div>
          );
        },
        accessor: 'worker_name',
        minwidth: 140,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Hashrate
            </div>
          );
        },
        id: 'hashrateUnit',
        accessor: (row) => row.hashrateUnit,
        Cell: (row) => {
          return <div>{row.value.display_hashrate}</div>;
        },
        sortMethod: (a, b) => a.original_hashrate - b.original_hashrate,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Temperature
            </div>
          );
        },
        accessor: 'temperature',
        minwidth: 113,
        Cell: (row) => <div>{row.value + ' \xB0C'}</div>,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Location
            </div>
          );
        },
        accessor: 'location',
        minWidth: 130,
        Cell: (row) => <div>{row.value}</div>,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Type
            </div>
          );
        },
        accessor: 'type',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Last
              Updated
            </div>
          );
        },
        id: 'lastUpdated',
        accessor: (row) => row.lastUpdated,
        sortMethod: (a, b) => {
          let items = [a, b];
          let results = [];
          let myRegExp = /^(\d+)\+?\s(\w+)\sago$/;
          items.map((item) => {
            let inputString = item;
            let result = myRegExp.exec(inputString);
            let num = result[1];
            let duration = result[2];
            let date_object = moment().subtract(duration, num).toDate();
            results.push(date_object);
            return item;
          });
          let a1 = results[0];
          let b1 = results[1];
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Last
              Pinged
            </div>
          );
        },
        id: 'ping_timestamp',
        accessor: (row) => row.pingTimestamp,
        sortMethod: (a, b) => {
          if (a === null) {
            return -1;
          }
          if (b === null) {
            return 1;
          }
          let items = [a, b];
          let results = [];
          let myRegExp = /^(\d+)\+?\s(\w+)\sago$/;
          items.map((item) => {
            let inputString = item;
            let result = myRegExp.exec(inputString);
            let num = result[1];
            let duration = result[2];
            let date_object = moment().subtract(duration, num).toDate();
            results.push(date_object);
            return item;
          });
          let a1 = results[0];
          let b1 = results[1];
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
    ];
    if (this.state.displayMode === 'issueMiners') {
      columns.splice(
        7,
        2,
        {
          Header: () => <div style={{ textAlign: 'left' }}>Problems</div>,
          id: 'problems',
          accessor: (data) => {
            let output = [];
            _.map(data.problems, (problem) => {
              output.push(problem.problemName);
            });
            return output.join(', ');
          },
          Cell: (row) => <div style={{ paddingLeft: '1px' }}>{row.value}</div>,
        },
        {
          Header: () => <div style={{ textAlign: 'left' }}>Indicator</div>,
          id: 'indicator',
          accessor: (data) => {
            let output = [];
            _.map(data.problems, (problem) => {
              output.push(problem.indicator);
            });
            return output.join(', ');
          },
          Cell: (row) => <div style={{ paddingLeft: '1px' }}>{row.value}</div>,
        }
      );
    }
    return (
      <div className="worker_container chartsSection">
        {this.state.isLoading && !this.props.isLoading && <Loading />}
        {this.props.rules &&
          this.props.rules.features.includes('view_general_table') &&
          this.state.displayTable === 'general' && (
            <span id="filter_label">Filters</span>
          )}
        {this.props.rules &&
          this.props.rules.features.includes('view_general_table') &&
          this.state.displayTable === 'general' && (
            <div className="filters">
              <div id="filterInput">
                {this.state.selectedCustomer.length > 0 && (
                  <div className="filterNumber" id="customerFilterNumber">
                    {this.state.selectedCustomer.length}
                  </div>
                )}
                <Select
                  value={this.state.selectedCustomer}
                  placeholder="Customer"
                  onChange={(entry) => {
                    this.setState({ selectedCustomer: entry });
                    this.handleFilterChange(
                      entry.map((o) => {
                        return o.value;
                      }),
                      'customer_name'
                    );
                  }}
                  isMulti
                  options={this.displayOptions('customer_name')}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>
              <div id="filterInput">
                {this.state.selectedPool.length > 0 && (
                  <div className="filterNumber" id="poolFilterNumber">
                    {this.state.selectedPool.length}
                  </div>
                )}
                <Select
                  value={this.state.selectedPool}
                  placeholder="Miner Group"
                  onChange={(entry) => {
                    this.setState({ selectedPool: entry });
                    this.handleFilterChange(
                      entry.map((o) => {
                        return o.value;
                      }),
                      'pool'
                    );
                  }}
                  isMulti
                  options={this.displayOptions('pool')}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>
              <div id="filterInput">
                {this.state.selectedType.length > 0 && (
                  <div className="filterNumber" id="typeFilterNumber">
                    {this.state.selectedType.length}
                  </div>
                )}
                <Select
                  value={this.state.selectedType}
                  placeholder="Type"
                  onChange={(entry) => {
                    this.setState({ selectedType: entry });
                    this.handleFilterChange(
                      entry.map((o) => {
                        return o.value;
                      }),
                      'type'
                    );
                  }}
                  isMulti
                  options={this.displayOptions('type')}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>
              <div id="filterInput">
                {this.state.selectedLocation.length > 0 && (
                  <div className="filterNumber" id="locationFilterNumber">
                    {this.state.selectedLocation.length}
                  </div>
                )}
                <Select
                  value={this.state.selectedLocation}
                  placeholder="Area"
                  onChange={(entry) => {
                    this.setState({ selectedLocation: entry });
                    this.handleFilterChange(
                      entry.map((o) => {
                        return o.value;
                      }),
                      'mine_controller'
                    );
                  }}
                  isMulti
                  options={this.displayOptions('mine_controller')}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>
              <div id="filterInput">
                {this.state.selectedSwitch.length > 0 && (
                  <div className="filterNumber" id="switchFilterNumber">
                    {this.state.selectedSwitch.length}
                  </div>
                )}
                <Select
                  value={this.state.selectedSwitch}
                  placeholder="Switch"
                  onChange={(entry) => {
                    this.setState({ selectedSwitch: entry });
                    this.handleFilterChange(
                      entry.map((o) => {
                        return o.value;
                      }),
                      'switch'
                    );
                  }}
                  isMulti
                  options={this.displayOptions('switch')}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>
              <div id="filterInput">
                {this.state.selectedStatus.length > 0 && (
                  <div className="filterNumber" id="statusFilterNumber">
                    {this.state.selectedStatus.length}
                  </div>
                )}
                <Select
                  value={this.state.selectedStatus}
                  placeholder="Status"
                  onChange={(entry) => {
                    this.setState({
                      selectedStatus: entry,
                      displayMode: entry[0].value,
                      disabledValue: false,
                    });
                    this.handleFilterChange(
                      entry.map((o) => {
                        return o.label;
                      }),
                      'status'
                    );
                  }}
                  isMulti
                  options={[
                    { value: 'healthyMiners', label: 'Healthy' },
                    { value: 'issueMiners', label: 'Issues' },
                    { value: 'noAPIResponseMiners', label: 'No API Response' },
                    { value: 'offlineMiners', label: 'Offline' },
                    { value: 'inRepairMiners', label: 'In Repair' },
                  ]}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>
            </div>
          )}
        {this.props.rules &&
          this.props.rules.features.includes('view_general_table') &&
          this.state.displayTable === 'general' && (
            <div className="filter_input">
              <button
                onClick={() => {
                  this.setState({ isFilterApplied: true });
                  this.updateDataSetBasedonFilter();
                }}
                id="filterBtn"
              >
                <span>Apply Filters</span>
              </button>

              <div
                disabled={this.state.disabledValue}
                onClick={() => {
                  this.setState({
                    filtered: [],
                    filteredData: null,
                    filteredDataObj: null,
                    selectedCustomer: [],
                    selectedPool: [],
                    selectedType: [],
                    selectedLocation: [],
                    selectedSwitch: [],
                    selectedStatus: [],
                    disabledValue: true,
                    displayMode: 'allMiners',
                    isFilterApplied: false,
                  });
                }}
                className={
                  this.state.selectedCustomer.length > 0 ||
                  this.state.selectedPool.length > 0 ||
                  this.state.selectedLocation.length > 0 ||
                  this.state.selectedStatus.length > 0 ||
                  this.state.selectedSwitch.length > 0 ||
                  this.state.selectedType.length > 0
                    ? 'appBtn clearBtn'
                    : 'appBtn disabledBtn'
                }
              >
                <span>Clear Filters</span>
              </div>
            </div>
          )}
        {/* Filters Tags */}
        {(this.state.selectedCustomer.length > 0 ||
          this.state.selectedPool.length > 0 ||
          this.state.selectedType.length > 0 ||
          this.state.selectedSwitch.length > 0 ||
          this.state.selectedLocation.length > 0 ||
          this.state.selectedStatus.length > 0) &&
          this.props.rules &&
          this.props.rules.features.includes('view_general_table') &&
          this.state.displayTable === 'general' && (
            <div className="tagContainer">
              <div className="tagContainer">
                {this.state.selectedCustomer.map((customer) => {
                  return (
                    <div
                      className="filterTag customerTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() =>
                        this.removeFilterOption(customer.value, 'customer_name')
                      }
                    >
                      {customer.value} &nbsp; x
                    </div>
                  );
                })}
              </div>
              <div className="tagContainer">
                {this.state.selectedPool.map((pool) => {
                  return (
                    <div
                      className="filterTag poolTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() =>
                        this.removeFilterOption(pool.value, 'pool')
                      }
                    >
                      {pool.value} &nbsp; x
                    </div>
                  );
                })}
              </div>
              <div className="tagContainer">
                {this.state.selectedType.map((type) => {
                  return (
                    <div
                      className="filterTag typeTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() =>
                        this.removeFilterOption(type.value, 'type')
                      }
                    >
                      {type.value} &nbsp; x
                    </div>
                  );
                })}
              </div>
              <div className="tagContainer">
                {this.state.selectedLocation.map((area) => {
                  return (
                    <div
                      className="filterTag locationTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() =>
                        this.removeFilterOption(area.value, 'mine_controller')
                      }
                    >
                      {area.value} &nbsp; x
                    </div>
                  );
                })}
              </div>
              <div className="tagContainer">
                {this.state.selectedSwitch.map((pool) => {
                  return (
                    <div
                      className="filterTag switchTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() =>
                        this.removeFilterOption(pool.value, 'switch')
                      }
                    >
                      {pool.value} &nbsp; x
                    </div>
                  );
                })}
              </div>
              <div className="tagContainer">
                {this.state.selectedStatus.map((status) => {
                  return (
                    <div
                      className="filterTag statusTag"
                      is_filter_applied={
                        this.state.isFilterApplied ? 'true' : 'false'
                      }
                      onClick={() => {
                        this.removeFilterOption(status.label, 'status');
                        this.setState({ displayMode: 'allMiners' });
                      }}
                    >
                      {status.label} &nbsp; x
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        <div className="worker_stats_count_container">
          {this.props.rules &&
            this.props.rules.features.includes('view_general_table') &&
            this.state.displayTable === 'general' && (
              <div className="worker_stats_count">
                <div className="worker_count">
                  <p id="worker_stat_count_label">All Workers</p>
                  <p id="worker_stat_count_value">
                    {this.state.filteredDataObj
                      ? this.state.filteredDataObj.allMinerCount
                      : this.state.allMinerCount}
                  </p>
                </div>
                <div className="worker_count">
                  <p id="worker_stat_count_label">Healthy Workers</p>
                  <p id="worker_stat_count_value">
                    {this.state.filteredDataObj
                      ? this.state.filteredDataObj.healthyMinerCount
                      : this.state.healthyMinerCount}
                  </p>
                  <div
                    id="worker_stat_count_color"
                    style={{ backgroundColor: '#72D98C' }}
                  ></div>
                </div>
                <div className="worker_count">
                  <p id="worker_stat_count_label">Workers with Issues</p>
                  <p id="worker_stat_count_value">
                    {this.state.filteredDataObj
                      ? this.state.filteredDataObj.issueMinerCount
                      : this.state.issueMinerCount}
                  </p>
                  <div
                    id="worker_stat_count_color"
                    style={{ backgroundColor: '#FF9933' }}
                  ></div>
                </div>
                <div className="worker_count">
                  <p id="worker_stat_count_label">
                    Workers with no API Response
                  </p>
                  <p id="worker_stat_count_value">
                    {this.state.filteredDataObj
                      ? this.state.filteredDataObj.noAPIResponseMinerCount
                      : this.state.noAPIResponseMinerCount}
                  </p>
                  <div
                    id="worker_stat_count_color"
                    style={{ backgroundColor: '#EE5A5A' }}
                  ></div>
                </div>
                <div className="worker_count">
                  <p id="worker_stat_count_label">Offline Workers</p>
                  <p id="worker_stat_count_value">
                    {this.state.filteredDataObj
                      ? this.state.filteredDataObj.offlineMinerCount
                      : this.state.offlineMinerCount}
                  </p>
                  <div
                    id="worker_stat_count_color"
                    style={{ backgroundColor: '#C8C8C8' }}
                  ></div>
                </div>
                <div className="worker_count">
                  <p id="worker_stat_count_label">Workers in Repair</p>
                  <p id="worker_stat_count_value">
                    {this.state.filteredDataObj
                      ? this.state.filteredDataObj.inRepairMinerCount
                      : this.state.inRepairMinerCount}
                  </p>
                  <div
                    id="worker_stat_count_color"
                    style={{ backgroundColor: '#FFD440' }}
                  ></div>
                </div>
              </div>
            )}
          {this.props.rules &&
            this.props.rules.features.includes('view_performance_table') &&
            this.state.displayTable === 'performance' && (
              <div className="worker_stats_count">
                <div className="worker_count">
                  <p id="worker_stat_count_label">All Workers</p>
                  <p id="worker_stat_count_value">
                    {this.state.allPoolWorkers.length}
                  </p>
                </div>
                <div className="worker_count">
                  <p id="worker_stat_count_label">Healthy Workers</p>
                  <p id="worker_stat_count_value">
                    {this.state.healthyPoolWorkers.length}
                  </p>
                  <div
                    id="worker_stat_count_color"
                    style={{ backgroundColor: '#72D98C' }}
                  ></div>
                </div>
                <div className="worker_count">
                  <p id="worker_stat_count_label">Offline Workers</p>
                  <p id="worker_stat_count_value">
                    {this.state.offlinePoolWorkers.length}
                  </p>
                  <div
                    id="worker_stat_count_color"
                    style={{ backgroundColor: '#C8C8C8' }}
                  ></div>
                </div>
              </div>
            )}
        </div>
        <div className="worker_table_container">
          <Tabs
            defaultActiveKey={
              this.props.rules &&
              this.props.rules.features.includes('view_performance_table') &&
              !this.props.rules.features.includes('view_general_table')
                ? 'performance'
                : 'general'
            }
            className="tabs"
            onSelect={(event) => this.handleSelect(event)}
          >
            {this.props.rules &&
              this.props.rules.features.includes('view_performance_table') &&
              this.state.poolWorkers && (
                <Tab eventKey={'performance'} title="Pool Workers">
                  <PerformanceTable
                    data={this.state.poolWorkers}
                    tableType={this.state.displayTable}
                    nodes_to_action={this.state.nodes_to_action}
                    displayReportModal={this.state.displayReportModal}
                    handleCloseReportModal={this.handleCloseReportModal}
                    setTotalHashrate={this.setData}
                    deleteWorkers={this.deleteWorkers}
                  ></PerformanceTable>
                </Tab>
              )}
            {this.props.rules &&
              this.props.rules.features.includes('view_general_table') && (
                <Tab eventKey={'general'} title="Miners">
                  <GeneralTable
                    data={data}
                    columns={columns}
                    tableType={this.state.displayTable}
                    nodes_to_action={this.state.nodes_to_action}
                    displayReportModal={this.state.displayReportModal}
                    handleSearchChange={this.handleSearchChange}
                    deleteWorkers={this.deleteWorkers}
                    handleCloseReportModal={this.handleCloseReportModal}
                    action_miner={this.action_miner}
                    tagMinerAsOffline={this.tagMinerAsOffline}
                    configure_miners={this.configure_miners}
                    change_pools={this.change_pools}
                    generate_QR_codes={this.generate_QR_codes}
                    filtered={this.state.filtered}
                  ></GeneralTable>
                </Tab>
              )}
          </Tabs>
        </div>
      </div>
    );
  }

  getMinerDisplayData = (displayMode) => {
    let minerCount = null;
    let data = null;
    let title;
    switch (displayMode) {
      case 'Healthy':
      case 'healthyMiners':
        minerCount = this.state.healthyMinerCount;
        data = this.state.data;
        title = 'Healthy miners';
        break;
      case 'allMiners':
        minerCount = this.state.allMinerCount;
        data = this.state.allMiners;
        title = 'All miners';
        break;
      case 'Issues':
      case 'issueMiners':
        minerCount = this.state.issueMinerCount;
        data = this.state.issueMiners;
        title = 'Miners with issues';
        break;
      case 'NoAPIResponse':
      case 'noAPIResponseMiners':
        minerCount = this.state.noAPIResponseMinerCount;
        data = this.state.noAPIResponseMiners;
        title = 'Miners with no API response';
        break;
      case 'Offline':
      case 'offlineMiners':
        minerCount = this.state.offlineMinerCount;
        data = this.state.offlineMiners;
        title = 'Offline miners';
        break;
      case 'In Repair':
      case 'inRepairMiners':
        minerCount = this.state.inRepairMinerCount;
        data = this.state.inRepairMiners;
        title = 'Miners in repair';
        break;
      default:
        minerCount = this.state.allMinerCount;
        data = this.state.allMiners;
        title = 'Choose miner display';
    }
    return { minerCount, data, title };
  };

  getData = async () => {
    if (this.props.minersData) {
      const data = await this.props.minersData;
      this.setState({
        data: data.healthyMiners,
        allMiners: data.allMiners,
        issueMiners: data.issueMiners,
        noAPIResponseMiners: data.noAPIResponseMiners,
        offlineMiners: data.offlineMiners,
        inRepairMiners: data.inRepairMiners,
        issueMinerCount: data.issueMiners.length,
        noAPIResponseMinerCount: data.noAPIResponseMiners.length,
        healthyMinerCount: data.healthyMiners.length,
        allMinerCount: data.allMiners.length,
        offlineMinerCount: data.offlineMiners.length,
        inRepairMinerCount: data.inRepairMiners.length,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: true });
    }
    if (this.props.pool_status === null) {
      await this.props.getBlockseerWorkerStatus(this.props.auth.token);
    }
    this.setState({
      allPoolWorkers: this.props.pool_status.data.allWorkers,
      healthyPoolWorkers: this.props.pool_status.data.healthyWorkers,
      offlinePoolWorkers: this.props.pool_status.data.offlineWorkers,
    });
    this.props.getRulesFeaturesRequest(this.props.auth.token);
    let permission = get(this.props, 'auth.permission', null);
    if (permission && permission < 3) {
      this.props.getCustomers(this.props.auth);
      this.props.getUrls(this.props.auth);
    }
    let permissionAdmins = 2;
    if (permission > permissionAdmins) {
      this.props.getCustomerPools(this.props.auth);
    }
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleSelect(key) {
    this.setState({ displayTable: key });
  }

  handleSearchChange = (event) => {
    event.preventDefault();
    const filter = event.target.value === '' ? null : event.target.value;
    this.setState({ filter });
  };

  handleOpenActionModal = (
    modal,
    actionOnFilteredMiners,
    clock_freq_result
  ) => {
    this.setState({
      displayModal: modal,
      actionOnFilteredMiners,
      clock_freq_result,
    });
  };

  handleFilterChange = (value, accessor) => {
    let filtered = this.state.filtered;
    let isInsertNewFilter = true;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) {
            filtered.splice(i, 1);
          } else filter['value'] = value;
          isInsertNewFilter = false;
        }
      });
    }

    if (isInsertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({
      filtered,
      activeRows: [],
      nodes_to_action: [],
    });
  };

  toggleRow(nodename) {
    let nodes_to_action = [...this.state.nodes_to_action];
    const keyIndex = nodes_to_action.indexOf(nodename);
    let last_selected = -1;
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it exists, remove it
      nodes_to_action = [
        ...nodes_to_action.slice(0, keyIndex),
        ...nodes_to_action.slice(keyIndex + 1),
      ];
    } else {
      last_selected = nodename;
      nodes_to_action.push(nodename);
    }
    this.setState({
      nodes_to_action,
      selectAll: 2,
      last_selected: last_selected,
    });
  }

  toggleSelectAll() {
    let nodes_to_action = [];
    let data = null;
    if (!this.state.filteredData) {
      data = this.getMinerDisplayData(this.state.displayMode).data;
    } else {
      data = this.state.filteredData;
    }

    if (this.state.selectAll === 0) {
      data.forEach((item) => {
        nodes_to_action.push(item.nodename);
      });
    }

    this.setState({
      nodes_to_action,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  displayOptions = (category) => {
    const data = this.getMinerDisplayData(this.state.displayMode).data;
    let arr = data.map((val) => {
      return val[`${category}`];
    });
    const uniqueArr = uniqBy(arr, (e) => {
      return e;
    });
    const optionsList = uniqueArr.map((val, i) => {
      return { id: i, value: val, label: val };
    });

    return optionsList;
  };

  updateDataSetBasedonFilter = (filtered) => {
    if (!filtered) {
      filtered = this.state.filtered;
    }
    if (get(filtered, 'length', 0) < 1) {
      this.setState({
        filteredData: null,
        disabledValue: true,
      });
      return;
    }
    //Code for filtering data based on filters
    const filterFunc = (dataToFilter) => {
      return dataToFilter.filter((row) => {
        let displayRow = false;
        let filterApplied = false;
        filtered.forEach((filter) => {
          const id = filter.pivotId || filter.id;
          if (displayRow || !filterApplied) {
            filterApplied = true;
            displayRow =
              row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
          }
        });

        return displayRow;
      });
    };
    let dataToFilter = this.getMinerDisplayData(this.state.displayMode).data;
    const filteredData = filterFunc(dataToFilter);
    const healthyMinerCount = filterFunc(this.state.data).length;
    const issueMinerCount = filterFunc(this.state.issueMiners).length;
    const noAPIResponseMinerCount = filterFunc(
      this.state.noAPIResponseMiners
    ).length;
    const inRepairMinerCount = filterFunc(this.state.inRepairMiners).length;
    const offlineMinerCount = filterFunc(this.state.offlineMiners).length;
    const allMinerCount = filteredData.length;

    const filteredDataObj = {
      allMinerCount: allMinerCount,
      healthyMinerCount: healthyMinerCount,
      inRepairMinerCount: inRepairMinerCount,
      issueMinerCount: issueMinerCount,
      noAPIResponseMinerCount: noAPIResponseMinerCount,
      offlineMinerCount: offlineMinerCount,
    };

    this.setState({
      filteredData,
      activeRows: [],
      nodes_to_action: [],
      disabledValue: false,
      filteredDataObj,
    });
  };

  ctrlLoader = (command) => {
    if (command === 'off') {
      swal.close();
    } else {
      swal({
        title: 'Loading',
      });
      swal.showLoading();
    }
  };

  display_warning = async (action, filtered) => {
    let miner_count = filtered
      ? this.getFilteredMiners().length
      : this.state.nodes_to_action.length;
    return await swal({
      title: `Are you sure you want to perform a ${action} on ${miner_count} miner(s)?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, perform ${action}`,
      cancelButtonText: 'No, cancel',
      reverseButtons: true,
      onClose: () => history.push('/mineManagement/miners'),
    }).then((result) => {
      return result;
    });
  };

  action_miner = (
    action,
    filtered = false,
    pool = null,
    url = null,
    clock_frequency = null
  ) => {
    let action_text = action.replace('_', ' ');
    const miners = filtered
      ? this.getFilteredMiners()
      : this.state.nodes_to_action;
    if (miners.length > 0) {
      this.display_warning(action_text, filtered).then((result) => {
        if (result.value) {
          let request = {
            miners: miners,
            command: action,
            user_id: parseInt(this.props.auth.user_id),
            status: 'pending',
          };
          // if (action === 'flash') {
          //   request.params = { flash_on: true };
          // }
          // if (action === 'update_pools') {
          //   request.params = {
          //     user: pool,
          //     url: url,
          //     password: '123',
          //   };
          // }
          // if (action === 'change_clock') {
          //   request.params = { new_clock_frequency: clock_frequency };
          // }
          axios
            .put(config.urls.nodeServer + '/admin/command_status', request, {
              headers: {
                Authorization: this.props.auth.token,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                swal({
                  title: `Successfully requested ${action_text} on miner(s)`,
                  onClose: () => history.push('/mineManagement/miners'),
                });
              } else {
                swal({
                  title: `${action_text} on miner(s) did not succeed, contact your system administrator or try again later`,
                  onClose: () => history.push('/mineManagement/miners'),
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    } else {
      swal({
        title: `You haven't selected any miners`,
        type: 'error',
        onClose: () => history.push('/mineManagement/miners'),
      });
    }
  };

  tagMinerAsOffline = async (filtered = false) => {
    const inputOptions = config.problemIndicators;
    let miner_status = null;
    inputOptions['other_indicator'] = 'Add new indicator';
    const tag_miner_result = await swal({
      title: 'Select Miner Status',
      input: 'select',
      inputOptions: config.minerStatus,
      showCancelButton: true,
      inputValidator: (value) => {
        return !value && 'You need to write something!';
      },
    }).then(async (result) => {
      miner_status = null;
      if (result.value === 'inRepair') {
        miner_status = 'in_repair';
      } else {
        miner_status = 'offline';
      }
      return await swal({
        title: 'Select indicator of the problem',
        input: 'select',
        inputOptions: inputOptions,
      }).then(async (result) => {
        if (result.value === 'other_indicator') {
          const new_indicator = await swal({
            title: 'Enter indicator',
            input: 'text',
            showCancelButton: true,
            inputValidator: (value) => {
              return !value && 'You need to write something!';
            },
          });
          return {
            date: new Date().toString(),
            miner_status: miner_status,
            indicator: new_indicator.value,
          };
        } else {
          return {
            date: new Date().toString(),
            miner_status: miner_status,
            indicator: result.value,
          };
        }
      });
    });
    this.display_warning('tag miners as offline').then((result) => {
      if (result.value) {
        let request = {
          miners: filtered
            ? this.getFilteredMiners()
            : this.state.nodes_to_action,
          indicator: tag_miner_result,
          miner_status: miner_status,
        };
        let config_headers = {
          headers: {
            Authorization: this.props.auth.token,
          },
        };
        axios
          .post(
            config.urls.nodeServer + '/admin/miners/offline',
            request,
            config_headers
          )
          .then(function (response) {
            if (response.status === 200) {
              swal({
                title: 'Successfully requested tagging miner(s) as offline',
                onClose: () => history.push('/mineManagement/miners'),
              });
            } else {
              swal({
                title:
                  'Tagging miner(s) offline did not succeed, contact your system administrator or try again later',
                onClose: () => history.push('/mineManagement/miners'),
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  getFilteredMiners = () => {
    // more efficient to store miners as objects
    // goes through all the filter miners if value is true, push to array
    const minersToAction = [];
    map(this.state.filteredData, (value, key) => {
      minersToAction.push(value.nodename);
    });
    return minersToAction;
  };

  printData = () => {
    const data = this.state.filteredData
      ? this.state.filteredData
      : this.state.data;
    const tdPadding = '3px 7px';
    let html = `<table id="printTable" border='1|1' style='border-collapse:collapse'>
      <tr>
        <thead style='font-weight:bold'>
          <td style='padding:${tdPadding}'>No</td>
          <td style='padding:${tdPadding}'>Nodename</td>
          <td style='padding:${tdPadding}'>IP</td>
          <td style='padding:${tdPadding}'>Pool</td>
          <td style='padding:${tdPadding}'>Customer</td>
          <td style='padding:${tdPadding}'>Mine Controller</td>
          <td style='padding:${tdPadding}'>Switch</td>
          <td style='padding:${tdPadding}'>Port</td>
          <td style='padding:${tdPadding}'>Hashrate</td>
          <td style='padding:${tdPadding}'>Temp</td>
          <td style='padding:${tdPadding}'>Type</td>
          <td style='padding:${tdPadding}'>Last Updated</td>
        </thead>
      </tr>`;
    data.forEach((val, i) => {
      html += '<tr>';
      html += `<td style='padding:${tdPadding}'>${i + 1}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.nodename}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.ip}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.pool}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.customer_name}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.mine_controller}</td>`;
      html += `<td style='padding:${tdPadding}; white-space:nowrap;'>${val.switch}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.port}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.hashrate}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.temperature}</td>`;
      html += `<td style='padding:${tdPadding}'>${val.type}</td>`;
      html += `<td style='padding:${tdPadding}'>${new Date(
        val.last_updated
      ).toLocaleString()}</td>`;
      html += '</tr>';
    });
    html += '</table>';
    let newWin = window.open('');
    newWin.document.write(html);
    newWin.print();
    newWin.close();
  };

  generate_QR_codes = (filtered) => {
    const miners = filtered
      ? this.getFilteredMiners()
      : this.state.nodes_to_action;
    if (miners.length > 0) {
      this.display_warning('QR code generation', filtered).then((result) => {
        if (result.value) {
          let request = {
            miners: miners,
          };
          let config_headers = {
            headers: {
              Authorization: this.props.auth.token,
            },
          };
          axios
            .post(
              config.urls.nodeServer + '/admin/miners/id',
              request,
              config_headers
            )
            .then(async (json_response) => {
              const ids = json_response.data;
              await generateQR(ids);
              await swal({
                title: `Successfully generated QR codes for ${ids.length} miner(s)`,
                onClose: () => window.location.reload(false),
              });
            });
        }
      });
    } else {
      swal({
        title: `You haven't selected any miners`,
        type: 'error',
        onClose: () => history.push('/mineManagement/miners'),
      });
    }
  };

  configure_miners = async (filter = false) => {
    let permission = get(this.props, 'auth.permission', null);
    if (permission !== null) {
      let inputOptionsPools = null;
      let permissionAdmins = 2;
      if (
        permission > permissionAdmins &&
        get(this.props, 'customer_pools', null)
      ) {
        inputOptionsPools = this.props.customer_pools;
      } else {
        inputOptionsPools = Object.assign(
          {},
          ...Object.entries(this.props.urls.urls).map(([a, b]) => ({ [a]: a }))
        );
      }
      inputOptionsPools['add_new_pool'] = 'Change to new pool';
      let adding_new_pool = false;
      const change_pool_result = await swal({
        title: 'Select new pool',
        input: 'select',
        inputOptions: inputOptionsPools,
      }).then(async (result) => {
        if (result.value === 'add_new_pool') {
          adding_new_pool = true;
          return await swal({
            title: 'Enter the new pool name',
            input: 'text',
            showCancelButton: true,
            inputValidator: (value) => {
              return !value && 'You need to write something!';
            },
          }).then(async (pool_result) => {
            const url = await swal({
              title: 'Enter the new URL',
              input: 'text',
              showCancelButton: true,
              inputValidator: (value) => {
                return !value && 'You need to write something!';
              },
            });
            return { url: url.value, pool: pool_result.value };
          });
        } else {
          return result.value;
        }
      });
      let pool = adding_new_pool ? change_pool_result.pool : change_pool_result;
      let url = adding_new_pool
        ? change_pool_result.url
        : this.props.urls.urls[pool];
      if (!filter) {
        this.action_miner('update_pools', false, pool, url);
      } else {
        this.action_miner('update_pools', true, pool, url);
      }
    }
  };

  change_pools = async () => {
    let permission = get(this.props, 'auth.permission', null);
    if (permission !== null) {
      let inputOptionsTypes = {};
      const types = this.displayOptions('type');
      Object.keys(types).forEach(function (key) {
        inputOptionsTypes[types[key]['value']] = types[key]['value'];
      });

      this.handleOpenActionModal('clock', true);

      const clock_freq_result = await swal({
        title: 'Select API to change clock frequency',
        input: 'select',
        inputOptions: inputOptionsTypes,
      }).then(async (result) => {
        if (result.value) {
          if (result.value.includes('bOS')) {
            return 'bOS API';
          } else if (result.value.includes('Innosilicon')) {
            return 'Innosilicon API';
          } else if (result.value.includes('GPU')) {
            return 'GPU API';
          } else {
            return 'Antminer API';
          }
        }
      });
      this.handleOpenActionModal('clock', true, clock_freq_result);
    }
  };

  removeFilterOption = (value, accessor) => {
    let {
      filtered,
      selectedPool,
      selectedCustomer,
      selectedLocation,
      selectedType,
      selectedSwitch,
      selectedStatus,
    } = this.state;

    filtered.forEach((filter, i) => {
      if (filter.id === accessor && filter.value.includes(value)) {
        const valueIndex = filter.value.indexOf(value);
        filter.value.splice(valueIndex, 1);
        if (filter.id === 'customer_name')
          selectedCustomer.splice(valueIndex, 1);
        if (filter.id === 'pool') selectedPool.splice(valueIndex, 1);
        if (filter.id === 'type') selectedType.splice(valueIndex, 1);
        if (filter.id === 'mine_controller')
          selectedLocation.splice(valueIndex, 1);
        if (filter.id === 'switch') selectedSwitch.splice(valueIndex, 1);
        if (filter.id === 'status') selectedStatus.splice(valueIndex, 1);
      }
      if (filter.value.length === 0) filtered.splice(i, 1);
    });

    this.setState({ filtered, selectedCustomer, selectedPool });
  };
}

const mapStateToProps = ({
  auth,
  miner_status,
  rules,
  customers,
  urls,
  slushpool,
  customer_pools,
  antpool,
  pool_hashrate,
  pool_status,
}) => {
  return {
    auth,
    miner_status,
    rules,
    customers,
    urls,
    slushpool,
    customer_pools,
    antpool,
    pool_hashrate,
    pool_status,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, {
  loginRequest,
  tokenLoginRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  getUrls,
  logoutRequest,
  getCustomerPools,
  getBlockseerWorkerStatus,
})(Miners);
