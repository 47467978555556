import React, { Component } from 'react';
import axios from 'axios';
import './Admin.scss';
import { connect } from 'react-redux';
import {
  loginRequest,
  tokenLoginRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  logoutRequest,
  getUrls,
  deleteAccountsRequest,
  getCustomerPools,
  getPools,
} from '../../actions';
import UserManagement from './UserManagement';
import MiningPayouts from './MiningPayouts';
import MinerSettings from './MinerSettings';
import CustomerManagement from './CustomerManagement';
import PoolManagement from './PoolManagement';
import CanDisplay from '../CanDisplay';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
const config = require('../../config');

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      userManagementData: null,
      displayUserManagement: true,
      displayMiningPayouts: false,
      displayMinerSettings: false,
      displayWorkerGroups: false,
      totalUsers: null,
      totalAdmins: null,
      totalViewers: null,
    };
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    await this.getUserManagementData();
    await this.getPayoutData();
    await this.getMinerSettingsData();
    await this.props.getCustomerPools(this.props.auth);
    await this.props.getPools(this.props.auth);
    await this.props.getCustomers(this.props.auth);
  };

  handleChange = (toggleKey) => {
    const stateCopy = { ...this.state };
    const nextValue = !stateCopy[toggleKey];
    Object.keys(stateCopy).forEach((key) => (stateCopy[key] = false));
    stateCopy[toggleKey] = nextValue;
    this.setState(stateCopy);
  };

  getUserManagementData = () => {
    axios
      .get(config.urls.nodeServer + `/admin/users/${this.props.auth.user_id}`, {
        headers: {
          Authorization: this.props.auth.token,
        },
      })
      .then((response) => {
        this.setState({
          userManagementData: response.data.users[0],
        });
        let totalAdmins = 0;
        let totalUsers = response.data.users[0].length;
        let totalViewers = 0;
        response.data.users[0].forEach((user) => {
          if (user.role_type.includes('viewer')) {
            totalViewers += 1;
          } else if (user.role_type.includes('admin')) {
            totalAdmins += 1;
          }
        });
        this.setState({
          totalUsers,
          totalAdmins,
          totalViewers,
        });
      });
  };

  getPayoutData = () => {
    axios
      .get(
        config.urls.nodeServer +
          `/admin/payout_thresholds/${this.props.auth.user_id}`,
        {
          headers: {
            Authorization: this.props.auth.token,
          },
        }
      )
      .then((response) => {
        this.setState({
          miningPayoutData: response.data.thresholds[0],
        });
      });
  };

  getMinerSettingsData = () => {
    axios
      .get(config.urls.nodeServer + '/admin/thresholds', {
        headers: {
          Authorization: this.props.auth.token,
        },
      })
      .then((response) => {
        this.setState({
          minerSettingsData: response.data.all_thresholds,
        });
      });
  };

  render() {
    return (
      <div className="adminUI chartsSection">
        <div className="adminHeaderContainer">
          <div id="adminLabel">Admin Manager</div>
          <CanDisplay
            rules={this.props.rules}
            perform="view_admin_info_card"
            yes={() => (
              <div className="userCardContainer">
                <div className="userCard">
                  <div className="userCardLabel">Total Users</div>
                  <div className="userCardValue">
                    {this.state.totalUsers ? this.state.totalUsers : 'N/A'}
                  </div>
                </div>
                <div className="userCard">
                  <div className="userCardLabel">Total Viewers</div>
                  <div className="userCardValue">
                    {this.state.totalViewers ? this.state.totalViewers : 'N/A'}
                  </div>
                </div>
                <div className="userCard">
                  <div className="userCardLabel">Total Admins</div>
                  <div className="userCardValue">
                    {this.state.totalAdmins ? this.state.totalAdmins : 'N/A'}
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <div className="adminContainer">
          <Tabs defaultActiveKey="add_user" className="tabs">
            {this.props.rules &&
              this.props.rules.features.includes(
                'view_admin_user_management_tab'
              ) &&
              this.state.userManagementData &&
              this.props.customers && (
                <Tab eventKey="add_user" title="User Management">
                  <UserManagement
                    rules={this.props.rules}
                    reloadData={this.getUserManagementData}
                    customers={this.props.customers}
                    userManagementData={this.state.userManagementData}
                  />
                </Tab>
              )}
            {this.props.rules &&
              this.props.rules.features.includes(
                'view_admin_mining_payout_tab'
              ) &&
              this.state.miningPayoutData && (
                <Tab eventKey="mining_payouts" title="Mining Payouts">
                  <MiningPayouts
                    miningPayoutData={this.state.miningPayoutData}
                  />
                </Tab>
              )}
            {this.props.rules &&
              this.props.rules.features.includes(
                'view_admin_miner_settings_tab'
              ) &&
              this.state.minerSettingsData && (
                <Tab eventKey="miner_settings" title="Miner Settings">
                  <MinerSettings
                    minerSettingsData={this.state.minerSettingsData}
                  />
                </Tab>
              )}
            {this.props.rules &&
              this.props.rules.features.includes(
                'view_admin_customer_management_tab'
              ) &&
              this.props.customers &&
              this.props.customer_pools &&
              this.props.pools && (
                <Tab eventKey="add_customer" title="Customer Management">
                  <CustomerManagement
                    rules={this.props.rules}
                    pools={this.props.pools}
                    customers={this.props.customers}
                    customerPools={this.props.customer_pools}
                    customerManagementData={this.state.customerManagementData}
                  />
                </Tab>
              )}
            {this.props.rules &&
              this.props.rules.features.includes(
                'view_admin_pool_management_tab'
              ) &&
              this.props.pools && (
                <Tab eventKey="add_pool" title="Pool Management">
                  <PoolManagement
                    rules={this.props.rules}
                    pools={this.props.pools['all_pools']}
                  />
                </Tab>
              )}
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, customers, rules, customer_pools, pools }) => {
  return {
    auth,
    rules,
    customers,
    customer_pools,
    pools,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, {
  loginRequest,
  tokenLoginRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  getUrls,
  logoutRequest,
  deleteAccountsRequest,
  getCustomerPools,
  getPools,
})(Admin);
