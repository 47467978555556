import React, { Component } from 'react';
import './Miners.scss';
import ReactTable from 'react-table';
import moment from 'moment';
import swal from 'sweetalert2';

class PerformanceTable extends Component {
  constructor(props) {
    super();
    this.state = {
      displayReportModal: props.displayReportModal,
      filtered: props.filtered,
      nodes_to_action: props.nodes_to_action,
      filter: null,
      data: [],
    };
  }

  componentDidMount() {
    if (this.props.data.length > 1) {
      const enabledMiners = this.props.data.filter((miner) => !miner.disabled);
      this.setState({
        data: enabledMiners,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const enabledMiners = this.props.data.filter((miner) => !miner.disabled);
      this.setState({
        data: enabledMiners,
      });
    }
  }

  formatHashrate = (hashrate) => {
    if (hashrate === 'N/A') {
      return hashrate;
    } else if (hashrate / 1000000000000000 < 1) {
      return (hashrate / 1000000000000).toFixed(2) + ' TH/s';
    } else {
      return (hashrate / 1000000000000000).toFixed(2) + ' PH/s';
    }
  };

  formatRewards = (reward) => {
    if (reward === 'N/A') {
      return reward;
    } else {
      // Convert Satoshi to BTC
      return reward / 100000000;
    }
  };

  handleSearchChange = (event) => {
    event.preventDefault();
    const filter = event.target.value === '' ? null : event.target.value;
    this.setState({ filter });
  };

  toggleSelectAll() {
    let nodes_to_action = [];
    let data = this.state.data;
    if (this.state.selectAll === 0) {
      data.forEach((item) => {
        nodes_to_action.push(item.worker_name);
      });
    }
    this.setState({
      nodes_to_action,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
    this.props.setTotalHashrate();
  }

  toggleRow(worker_name) {
    let nodes_to_action = [...this.state.nodes_to_action];
    const keyIndex = nodes_to_action.indexOf(worker_name);
    let last_selected = -1;
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it exists, remove it
      nodes_to_action = [
        ...nodes_to_action.slice(0, keyIndex),
        ...nodes_to_action.slice(keyIndex + 1),
      ];
    } else {
      last_selected = worker_name;
      nodes_to_action.push(worker_name);
    }
    this.setState({
      nodes_to_action,
      selectAll: 2,
      last_selected: last_selected,
    });
  }

  render() {
    const { filter } = this.state;
    const lowercasedFilter = filter ? filter : null;
    let data = null;
    const filteredSearchData = this.state.data.filter((item) => {
      return Object.keys(item).some((key) => {
        if (
          item !== null &&
          key !== null &&
          item[key] !== null &&
          item[key].toString().toLowerCase().includes(lowercasedFilter) &&
          lowercasedFilter !== null
        ) {
          return item[key];
        } else return null;
      });
    });
    if (filteredSearchData.length !== 0 && this.state.filter !== null) {
      data = filteredSearchData;
    } else if (filteredSearchData.length === 0 && this.state.filter !== null) {
      data = [];
    } else {
      data = this.state.data;
    }

    let columns = [
      {
        id: 'checkbox',
        accessor: '',
        Cell: ({ original }) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={
                this.state.nodes_to_action.includes(original.worker_name) ===
                true
              }
              onClick={() => this.toggleRow(original.worker_name)}
              onChange={() => {}}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkboxHeader"
              checked={this.state.selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
              }}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        minWidth: 45,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Status
            </div>
          );
        },
        accessor: 'status',
        minwidth: 140,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Miner
              Group
            </div>
          );
        },
        accessor: 'worker_name',
        Cell: (row) => {
          return <div>{row.original.worker_name.split('.')[0]}</div>;
        },
        minwidth: 140,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Worker
              name
            </div>
          );
        },
        accessor: 'worker_name',
        Cell: (row) => {
          return <div>{row.original.worker_name.split('.')[1]}</div>;
        },
        minwidth: 140,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Hashrate
            </div>
          );
        },
        id: 'hashrate',
        accessor: 'hashrate',
        Cell: (row) => {
          return <div>{this.formatHashrate(row.original.hashrate)}</div>;
        },
        minwidth: 130,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Revenue
            </div>
          );
        },
        accessor: 'revenue',
        Cell: (row) => {
          return <div>{this.formatRewards(row.original.revenue)}</div>;
        },
        minWidth: 130,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Valid
              Shares
            </div>
          );
        },
        accessor: 'valid_shares',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Stale
              Shares
            </div>
          );
        },
        accessor: 'stale_shares',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Invalid Shares
            </div>
          );
        },
        accessor: 'invalid_shares',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Last
              Updated
            </div>
          );
        },
        id: 'last_submitted',
        accessor: (row) => row.last_submitted,
        sortMethod: (a, b) => {
          let items = [a, b];
          let results = [];
          let myRegExp = /^(\d+)\+?\s(\w+)\sago$/;
          items.map((item) => {
            let inputString = item;
            let result = myRegExp.exec(inputString);
            let num = result[1];
            let duration = result[2];
            let date_object = moment().subtract(duration, num).toDate();
            results.push(date_object);
            return item;
          });
          let a1 = results[0];
          let b1 = results[1];
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
    ];
    return (
      <div id="container">
        <div className="filter_container">
          <div
            className="search_input_container"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <form className="inputBoxForm">
              <input
                className="input_box"
                placeholder="Search for worker(s)"
                onChange={this.handleSearchChange}
              ></input>
            </form>
            {/* <button
                            className="btn_action"
                            onClick={()=>{
                                this.setState({displayReportModal: true})
                            }}
                        >
                            <span>Export all</span>
                        </button> */}
            {this.state.nodes_to_action.length > 0 && (
              <button
                className="btn_delete"
                onClick={async () => {
                  const result = await swal({
                    title:
                      this.state.nodes_to_action.length > 1
                        ? `Are you sure you want to delete ${this.state.nodes_to_action.length} workers?`
                        : `Are you sure you want to delete this worker?`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: `Yes, delete`,
                    cancelButtonText: 'No, cancel',
                    reverseButtons: true,
                  });
                  if (result.value) {
                    swal.showLoading();
                    const deletedWorker = await this.props.deleteWorkers(
                      this.state.nodes_to_action,
                      'blockseer'
                    );
                    swal.close();
                    if (deletedWorker) {
                      swal({
                        title:
                          this.state.nodes_to_action.length > 1
                            ? 'Successfully deleted workers'
                            : 'Successfully deleted worker',
                      });
                    } else {
                      swal({
                        title:
                          this.state.nodes_to_action.length > 1
                            ? 'Failed to delete workers'
                            : 'Failed to delete worker',
                      });
                    }
                  }
                }}
              >
                <span>
                  {this.state.nodes_to_action.length > 1
                    ? 'Delete workers'
                    : 'Delete worker'}
                </span>
              </button>
            )}
            {/* {this.state.displayReportModal &&
                            <ReportModal 
                                showModal={this.state.displayReportModal}
                                hideModal={this.props.handleCloseReportModal}
                                filtered={this.state.filtered}
                            />
                        } */}
          </div>
          <div className="worker_data_container">
            {`Workers selected:  ${this.state.nodes_to_action.length}`}
          </div>
        </div>
        <div className="table_container">
          <ReactTable
            //If filters are applied, display filtered data. Else display healthy/broken miners depending on toggle.
            bordered={false}
            data={data}
            columns={columns}
            defaultPageSize={100}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: this.state.nodes_to_action.includes(
                      rowInfo.row.checkbox.nodename
                    )
                      ? '#1e1e2f'
                      : 'inherit',
                  },
                };
              } else {
                return {};
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default PerformanceTable;
