/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable prefer-promise-reject-errors */
import swal from 'sweetalert2';
import axios from 'axios';
import {
  LOGIN,
  SET_MINER_STATUS,
  SET_RULES_FEATURES,
  SET_CUSTOMERS,
  SET_URLS,
  SET_POOLS,
  SET_CUSTOMER_POOLS,
  SET_PERFORMANCE_DATA,
  GET_USERS,
  GET_WORKERS_HASHRATE_STATS,
  GET_POOL_HASHRATE_STATS,
  GET_POOL_REWARDS_STATS,
  GET_POOL_NAMES,
  GET_POOL_STATUS,
  GET_STRAPI_CONTENT,
} from './types';
import history from '../history';
import config from '../config';
import { STRAPI_CUSTOMERS } from '../constants';
import { indexOf } from 'lodash';

const error500 = () => {
  history.push('/mineManagement/error');
};

const TIMEOUT_THRESHOLD = 15000;

export const loginRequest = async (username, password) => {
  try {
    const config_headers = {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    };
    const res = await axios.get(
      `${config.urls.nodeServer}/auth/login`,
      config_headers
    );
    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging' ||
      process.env.REACT_APP_ENV === 'beta' ||
      process.env.REACT_APP_ENV === 'marapool'
    ) {
      return { result: true, response: res };
    }
    window.localStorage.setItem('token', res.data.token);
    window.localStorage.setItem('user_id', res.data.user_id);

    getRulesFeaturesRequest(res.data.token);

    const url = window.localStorage.getItem('url');
    if (url) {
      history.push(url);
      window.localStorage.removeItem('url');
    } else {
      history.push('/mineManagement/home');
    }
    return { result: true, response: res };
  } catch (err) {
    return { result: false, response: err };
  }
};

export const getMfaRequest = async (token, mfa_type) => {
  try {
    const config_headers = {
      headers: {
        Authorization: token,
      },
    };
    const res = await axios.get(
      `${config.urls.nodeServer}/auth/mfa/${mfa_type}`,
      config_headers
    );
    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.log('Failed two factor authentication, please try again later');
    return false;
  }
};

export const getMfaType = async (token) => {
  try {
    const config_headers = {
      headers: {
        Authorization: token,
      },
    };
    const res = await axios.get(
      `${config.urls.nodeServer}/auth/mfa_type`,
      config_headers
    );
    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.log('Failed two factor authentication, please try again later');
    return false;
  }
};

export const verifyMfaRequest = (verification_code, loginData) => (dispatch) =>
  new Promise((resolve, reject) => {
    const config_headers = {
      headers: {
        Authorization: loginData.token,
      },
    };
    axios
      .post(
        `${config.urls.nodeServer}/auth/mfa`,
        { verification_code },
        config_headers
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            payload: loginData,
            type: LOGIN,
          });

          resolve(res);

          window.localStorage.setItem('token', loginData.token);
          window.localStorage.setItem('user_id', loginData.user_id);

          getRulesFeaturesRequest(loginData.token);

          const url = window.localStorage.getItem('url');
          if (url) {
            history.push(url);
            window.localStorage.removeItem('url');
          } else {
            history.push('/mineManagement/home');
          }
        }
      })
      .catch((err) => {
        console.log('Failed two factor authentication, please try again later');
        dispatch({
          payload: null,
          type: LOGIN,
        });
        reject({ result: false, response: err });
      });
  });

export const createMinerSettingRequest = (miner_setting, auth) => () =>
  new Promise((resolve, reject) => {
    const createMinerSetting = new XMLHttpRequest();
    createMinerSetting.onreadystatechange = () => {
      if (createMinerSetting.readyState === 4) {
        if (createMinerSetting.status === 201) {
          resolve(createMinerSetting.response);
          swal({
            title: 'Successfully created new miner setting',
          });
        } else if (createMinerSetting.status >= 500) {
          error500();
          reject('Server error');
        } else if (createMinerSetting.status >= 400) {
          const response = JSON.parse(createMinerSetting.response);
          if (response) {
            swal({
              title: response.message,
            });
          } else {
            swal({
              title: 'Error creating new miner setting',
            });
          }
          reject('Session expired - please login');
        }
      }
    };
    createMinerSetting.onerror = () => {
      reject('Server error');
    };
    createMinerSetting.ontimeout = () => {
      reject('Connection timeout');
    };
    createMinerSetting.open(
      'post',
      `${config.urls.nodeServer}/admin/thresholds`,
      true
    );
    createMinerSetting.setRequestHeader('Authorization', auth.token);
    createMinerSetting.setRequestHeader('Content-Type', 'application/json');
    createMinerSetting.send(JSON.stringify(miner_setting));
  });

export const updateMinerSettingRequest = (miner_setting, auth) => () =>
  new Promise((resolve, reject) => {
    const updateMinerSetting = new XMLHttpRequest();
    updateMinerSetting.onreadystatechange = () => {
      if (updateMinerSetting.readyState === 4) {
        if (updateMinerSetting.status === 200) {
          resolve(updateMinerSetting.response);
          swal({
            title: 'Successfully updated miner setting',
          });
        } else if (updateMinerSetting.status >= 500) {
          error500();
          reject('Server error');
        } else if (updateMinerSetting.status >= 400) {
          reject('Session expired - please login');
        }
      }
    };
    updateMinerSetting.onerror = () => {
      reject('Server error');
    };
    updateMinerSetting.ontimeout = () => {
      reject('Connection timeout');
    };
    updateMinerSetting.open(
      'put',
      `${config.urls.nodeServer}/admin/thresholds`,
      true
    );
    updateMinerSetting.setRequestHeader('Authorization', auth.token);
    updateMinerSetting.setRequestHeader('Content-Type', 'application/json');
    updateMinerSetting.send(JSON.stringify(miner_setting));
  });

export const createAccountRequest = (user, auth) => () =>
  new Promise((resolve, reject) => {
    const createAccount = new XMLHttpRequest();
    createAccount.onreadystatechange = () => {
      if (createAccount.readyState === 4) {
        if (createAccount.status === 200) {
          resolve(createAccount.response);
          swal({
            title: 'Successfully created new account',
          });
        } else if (createAccount.status >= 500) {
          error500();
          reject('Server error');
        } else if (createAccount.status >= 400) {
          const response = JSON.parse(createAccount.response);
          if (response) {
            swal({
              title: response.message,
            });
          } else {
            swal({
              title: 'Error creating new account',
            });
          }
          reject('Session expired - please login');
        }
      }
    };
    createAccount.onerror = () => {
      reject('Server error');
    };
    createAccount.ontimeout = () => {
      reject('Connection timeout');
    };
    createAccount.open('post', `${config.urls.nodeServer}/admin/users`, true);
    createAccount.setRequestHeader('Authorization', auth.token);
    createAccount.setRequestHeader('Content-Type', 'application/json');
    createAccount.send(JSON.stringify(user));
  });

export const createCustomerRequest = (customer, auth) => () =>
  new Promise((resolve, reject) => {
    const createCustomer = new XMLHttpRequest();
    createCustomer.onreadystatechange = () => {
      if (createCustomer.readyState === 4) {
        if (createCustomer.status === 200) {
          resolve(createCustomer.response);
          swal({
            title: 'Successfully created new customer',
            onClose: async () => {
              window.location.reload(false);
            },
          });
        } else if (createCustomer.status >= 500) {
          error500();
          reject('Server error');
        } else if (createCustomer.status >= 400) {
          const response = JSON.parse(createCustomer.response);
          if (response) {
            swal({
              title: response.message,
            });
          } else {
            swal({
              title: 'Error creating new customer',
            });
          }
          reject('Session expired - please login');
        }
      }
    };
    createCustomer.onerror = () => {
      reject('Server error');
    };
    createCustomer.ontimeout = () => {
      reject('Connection timeout');
    };
    createCustomer.open(
      'post',
      `${config.urls.nodeServer}/admin/customers`,
      true
    );
    createCustomer.setRequestHeader('Authorization', auth.token);
    createCustomer.setRequestHeader('Content-Type', 'application/json');
    createCustomer.send(JSON.stringify(customer));
  });

export const createPoolRequest = (pool, auth) => () =>
  new Promise((resolve, reject) => {
    const createPool = new XMLHttpRequest();
    createPool.onreadystatechange = () => {
      if (createPool.readyState === 4) {
        if (createPool.status === 200) {
          resolve(createPool.response);
          swal({
            title: 'Successfully created new pool',
            onClose: async () => {
              window.location.reload(false);
            },
          });
        } else if (createPool.status >= 500) {
          error500();
          reject('Server error');
        } else if (createPool.status >= 400) {
          const response = JSON.parse(createPool.response);
          if (response) {
            swal({
              title: response.message,
            });
          } else {
            swal({
              title: 'Error creating new pool',
            });
          }
          reject('Session expired - please login');
        }
      }
    };
    createPool.onerror = () => {
      reject('Server error');
    };
    createPool.ontimeout = () => {
      reject('Connection timeout');
    };
    createPool.open('post', `${config.urls.nodeServer}/admin/pools`, true);
    createPool.setRequestHeader('Authorization', auth.token);
    createPool.setRequestHeader('Content-Type', 'application/json');
    createPool.send(JSON.stringify(pool));
  });

export const updatePoolRequest = (pool, auth) => () =>
  new Promise((resolve, reject) => {
    const updatePool = new XMLHttpRequest(); // TODO: use a proper library for AJAX call. -JJ
    updatePool.timeout = TIMEOUT_THRESHOLD;
    updatePool.onreadystatechange = () => {
      if (updatePool.readyState === 4) {
        if (updatePool.status === 200) {
          resolve(updatePool.response);
          swal({
            title: 'Successfully updated pool',
            onClose: async () => {
              window.location.reload(false);
            },
          });
        } else if (updatePool.status >= 500) {
          error500();
          reject('Server error');
        } else if (updatePool.status >= 400) {
          reject('Session expired - please login');
        }
      }
    };
    updatePool.onerror = () => {
      reject('Server error');
    };
    updatePool.ontimeout = () => {
      reject('Connection timeout');
    };
    updatePool.open('put', `${config.urls.nodeServer}/admin/pools`, true);
    updatePool.setRequestHeader('Authorization', auth.token);
    updatePool.setRequestHeader('Content-Type', 'application/json');
    updatePool.send(JSON.stringify(pool));
  });

export const updateAccountRequest = (user, auth) => () =>
  new Promise((resolve, reject) => {
    const updateAccount = new XMLHttpRequest();
    updateAccount.onreadystatechange = () => {
      if (updateAccount.readyState === 4) {
        if (updateAccount.status === 200) {
          resolve(updateAccount.response);
          swal({
            title: 'Successfully updated account',
          });
        } else if (updateAccount.status >= 500) {
          error500();
          reject('Server error');
        } else if (updateAccount.status >= 400) {
          const response = JSON.parse(updateAccount.response);
          if (response) {
            swal({
              title: response.message,
            });
          } else {
            swal({
              title: 'Error updating account',
            });
          }
          reject('Session expired - please login');
        }
      }
    };
    updateAccount.onerror = () => {
      reject('Server error');
    };
    updateAccount.ontimeout = () => {
      reject('Connection timeout');
    };
    updateAccount.open('put', `${config.urls.nodeServer}/admin/users`, true);
    updateAccount.setRequestHeader('Authorization', auth.token);
    updateAccount.setRequestHeader('Content-Type', 'application/json');
    updateAccount.send(JSON.stringify(user));
  });

export const updateCustomerRequest = (customer, auth) => () =>
  new Promise((resolve, reject) => {
    const updateCustomer = new XMLHttpRequest();
    updateCustomer.onreadystatechange = () => {
      if (updateCustomer.readyState === 4) {
        if (updateCustomer.status === 200) {
          resolve(updateCustomer.response);
          swal({
            title: 'Successfully updated customer',
            onClose: async () => {
              window.location.reload(false);
            },
          });
        } else if (updateCustomer.status >= 500) {
          error500();
          reject('Server error');
        } else if (updateCustomer.status >= 400) {
          reject('Session expired - please login');
        }
      }
    };
    updateCustomer.onerror = () => {
      reject('Server error');
    };
    updateCustomer.ontimeout = () => {
      reject('Connection timeout');
    };
    updateCustomer.open(
      'put',
      `${config.urls.nodeServer}/admin/customers`,
      true
    );
    updateCustomer.setRequestHeader('Authorization', auth.token);
    updateCustomer.setRequestHeader('Content-Type', 'application/json');
    updateCustomer.send(JSON.stringify(customer));
  });

export const getAccountsRequest = (token) => (dispatch) =>
  new Promise((_resolve, reject) => {
    const config_headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(`${config.urls.nodeServer}/admin/users`, config_headers)
      .then((res) => {
        const json_response = res.data;
        dispatch({
          payload: json_response,
          type: GET_USERS,
        });
      })
      .catch((err) => {
        reject(err.message);
      });
  });

export const deleteAccountsRequest = (token, users) => (dispatch) =>
  new Promise((_resolve, reject) => {
    const config_headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .delete(`${config.urls.nodeServer}/admin/users`, users, config_headers)
      .then((res) => {
        const json_response = res.data;
        console.log(json_response);
        dispatch({
          payload: json_response,
          type: GET_USERS,
        });
      })
      .catch((err) => {
        reject(err.message);
      });
  });

export const updateMinerPayoutRequest = (miner_payout, auth) => () =>
  new Promise((resolve, reject) => {
    const updateMinerPayout = new XMLHttpRequest();
    updateMinerPayout.onreadystatechange = () => {
      if (updateMinerPayout.readyState === 4) {
        if (updateMinerPayout.status === 200) {
          resolve(updateMinerPayout.response);
          swal({
            title: 'Successfully updated miner payout',
          });
        } else if (updateMinerPayout.status >= 500) {
          error500();
          reject('Server error');
        } else if (updateMinerPayout.status >= 400) {
          reject('Session expired - please login');
        }
      }
    };
    updateMinerPayout.onerror = () => {
      reject('Server error');
    };
    updateMinerPayout.ontimeout = () => {
      reject('Connection timeout');
    };
    updateMinerPayout.open(
      'put',
      `${config.urls.nodeServer}/admin/payout_thresholds`,
      true
    );
    updateMinerPayout.setRequestHeader('Authorization', auth.token);
    updateMinerPayout.setRequestHeader('Content-Type', 'application/json');
    updateMinerPayout.send(JSON.stringify(miner_payout));
  });

export const tokenLoginRequest = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    const authenticate = new XMLHttpRequest();
    authenticate.onreadystatechange = () => {
      if (authenticate.readyState === 4) {
        if (authenticate.status === 200) {
          dispatch({
            payload: JSON.parse(authenticate.response),
            type: LOGIN,
          });
          getRulesFeaturesRequest(token)(dispatch);
          resolve(authenticate.response);
        } else if (authenticate.status >= 500) {
          history.push('/');
          reject('Server error');
        } else if (authenticate.status >= 400) {
          dispatch({
            payload: null,
            type: LOGIN,
          });
          history.push('/');
          reject('Session expired - please login');
        }
      }
    };
    authenticate.onerror = () => {
      reject('Server error');
    };
    authenticate.ontimeout = () => {
      reject('Connection timeout');
    };
    authenticate.open('post', `${config.urls.nodeServer}/auth/token`, true);
    authenticate.setRequestHeader('Authorization', token);
    authenticate.setRequestHeader('Content-Type', 'application/json');
    authenticate.send();
  });

export const logOut = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      payload: null,
      type: LOGIN,
    });
    history.push('/login');
    resolve();
  });

export const minerStatusRequest = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    const minerStatus = new XMLHttpRequest();
    minerStatus.onreadystatechange = () => {
      if (minerStatus.readyState === 4) {
        if (minerStatus.status === 200) {
          const miner_status_res = JSON.parse(minerStatus.response);
          dispatch({
            payload: miner_status_res,
            type: SET_MINER_STATUS,
          });
          resolve(miner_status_res);
        } else if (minerStatus.status >= 500) {
          error500();
          reject('Server error');
        } else if (minerStatus.status >= 400) {
          history.push('/login');
          reject('Session expired - please login');
        }
      }
    };
    minerStatus.onerror = () => {
      reject('Server error');
    };
    minerStatus.ontimeout = () => {
      reject('Connection timeout');
    };
    minerStatus.open(
      'get',
      `${config.urls.nodeServer}/user/miners/status`,
      true
    );
    minerStatus.setRequestHeader('Authorization', token);
    minerStatus.send();
  });

export const deleteWorkers = (auth, miners, product) =>
  new Promise((resolve) => {
    const deleteWorkersRequest = new XMLHttpRequest();
    deleteWorkersRequest.onreadystatechange = () => {
      if (deleteWorkersRequest.readyState === 4) {
        if (deleteWorkersRequest.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    };
    deleteWorkersRequest.onerror = () => {
      resolve(false);
    };
    deleteWorkersRequest.ontimeout = () => {
      resolve(false);
    };
    deleteWorkersRequest.open(
      'delete',
      `${config.urls.nodeServer}/admin/miners/${product}`,
      true
    );
    deleteWorkersRequest.setRequestHeader('Authorization', auth.token);
    deleteWorkersRequest.setRequestHeader('Content-Type', 'application/json');
    deleteWorkersRequest.send(JSON.stringify({ miners }));
  });

export const performanceDataRequest = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    const performanceData = new XMLHttpRequest();
    performanceData.onreadystatechange = () => {
      if (performanceData.readyState === 4) {
        if (performanceData.status === 200) {
          const performance_res = JSON.parse(performanceData.response);
          dispatch({
            payload: performance_res,
            type: SET_PERFORMANCE_DATA,
          });
          resolve(performance_res);
        } else if (performanceData.status >= 500) {
          error500();
          reject('Server error');
        } else if (performanceData.status >= 400) {
          history.push('/login');
          reject('Session expired - please login');
        }
      }
    };
    performanceData.onerror = () => {
      reject('Server error');
    };
    performanceData.ontimeout = () => {
      reject('Connection timeout');
    };
    performanceData.open(
      'get',
      `${config.urls.nodeServer}/user/performance`,
      true
    );
    performanceData.setRequestHeader('Authorization', token);
    performanceData.send();
  });

export const minerWithIdRequest = (token, miner_id) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.urls.nodeServer}/user/miners/${miner_id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const json_response = res.data;
        dispatch({
          payload: json_response,
          type: SET_MINER_STATUS,
        });
        resolve(json_response);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status && err.response.status === 403) {
            history.push('/login');
            reject('Session Expired - please login');
          } else if (err.response.status && err.response.status === 404) {
            history.push('/mineManagement/miners');
            reject('Miner not found');
            window.location.reload(true);
          } else {
            history.push('/login');
            reject('Server error');
          }
        } else {
          reject('Server error');
        }
      });
  });

export const getRulesFeaturesRequest = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    const rulesFeatures = new XMLHttpRequest();
    rulesFeatures.onreadystatechange = () => {
      if (rulesFeatures.readyState === 4) {
        if (rulesFeatures.status === 200) {
          dispatch({
            payload: JSON.parse(rulesFeatures.response),
            type: SET_RULES_FEATURES,
          });
          resolve(rulesFeatures.response);
        } else if (rulesFeatures.status >= 500) {
          error500();
          reject('Server error');
        } else if (rulesFeatures.status >= 400) {
          history.push('/login');
          reject('Session expired - please login');
        }
      }
    };
    rulesFeatures.onerror = () => {
      reject('Server error');
    };
    rulesFeatures.ontimeout = () => {
      reject('Connection timeout');
    };
    rulesFeatures.open('get', `${config.urls.nodeServer}/user/features`, true);
    rulesFeatures.setRequestHeader('Authorization', token);
    rulesFeatures.send();
  });

export const getCustomers = (auth) => (dispatch) =>
  new Promise((resolve, reject) => {
    const customers = new XMLHttpRequest();
    customers.onreadystatechange = () => {
      if (customers.readyState === 4) {
        if (customers.status === 200) {
          dispatch({
            payload: JSON.parse(customers.response),
            type: SET_CUSTOMERS,
          });
          resolve(customers.response);
        } else if (customers.status >= 500) {
          error500();
          reject('Server error');
        } else if (customers.status >= 400) {
          history.push('/login');
          reject('Session expired - please login');
        }
      }
    };
    customers.onerror = () => {
      reject('Server error');
    };
    customers.ontimeout = () => {
      reject('Connection timeout');
    };
    customers.open('get', `${config.urls.nodeServer}/user/customers`, true);
    customers.setRequestHeader('Authorization', auth.token);
    customers.send();
  });

export const getPools = (auth) => (dispatch) =>
  new Promise((resolve, reject) => {
    const pools = new XMLHttpRequest();
    pools.onreadystatechange = () => {
      if (pools.readyState === 4) {
        if (pools.status === 200) {
          dispatch({
            payload: JSON.parse(pools.response),
            type: SET_POOLS,
          });
          resolve(JSON.parse(pools.response));
        } else if (pools.status >= 500) {
          error500();
          reject('Server error');
        } else if (pools.status >= 400) {
          history.push('/login');
          reject('Session expired - please login');
        }
      }
    };
    pools.onerror = () => {
      reject('Server error');
    };
    pools.ontimeout = () => {
      reject('Connection timeout');
    };
    pools.open('get', `${config.urls.nodeServer}/user/pools`, true);
    pools.setRequestHeader('Authorization', auth.token);
    pools.send();
  });

export const getCustomerPools = (auth) => (dispatch) =>
  new Promise((resolve, reject) => {
    const customer_pools = new XMLHttpRequest();
    customer_pools.onreadystatechange = () => {
      if (customer_pools.readyState === 4) {
        if (customer_pools.status === 200) {
          dispatch({
            payload: JSON.parse(customer_pools.response),
            type: SET_CUSTOMER_POOLS,
          });
          resolve(customer_pools.response);
        } else if (customer_pools.status >= 500) {
          error500();
          reject('Server error');
        } else if (customer_pools.status >= 400) {
          history.push('/login');
          reject('Session expired - please login');
        }
      }
    };
    customer_pools.onerror = () => {
      reject('Server error');
    };
    customer_pools.ontimeout = () => {
      reject('Connection timeout');
    };
    customer_pools.open(
      'get',
      `${config.urls.nodeServer}/user/pools/:customer`,
      true
    );
    customer_pools.setRequestHeader('Authorization', auth.token);
    customer_pools.send();
  });

export const getUrls = (auth) => (dispatch) =>
  new Promise((resolve, reject) => {
    const urls = new XMLHttpRequest();
    urls.onreadystatechange = () => {
      if (urls.readyState === 4) {
        if (urls.status === 200) {
          dispatch({
            payload: JSON.parse(urls.response),
            type: SET_URLS,
          });
          resolve(urls.response);
        } else if (urls.status >= 500) {
          error500();
          reject('Server error');
        } else if (urls.status >= 400) {
          reject('Session expired - please login');
        }
      }
    };
    urls.onerror = () => {
      reject('Server error');
    };
    urls.ontimeout = () => {
      reject('Connection timeout');
    };
    urls.open(
      'get',
      `${config.urls.nodeServer}/admin/urls/${auth.user_id}`,
      true
    );
    urls.setRequestHeader('Authorization', auth.token);
    urls.send();
  });

export const resetPasswordRequest = (email) => {
  axios
    .post(
      `${config.urls.nodeServer}/auth/forgotPassword/`,
      { email },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(() => {
      swal({
        title: 'Success',
        text: 'An email with a password reset link will be sent to your email if your account exists',
      });
    })
    .catch((err) => {
      console.log(err);
      swal({
        title: 'Error resetting password',
      });
    });
};

export const getWorkerHashrates =
  (token, pools = null, customers = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const requestConfig = {
        headers: {
          Authorization: token,
        },
        params: {
          pools,
          customers,
        },
      };
      axios
        .get(`${config.urls.nodeServer}/user/worker_hashrates`, requestConfig)
        .then((response) => {
          dispatch({
            payload: response.data.all_pools,
            type: GET_WORKERS_HASHRATE_STATS,
          });
          resolve(response.data.all_pools);
        })
        .catch((err) => {
          reject(err.message);
        });
    });

export const getPoolHashrates = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.urls.nodeServer}/user/hashrates`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          payload: response.data.all_pools,
          type: GET_POOL_HASHRATE_STATS,
        });
        resolve(response.data.all_pools);
      })
      .catch((err) => {
        reject(err.message);
      });
  });

export const getPoolRewards =
  (token, poolName = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const requestBody = {};
      if (poolName) {
        requestBody.poolName = poolName;
      }
      axios
        .post(`${config.urls.nodeServer}/user/rewards`, requestBody, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch({
            payload: response.data.poolsRewards,
            type: GET_POOL_REWARDS_STATS,
          });
          resolve(response.data.poolsRewards);
        })
        .catch((err) => {
          reject(err.message);
        });
    });

export const getPoolNames = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.urls.nodeServer}/user/user_pool_names`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          payload: response.data,
          type: GET_POOL_NAMES,
        });
        resolve(response.data.poolsRewards);
      })
      .catch((err) => {
        reject(err.message);
      });
  });

export const getBlockseerWorkerStatus =
  (token, customers = null, pools = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const requestConfig = {
        headers: {
          Authorization: token,
        },
        params: {
          pools,
          customers,
        },
      };
      axios
        .get(`${config.urls.nodeServer}/user/workers/status`, requestConfig)
        .then((response) => {
          dispatch({
            payload: response.data,
            type: GET_POOL_STATUS,
          });
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });

const resetData = (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: null,
  });
  dispatch({
    type: SET_MINER_STATUS,
    payload: null,
  });
  dispatch({
    type: SET_RULES_FEATURES,
    payload: null,
  });
  dispatch({
    type: SET_CUSTOMERS,
    payload: null,
  });
  dispatch({
    type: SET_URLS,
    payload: null,
  });
  dispatch({
    type: SET_CUSTOMER_POOLS,
    payload: null,
  });
  dispatch({
    type: SET_PERFORMANCE_DATA,
    payload: null,
  });
  dispatch({
    type: GET_WORKERS_HASHRATE_STATS,
    payload: null,
  });
  dispatch({
    type: GET_POOL_HASHRATE_STATS,
    payload: null,
  });
  dispatch({
    type: GET_POOL_REWARDS_STATS,
    payload: null,
  });
  dispatch({
    type: GET_POOL_STATUS,
    payload: null,
  });
};

export const logoutRequest = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  resetData(dispatch);
  history.push('/login');
};

export const lockedAccountRequest = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  resetData(dispatch);
  history.push('/locked');
};

export const getStrapiContent =
  (firstLoad = false) =>
  async (dispatch) => {
    const customer = process.env.REACT_APP_CUSTOMER
      ? process.env.REACT_APP_CUSTOMER.toUpperCase()
      : 'DMG';
    const contentKey = `strapi_content_${indexOf(
      Object.keys(STRAPI_CUSTOMERS),
      customer
    )}`;

    if (!firstLoad && localStorage.getItem(contentKey)) {
      return dispatch({
        payload: JSON.parse(localStorage.getItem(contentKey)),
        type: GET_STRAPI_CONTENT,
      });
    }
    // Login
    try {
      const customerId =
        STRAPI_CUSTOMERS[customer];

      const loginResult = await axios.post(
        `${config.urls.strapiServer}/auth/local`,
        {
          identifier: process.env.REACT_APP_STRAPI_USERNAME,
          password: process.env.REACT_APP_STRAPI_PASSWORD,
        }
      );

      const contentResult = await axios.get(
        `${config.urls.strapiServer}/customers/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${loginResult.data.jwt}`,
          },
        }
      );

      // add to local storage
      localStorage.setItem(contentKey, JSON.stringify(contentResult.data));

      dispatch({
        payload: contentResult.data,
        type: GET_STRAPI_CONTENT,
      });
    } catch (e) {
      console.log(e);
    }
  };
