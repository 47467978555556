import { GET_STRAPI_CONTENT } from '../actions/types';

const defaultState = {
  cLogo: '',
  cCompanyName: '',
  cFavicon: ''
};

const contentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_STRAPI_CONTENT:
      const { cLogo, cName, cFavicon } = action.payload;
      const newState = Object.assign(state, {
        cLogo: cLogo?.formats?.small?.url || cLogo.url,
        cCompanyName: cName,
        cFavicon: cFavicon?.url
      });
      return newState;
    default:
      return state;
  }
};

export default contentReducer;
