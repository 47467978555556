import React, { Component } from 'react';
import { uniqBy } from 'lodash';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { updateMinerPayoutRequest } from '../../../actions';
import axios from 'axios';
import { CSVLink } from 'react-csv';
const config = require('../../../config');
const Swal = require('sweetalert2');

class MiningPayouts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payoutData: props.miningPayoutData,
      filteredData: null,
      filter: null,
      disabledValue: true,
      showMenu: false,
      page: 0,
      pageSize: 10,
      reportData: [],
    };
    this.csvLink = React.createRef();
  }

  displayOptions = (category) => {
    const data = this.state.payoutData;
    let arr = data.map((val) => {
      return val[`${category}`];
    });
    const uniqueArr = uniqBy(arr, (e) => {
      return e;
    });
    const optionsList = uniqueArr.map((val, i) => {
      return { id: i, value: val, label: val };
    });

    return optionsList;
  };

  getMinerPayoutsReport = () => {
    Swal({
      title: 'Retrieving report data..',
    });
    Swal.showLoading();
    const token = window.localStorage.getItem('token');
    axios
      .get(`${config.urls.nodeServer}/admin/payoutsReport/`, {
        headers: {
          Authorization: token,
        },
      })
      .then(async (res) => {
        this.setState({ reportData: res.data }, () => {
          this.csvLink.current.link.click();
          Swal.close();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSearchChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  showMenu = (row) => {
    this.setState({ showMenu: row }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  handleOpenModal = (modal) => {
    this.setState({ displayMinerPayoutModal: modal });
  };

  handleCloseMinerPayoutModal = () => {
    this.setState({ displayMinerPayoutModal: false });
  };

  closeMenu = () => {
    this.setState({ showSubMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  render() {
    let data = this.state.filteredData
      ? this.state.filteredData
      : this.state.payoutData;
    const lowercasedFilter = this.state.filter || null;
    const filteredSearchData = data.filter((item) => {
      return Object.keys(item).some((key) => {
        if (
          item !== null &&
          key !== null &&
          item[key] !== null &&
          item[key].toString().includes(lowercasedFilter)
        ) {
          return item[key];
        } else return null;
      });
    });
    if (filteredSearchData.length > 0) {
      data = filteredSearchData;
    }
    let columns = [
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" alt="" src="/tableSortDefault.png"></img>Miner
              Group
            </div>
          );
        },
        accessor: 'pool_name',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" alt="" src="/tableSortDefault.png"></img>Payout
              Threshold (BTC)
            </div>
          );
        },
        accessor: 'payout_threshold',
        minWidth: 113,
        Cell: (props) =>
          props.value ? +parseFloat(props.value).toFixed(2) + ' BTC' : '', // 2 decimals only when necessary: 0.01, 0.1, 1
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" alt="" src="/tableSortDefault.png"></img>Wallet
            </div>
          );
        },
        accessor: 'payout_address',
        minWidth: 113,
      },
    ];
    return (
      <div>
        <div className="filterContainer">
          <div className="searchInputContainer">
            <form className="inputBoxForm">
              <input
                className="inputBox"
                type="text"
                name="search"
                placeholder="Search for pool(s)"
                onChange={this.handleSearchChange}
              ></input>
            </form>
            <button
              className="filterAddBtn"
              onClick={this.getMinerPayoutsReport}
            >
              Payout Report
            </button>
            <CSVLink
              data={this.state.reportData}
              filename={`payout_report.csv`}
              target="_blank"
              ref={this.csvLink}
            />
          </div>
        </div>
        <div className="tableContainer">
          <ReactTable
            bordered={false}
            data={data}
            columns={columns}
            defaultPageSize={10}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, rules }) => {
  return {
    auth,
    rules,
  };
};

export default connect(mapStateToProps, { updateMinerPayoutRequest })(
  MiningPayouts
);
