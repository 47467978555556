import React, { Component } from 'react';
import './Report.scss';
import { connect } from 'react-redux';
import { getRulesFeaturesRequest, getCustomerPools } from '../../actions';
import BlockseerPoolGraph from '../Graphs/BlockseerPoolGraph';
import ReportFilter from './ReportFilter';
import PoolWorkerGraph from '../Graphs/PoolWorkerGraph';
import axios from 'axios';
const config = require('../../config');

class Report extends Component {
  constructor() {
    super();
    this.state = {
      selectedPool: [],
      workersHashrateFilteredData: null,
      minDateReport: null,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    const saved_token = window.localStorage.getItem('token');
    await this.getMinDateReport(saved_token);
  };

  componentDidUpdate = async (prevProps) => {
    const saved_token = window.localStorage.getItem('token');
    if (this.props.minersData !== prevProps.minersData) {
      await this.getMinDateReport(saved_token);
    }
  };

  getMinDateReport = async (token) => {
    axios
      .get(`${config.urls.nodeServer}/user/minDate/`, {
        headers: {
          Authorization: token,
        },
      })
      .then(async (res) => {
        const minDateReport = res.data;
        this.setState({ minDateReport });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (selectedPool) => {
    this.setState({ selectedPool: [...selectedPool] });
  };

  render() {
    return (
      <div className="chartsSection">
        <div className="filteredChartsSection">
          <ReportFilter
            handleChange={this.handleChange}
            workers_hashrate={this.props.workers_hashrate}
            data={null}
          />
          <div className="filteredCharts" id="reportCharts"></div>
          <div style={{ margin: '100' }}>
            <PoolWorkerGraph
              minDateReport={this.state.minDateReport}
              selectedPool={this.state.selectedPool}
            />
          </div>
          <div style={{ margin: '100' }}>
            <BlockseerPoolGraph minDateReport={this.state.minDateReport} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, customer_pools, rules, workers_hashrate }) => {
  return {
    auth,
    customer_pools,
    rules,
    workers_hashrate,
  };
};

export default connect(mapStateToProps, {
  getCustomerPools,
  getRulesFeaturesRequest,
})(Report);
