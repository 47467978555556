import QRCode from 'qrcode';
import jsPDF from 'jspdf';
const config = require('../../../config');

const generateQR = async (ids) => {
  const pdf = new jsPDF('l', 'mm', [58, 50]);
  let started = true;
  document.body.innerHTML += '<canvas id="canvas"></canvas>';
  let canvas = document.getElementById('canvas');
  const width = pdf.internal.pageSize.getWidth();
  const height = pdf.internal.pageSize.getHeight();
  ids.forEach(function (miner) {
    const url = `${config.urls.frontEnd}/mineManagement/miners?id=` + miner;
    QRCode.toCanvas(document.getElementById('canvas'), url, function (error) {
      if (error) console.error(error);
    });
    let imgData = canvas.toDataURL('image/png');
    if (started) {
      pdf.addImage(imgData, 'PNG', 0, 0, width, height);
      started = false;
    } else {
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, 0, width, height);
    }
  });
  canvas.remove();
  pdf.save('QRCodes.pdf');
};

export default generateQR;
