import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import AddOrEditPoolModal from './AddOrEditPoolModal';
import './PoolManagement.scss';
import EditPoolSubMenu from './EditPoolSubMenu';
import CanDisplay from '../../CanDisplay';
import DeletePoolModal from './DeletePoolModal';

class PoolManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      showMenu: false,
      showSubMenu: false,
      page: 0,
      pageSize: 10,
    };
  }

  showMenu = (row) => {
    this.setState({ showMenu: row }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = (event) => {
    this.setState({ showSubMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  handleOpenModal = (modal) => {
    this.setState({ displayPoolModal: modal });
  };

  handleClosePoolModal = () => {
    this.setState({ displayPoolModal: false });
  };

  handleSearchChange = (event) => {
    this.setState({
      filter: event.target.value === '' ? null : event.target.value,
    });
  };

  render() {
    let data = this.props.pools;
    if (this.state.filter !== null) {
      const filteredSearchData = data.filter((item) =>
        Object.keys(item).some((key) => {
          if (
            item !== null &&
            key !== null &&
            item[key] !== null &&
            item[key].toString().includes(this.state.filter)
          ) {
            return item[key];
          }
          return null;
        })
      );
      data = filteredSearchData;
    }
    const columns = [
      {
        Header: () => (
          <div>
            <img id="sortIcon" src="/tableSortDefault.png" alt="" />
            Miner Group
          </div>
        ),
        accessor: 'pool_name',
        minWidth: 113,
      },
      {
        Header: () => (
          <div>
            <img id="sortIcon" src="/tableSortDefault.png" alt="" />
            External URL
          </div>
        ),
        accessor: 'url',
        minWidth: 113,
      },
      {
        Header: () => (
          <div>
            <img id="sortIcon" src="/tableSortDefault.png" alt="" />
            Payout Address
          </div>
        ),
        accessor: 'payout_address',
        minWidth: 113,
      },
      {
        Header: () => (
          <div>
            <img id="sortIcon" src="/tableSortDefault.png" alt="" />
            Payout Threshold (BTC)
          </div>
        ),
        accessor: 'payout_threshold',
        minWidth: 113,
        Cell: (props) =>
          props.value ? `${+parseFloat(props.value).toFixed(2)} BTC` : '', // 2 decimals only when necessary: 0.01, 0.1, 1
      },
      {
        Header: () => (
          <div>
            <img id="sortIcon" src="/tableSortDefault.png" alt="" />
            Pool Fee
          </div>
        ),
        accessor: 'payout_fee',
        minWidth: 113,
        Cell: (props) => (props.value ? `${props.value}%` : ''),
      },
      {
        Header: () => (
          <div>
            <img id="sortIcon" src="/tableSortDefault.png" alt="" />
            Purge After Hours
          </div>
        ),
        accessor: 'purge_after_hours',
        minWidth: 113,
        Cell: (props) => (props.value ? `${props.value}h` : ''),
      },
      {
        id: 'action',
        accessor: '',
        Cell: (row) => (
          <div>
            <img
              src="/tableHamburger.png"
              id="actionRowBtn"
              alt=""
              onClick={() => {
                this.showMenu(row);
                this.setState({ showSubMenu: true });
              }}
            />
          </div>
        ),
        Header: '',
        width: 45,
      },
    ];
    return (
      <div>
        <div className="filterContainer">
          <div className="searchInputContainer">
            <form className="inputBoxForm">
              <input
                className="inputBox"
                type="text"
                name="search"
                placeholder="Search for pool(s)"
                onChange={this.handleSearchChange}
              />
            </form>
            <CanDisplay
              rules={this.props.rules}
              perform="add_pools"
              yes={() => (
                <button
                  className="filterAddBtn"
                  onClick={() => {
                    this.handleOpenModal('pool');
                  }}
                >
                  <span>Add Miner Group</span>
                </button>
              )}
            />
          </div>
          <AddOrEditPoolModal
            modalType="add"
            isOpen={this.state.displayPoolModal === 'pool'}
            handleCloseModal={this.handleClosePoolModal}
          />
        </div>
        <div className="tableContainer">
          <ReactTable
            bordered={false}
            data={this.props.pools}
            columns={columns}
            defaultPageSize={10}
            pageSize={this.state.pageSize}
            onPageChange={(page) => this.setState({ page })}
            onPageSizeChange={(pageSize, page) =>
              this.setState({ page, pageSize })
            }
          />
          {this.state.showSubMenu && (
            <EditPoolSubMenu
              poolData={this.state.showMenu}
              closeMenu={this.closeMenu}
              openEditPoolModal={() => this.handleOpenModal('edit_row')}
              openDeletePoolModal={() => this.handleOpenModal('delete_row')}
              tablePage={this.state.page}
              tablePageSize={this.state.pageSize}
            />
          )}
          {this.state.showMenu.original && (
            <AddOrEditPoolModal
              modalType="edit"
              isOpen={this.state.displayPoolModal === 'edit_row'}
              handleCloseModal={this.handleClosePoolModal}
              data={this.state.showMenu.original}
            />
          )}
        </div>
        {this.state.displayPoolModal === 'delete_row' && (
          <DeletePoolModal
            show={this.state.displayPoolModal === 'delete_row'}
            onHide={this.handleClosePoolModal}
            data={this.state.showMenu.original}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, rules }) => ({
  auth,
  rules,
});

export default connect(mapStateToProps, {})(PoolManagement);
