import React, { Component } from 'react';
import './Profile.scss';
import EditProfile from './EditProfile';
import ViewProfile from './ViewProfile';
import { Modal } from 'react-bootstrap';

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      edit: false,
      confirmSave: false,
    };
  }

  render() {
    const { auth, onHide } = this.props;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {this.state.edit ? (
          <EditProfile
            auth={this.props.auth}
            handleClose={onHide}
            cancelHandler={() => this.setState({ edit: false })}
          />
        ) : (
          <ViewProfile
            auth={auth}
            handleClose={onHide}
            editClick={() => {
              this.setState({ edit: true });
            }}
          />
        )}
      </Modal>
    );
  }
}

export default Profile;
