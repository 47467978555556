import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

export default class LockedAccount extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center', marginTop: '15%' }}>
        <FontAwesomeIcon
          icon={faLock}
          style={{ fontSize: '10em', marginBottom: '1%' }}
        />
        <h1>Your account is locked.</h1>
        <p>
          Due to security concerns, we have temporarily locked your account.
          Please contact us{' '}
          <a
            href="https://dmgblockchain.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{' '}
          to resolve this.
        </p>
      </div>
    );
  }
}
