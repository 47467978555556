import React, { Component } from 'react';
import Helmet from 'react-helmet';
import './ResetPassword.scss';
import { connect } from 'react-redux';
import { getStrapiContent } from '../../actions';
import { Form, Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

const config = require('../../config');

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'At least 8 Characters, One Uppercase, One Lowercase, and One Number'
    )
    .required('* Required field'),
  rePassword: Yup.string()
    .required('* Required field')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.newPassword === value;
    }),
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      content: {},
    };
    this.token = this.props.match.params.token;
  }

  componentDidMount = async () => {
    await this.props.getStrapiContent(true);
    this.setState({
      content: this.props.content,
    });
  };

  resetPasswordRequest = async (values) => {
    await axios
      .post(
        config.urls.nodeServer + '/reset_password',
        { newPassword: values.newPassword },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        }
      )
      .then((res) => {
        swal({
          title: res.data.msg,
          onClose: () => this.props.history.push('/login'),
        });
      })
      .catch((err) => {
        swal({
          title: 'Error updating user password',
          onClose: () => this.props.history.push('/'),
        });
      });
  };

  render() {
    const { content } = this.state;
    return (
      <div>
        <Helmet>
          <title>{content.cCompanyName}</title>
          <link rel="icon" type="image/png" sizes="16x16" href={content.cFavicon} />
        </Helmet>
        <div id="reset-password">
          <div id="input-fields">
            <div>
              <img
                src={content.cLogo}
                alt="Logo-full-cropped"
                className="logoFullRestPassword"
              />
            </div>
            <Formik
              initialValues={{ newPassword: '', rePassword: '' }}
              validationSchema={validationSchema}
              //destructing "actions" module on the fly
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await this.resetPasswordRequest(values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {({
                values,
                handleChange,
                errors,
                isValid,
                handleSubmit,
                touched,
                handleBlur,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="newPassword">
                    <Form.Label>New password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="password"
                      values={values.newPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.newPassword && errors.newPassword ? (
                      <div className="reset-password-error-message">
                        {errors.newPassword}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="rePassword">
                    <Form.Label>Re-enter new password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="password"
                      values={values.rePassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.rePassword && errors.rePassword ? (
                      <div className="reset-password-error-message">
                        {errors.rePassword}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Button
                    variant="outline-primary"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
          <div id="label">
            <label>Incompatible with Internet Explorer/Microsoft Edge</label>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ content }) => {
  return {
    content,
  };
};

export default connect(mapStateToProps, { getStrapiContent })(ResetPassword);
