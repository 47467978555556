import React, { Component } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './SideNavBar.scss';
import { getRulesFeaturesRequest } from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import adminDefault from '../../assets/adminDefault.png';
import adminSelected from '../../assets/adminSelected.png';
import dashboardDefault from '../../assets/dashboardDefault.png';
import dashboardSelected from '../../assets/dashboardSelected.png';
import mapDefault from '../../assets/mapDefault.png';
import mapSelected from '../../assets/mapSelected.png';
import workerDefault from '../../assets/workerDefault.png';
import workerSelected from '../../assets/workerSelected.png';
import reportDefault from '../../assets/reportDefault.png';
import reportSelected from '../../assets/reportSelected.png';
import navAlertsDefault from '../../assets/Navigation-alerts-default.png';
import navAlertsSelected from '../../assets/Navigation-alerts-selected.png';

class SizeNavBar extends Component {
  render() {
    const switchMapPermission =
      this.props.rules && this.props.rules.features.includes('view_switch_map')
        ? true
        : false;
    const adminPermission =
      this.props.rules && this.props.rules.features.includes('view_admin_page')
        ? true
        : false;
    const pageSelected = this.props.selected;
    const { content } = this.props;
    return (
      <div>
        <SideNav
          className="sideBar"
          expanded={this.props.expanded}
          onSelect={this.props.onSelect}
          onToggle={this.props.onToggle}
        >
          <div style={{ marginBottom: '15px' }}>
            {this.props.expanded ? (
              <img
                src={this.props.content.cLogo}
                alt="Logo-full"
                className="logoFull"
              />
            ) : (
              <img
                src={content.cLogo}
                style={{ width: '76px' }}
                alt="Logo"
                className="logo"
              />
            )}
          </div>
          <SideNav.Toggle />
          <SideNav.Nav selected={this.props.selected}>
            <NavItem eventKey="home">
              <NavIcon>
                <img
                  className="sideBarIcon"
                  alt="home-icon"
                  expanded={this.props.expanded ? 'true' : 'false'}
                  src={
                    pageSelected === 'home'
                      ? dashboardSelected
                      : dashboardDefault
                  }
                />
              </NavIcon>
              <NavText>Dashboard</NavText>
            </NavItem>

            <NavItem eventKey="miners">
              <NavIcon>
                <img
                  className="sideBarIcon"
                  alt="miner-icon"
                  expanded={this.props.expanded ? 'true' : 'false'}
                  src={
                    pageSelected === 'miners' ? workerSelected : workerDefault
                  }
                />
              </NavIcon>
              <NavText>Workers</NavText>
            </NavItem>
            <NavItem eventKey="report">
              <NavIcon>
                <img
                  className="sideBarIcon"
                  alt="report-icon"
                  expanded={this.props.expanded ? 'true' : 'false'}
                  src={
                    pageSelected === 'report' ? reportSelected : reportDefault
                  }
                />
              </NavIcon>
              <NavText>Report</NavText>
            </NavItem>
            <NavItem eventKey="alerts">
              <NavIcon>
                <img
                  className="sideBarIcon"
                  alt="report-icon"
                  expanded={this.props.expanded ? 'true' : 'false'}
                  src={
                    pageSelected === 'alerts'
                      ? navAlertsSelected
                      : navAlertsDefault
                  }
                />
              </NavIcon>
              <NavText>Alerts</NavText>
            </NavItem>
            {switchMapPermission && (
              <NavItem eventKey="switchesMap">
                <NavIcon>
                  <img
                    className="sideBarIcon"
                    alt="map-icon"
                    expanded={this.props.expanded ? 'true' : 'false'}
                    src={
                      pageSelected === 'switchesMap' ? mapSelected : mapDefault
                    }
                  />
                </NavIcon>
                <NavText>Map</NavText>
              </NavItem>
            )}
            {adminPermission && (
              <NavItem eventKey="admin">
                <NavIcon>
                  <img
                    className="sideBarIcon"
                    alt="admin-icon"
                    expanded={this.props.expanded ? 'true' : 'false'}
                    src={
                      pageSelected === 'admin' ? adminSelected : adminDefault
                    }
                  />
                </NavIcon>
                <NavText>Admin</NavText>
              </NavItem>
            )}
          </SideNav.Nav>
        </SideNav>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  miner_status,
  rules,
  customers,
  urls,
  content,
}) => {
  return {
    auth,
    miner_status,
    rules,
    customers,
    urls,
    content,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default withRouter(
  connect(mapStateToProps, { getRulesFeaturesRequest })(SizeNavBar)
);
