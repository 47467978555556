import React, { Component } from 'react';
import './Miners.scss';
import ReactTable from 'react-table';
import ReportModal from '../ReportModal';
import CanDisplay from '../CanDisplay';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

class GeneralTable extends Component {
  constructor(props) {
    super();
    this.state = {
      displayReportModal: props.displayReportModal || false,
      filtered: props.filtered,
      nodes_to_action: props.nodes_to_action,
      data: [],
    };
  }

  componentDidMount() {
    if (this.props.data.length > 1) {
      const enabledMiners = this.props.data.filter((miner) => !miner.disabled);
      this.setState({
        data: enabledMiners,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const enabledMiners = this.props.data.filter((miner) => !miner.disabled);
      this.setState({
        data: enabledMiners,
      });
    }
  }

  render() {
    return (
      <div id="container">
        <div className="filter_container">
          <div
            className="search_input_container"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <form className="inputBoxForm">
              <input
                className="input_box"
                placeholder="Search for worker(s)"
                onChange={this.props.handleSearchChange}
              ></input>
            </form>
            <CanDisplay
              rules={this.props.rules}
              perform="action_on_selected"
              yes={() => (
                <div className="dropdown_container">
                  <button className="btn_action">
                    <span>Action on selected</span>
                  </button>
                  <div className="dropdown-content">
                    <p onClick={() => this.props.action_miner('reboot')}>
                      Reboot
                    </p>
                    {/* <p onClick={() => this.props.action_miner("poll")}>Poll</p>
                            <p onClick={() => this.props.action_miner("disable_mining")}>Disable</p>
                            <p onClick={() => this.props.tagMinerAsOffline()}>Change miner status</p>
                            <p onClick={() => this.props.configure_miners()}>Change pools</p>
                            <p onClick={() => this.props.change_pools()}>Change clock frequency</p>
                            <p onClick={() => this.props.generate_QR_codes()}>Generate QR codes</p> */}
                  </div>
                </div>
              )}
            />
            <button
              className="btn_action"
              onClick={() => {
                this.setState({ displayReportModal: true });
              }}
            >
              <span>Workers with Issues Report</span>
            </button>
            {this.props.nodes_to_action.length > 0 && (
              <button
                className="btn_delete"
                onClick={async () => {
                  const result = await swal({
                    title:
                      this.props.nodes_to_action.length > 1
                        ? `Are you sure you want to delete ${this.props.nodes_to_action.length} workers?`
                        : `Are you sure you want to delete this worker?`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: `Yes, delete`,
                    cancelButtonText: 'No, cancel',
                    reverseButtons: true,
                  });
                  if (result.value) {
                    swal.showLoading();
                    const deletedWorker = await this.props.deleteWorkers(
                      this.props.nodes_to_action,
                      'mm'
                    );
                    swal.close();
                    if (deletedWorker) {
                      swal({
                        title:
                          this.props.nodes_to_action.length > 1
                            ? 'Successfully deleted workers'
                            : 'Successfully deleted worker',
                      });
                    } else {
                      swal({
                        title:
                          this.props.nodes_to_action.length > 1
                            ? 'Failed to delete workers'
                            : 'Failed to delete worker',
                      });
                    }
                  }
                }}
              >
                <span>
                  {this.props.nodes_to_action.length > 1
                    ? 'Delete workers'
                    : 'Delete worker'}
                </span>
              </button>
            )}
            {this.state.displayReportModal &&
              this.state.filtered &&
              this.state.nodes_to_action && (
                <ReportModal
                  showModal={this.state.displayReportModal}
                  hideModal={() => {
                    this.setState({ displayReportModal: false });
                  }}
                  filtered={this.state.filtered}
                  key={this.state.filtered}
                />
              )}
          </div>
          <div className="worker_data_container">
            {`Workers selected:  ${this.props.nodes_to_action.length}`}
          </div>
        </div>
        <div className="table_container">
          <ReactTable
            //If filters are applied, display filtered data. Else display healthy/broken miners depending on toggle.
            bordered={false}
            data={this.state.data}
            columns={this.props.columns}
            defaultPageSize={100}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: this.props.nodes_to_action.includes(
                      rowInfo.row.checkbox.nodename
                    )
                      ? '#1e1e2f'
                      : 'inherit',
                  },
                };
              } else {
                return {};
              }
            }}
            SubComponent={(row) => {
              return (
                <div className="sub_component_container">
                  <div>
                    <strong className="minerDetailTitle">Node name: </strong>
                    <span style={{ paddingLeft: '15px' }}>
                      {row.original.nodename}
                    </span>
                  </div>

                  <div>
                    <strong className="minerDetailTitle">IP:</strong>
                    <span style={{ paddingLeft: '70px' }}>
                      <a
                        href={`http://${row.original.ip}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#80cbc4' }}
                      >
                        {row.original.ip}
                      </a>
                    </span>
                  </div>

                  <div>
                    <strong className="minerDetailTitle">Pool url:</strong>
                    <span style={{ paddingLeft: '40px' }}>
                      {row.original.pool_url}
                    </span>
                  </div>

                  <div>
                    <strong className="minerDetailTitle">Ping delay:</strong>
                    <span style={{ paddingLeft: '25px' }}>
                      {(row.original.ping_delay_time * 1000).toFixed(2)} ms
                    </span>
                  </div>

                  <div>
                    <strong className="minerDetailTitle">Last updated:</strong>
                    <span style={{ paddingLeft: '11px' }}>
                      {new Date(row.original.last_updated).toLocaleString()}
                    </span>
                  </div>

                  <div>
                    <strong className="minerDetailTitle">Performance:</strong>
                    <span style={{ paddingLeft: '14px' }}>
                      {row.original.hashrate_performance} %
                    </span>
                  </div>

                  {row.original.problems &&
                    row.original.problems.length > 0 &&
                    row.original.problems.map((problem, i) => {
                      return (
                        <div>
                          <strong className="minerDetailTitle">
                            Current Problem {i + 1}:{' '}
                          </strong>
                          <span style={{ paddingLeft: '23px' }}>
                            {problem.problemName}
                          </span>{' '}
                          - <span>{problem.indicator}</span>
                        </div>
                      );
                    })}

                  <div>
                    <strong className="minerDetailTitle">History:</strong>
                  </div>

                  {row.original.indicator &&
                    Object.keys(row.original.indicator).length > 0 &&
                    row.original.indicator.map((problem, i) => {
                      if (Object.keys(problem).length > 0) {
                        problem['date'] = new Date(
                          problem['date']
                        ).toDateString();
                        return (
                          <div>
                            <strong
                              style={{ paddingLeft: '20px' }}
                              className="minerDetailSubTitle"
                            >
                              {problem['date']}:
                            </strong>
                            <span style={{ paddingLeft: '23px' }}>
                              Miner Status
                            </span>
                            : <span>{problem['miner_status']}</span>,{' '}
                            <span>Indicator</span>:{' '}
                            <span>{problem['indicator']}</span>
                          </div>
                        );
                      } else return null;
                    })}
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ rules }) => {
  return {
    rules,
  };
};

export default connect(mapStateToProps)(GeneralTable);
