export const LOGIN = 'LOGIN';
export const SET_MINER_STATUS = 'SET_MINER_STATUS';
export const SET_RULES_FEATURES = 'SET_RULES_FEATURES';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_POOLS = 'SET_POOLS';
export const SET_URLS = 'SET_URLS';
export const SET_CUSTOMER_POOLS = 'SET_CUSTOMER_POOLS';
export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_PERFORMANCE_DATA = 'SET_PERFORMANCE_DATA';
export const GET_WORKERS_HASHRATE_STATS = 'GET_WORKERS_HASHRATE_STATS';
export const GET_POOL_HASHRATE_STATS = 'GET_POOL_HASHRATE_STATS';
export const GET_POOL_REWARDS_STATS = 'GET_POOL_REWARDS_STATS';
export const GET_POOL_NAMES = 'GET_POOL_NAMES';
export const GET_POOL_STATUS = 'GET_POOL_STATUS';
export const GET_STRAPI_CONTENT = 'GET_STRAPI_CONTENT';
