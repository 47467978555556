import React, { Component } from 'react';
import ReactModal from 'react-modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { connect } from 'react-redux';
import './EditCustomerModal.scss';
import { updateCustomerRequest } from '../../../actions';

class EditCustomerModal extends Component {
  constructor() {
    super();
    this.state = {
      location: '',
      customer_pools: null,
      pools: [],
      pool: '',
      removed_pools: [],
    };
  }

  componentDidMount = () => {
    this.setPools();
  };

  componentDidUpdate = (prevprops) => {
    if (this.props.data !== prevprops.data) {
      this.setPools();
    }
  };

  setPools = () => {
    let customer_pools = {};
    let pools = [];
    this.props.customerPools.forEach((pool) => {
      pool['pools_customers'].forEach((pool_customer) => {
        if (pool_customer['customer']['name'] === this.props.data.name) {
          pools.push(pool['pool_name']);
        }
        if (Array.isArray(customer_pools[pool_customer['customer']['name']])) {
          customer_pools[pool_customer['customer']['name']].push(
            pool['pool_name']
          );
        } else {
          customer_pools[pool_customer['customer']['name']] = [
            pool['pool_name'],
          ];
        }
      });
    });
    this.setState({
      customer_pools,
      pools,
    });
  };

  validateForm = () => {
    return (
      this.state.location.length > 0 ||
      this.state.pool.length > 0 ||
      this.state.removed_pools.length > 0
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.props.data[event.target.id] = event.target.value;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let pools = this.state.pools;
    if (this.state.pool.length > 0) {
      pools.push(this.state.pool);
    }
    const newCustomer = {
      customer_id: this.props.data.customer_id,
      location:
        this.state.location.length > 0
          ? this.state.location
          : this.props.data.location,
      pools,
    };
    this.props.updateCustomerRequest(newCustomer, this.props.auth);
    this.props.handleCloseModal();
  };

  removePool = (pool) => {
    let pools = this.state.pools.filter((item) => item !== pool);
    this.setState({
      pools,
      removed_pools: [...this.state.removed_pools, pool],
    });
  };

  handleClose = () => {
    this.setState({
      location: '',
      pool: '',
      removed_pools: [],
    });
    this.setPools();
    this.props.handleCloseModal();
  };

  render() {
    return (
      <div>
        {this.props.data && (
          <ReactModal
            isOpen={this.props.isOpen}
            ariaHideApp={false}
            className="reactModalOverRide"
            style={{
              overlay: {
                zIndex: 10,
                backgroundColor: '#FFF',
                height: 420,
                width: 576,
                border: '1px solid #4F76DB',
                boxShadow: '0 0 3px 0 #CEDCF2',
                borderRadius: 5,
                boxSizing: 'border-box',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              },
            }}
          >
            <div className="modalHeader">
              <span id="addUser">Edit {this.props.header}</span>
            </div>
            <Form>
              <div className="modalFields">
                <div>
                  <Form.Group controlId="location">
                    <Form.Label className="fieldLabel">Location</Form.Label>
                    <Form.Control
                      type="location"
                      value={
                        this.state.location.length > 0
                          ? this.state.location
                          : this.props.data.location
                      }
                      className="inputBoxModal"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="modalFields">
                <div>
                  <Form.Group controlId="pool">
                    <Form.Label className="fieldLabel">Miner Group*</Form.Label>
                    <DropdownButton
                      id="dropdownModalBtn"
                      onSelect={(value) => {
                        this.setState({
                          pool: value,
                        });
                      }}
                      title={this.state.pool === '' ? null : this.state.pool}
                    >
                      {this.props.pools['all_pools'].map((value, key) => {
                        if (!this.state.pools.includes(value.pool_name)) {
                          return (
                            <DropdownItem key={key} eventKey={value.pool_name}>
                              {value.pool_name}
                            </DropdownItem>
                          );
                        }
                        return null;
                      })}
                    </DropdownButton>
                  </Form.Group>
                  <div className="tagContainer">
                    {this.state.pools.map((pool) => {
                      return (
                        <div
                          className="filterTag"
                          id="switchTag"
                          onClick={() => this.removePool(pool)}
                        >
                          {pool} &nbsp; x
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  marginRight: 24,
                  marginTop: 25,
                  gap: 12,
                }}
              >
                <Button
                  variant="outline-primary"
                  type="submit"
                  disabled={!this.validateForm()}
                  onClick={this.handleSubmit}
                  className="filterBtnModal"
                >
                  Save
                </Button>
                <Button onClick={this.handleClose} className="filterBtnModal">
                  Cancel
                </Button>
              </div>
            </Form>
          </ReactModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, { updateCustomerRequest })(
  EditCustomerModal
);
