import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './Unsaved.scss';
class Unsaved extends Component {
  render() {
    const { onHide, onDiscard } = this.props;
    return (
      <Modal
        {...this.props}
        dialogClassName="modalUnsavedPopup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="unsavedPopup">
          <p className="unsavedTitle">Unsaved Changes</p>
          <div className="unsavedConfirmation">
            <p>You have made changes. Do you want to save them?</p>
            <div className="unsaved-buttons">
              <button onClick={onHide} className="unsaved-accept-button">
                Save
              </button>
              <button onClick={onDiscard} className="unsaved-cancel-button">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default Unsaved;
