import React, { Component } from 'react';
import Helmet from 'react-helmet';
import './App.css';
import SizeNavBar from './components/SideNavBar';
import styled from 'styled-components';
import Miners from './components/Miners';
import SwitchesMap from './components/SwitchesMap';
import Admin from './components/Admin';
import Alerts from './components/Alerts';
import Report from './components/Report';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import history from './history';
import './App.scss';
import Header from './components/Header';
import Home from './components/Home';
import { connect } from 'react-redux';
import {
  loginRequest,
  tokenLoginRequest,
  performanceDataRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  logoutRequest,
  getUrls,
  getCustomerPools,
  minerWithIdRequest,
  getStrapiContent,
} from './actions';
import { get, size, map, isEmpty } from 'lodash';
import axios from 'axios';
import swal from 'sweetalert2';
import queryString from 'query-string';
const config = require('./config');

const Main = styled.main`
  position: relative;
  transition: all 0.15s;
  padding: 0;
  margin-left: ${(props) => (props.expanded ? 250 : 64)}px;
  @media (max-width: 1200px) {
    margin-left: ${(props) => (props.expanded ? 150 : 64)}px;
  }
  @media (max-width: 868px) {
    margin-left: 0;
  }
`;

const endpoints = {
  minersOnline: '/user/network_stats',
  userMinersOnline: '/user/network_stats',
  currentPowerData: '/user/power_data/current',
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: window.innerWidth <= 900 ? false : true,
      selected: this.props.match.params.page,
      minersData: null,
      powerData: null,
      minersOnline: null,
      averageTemperature: null,
      isAdmin: false,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    const saved_token = window.localStorage.getItem('token');
    const destination =
      this.props.location.pathname + this.props.location.search;
    await this.props.getStrapiContent();
    if (!get(this.props, 'auth.token', false) && saved_token !== null) {
      this.loadPage(this.props.match.params.page);
      await this.props.tokenLoginRequest(saved_token);
      await this.getMinersData('Loading');
      await this.props.performanceDataRequest(saved_token);
      await this.getNetworkStatsData(saved_token);
    } else if (!this.props.auth || !this.props.auth.token) {
      window.localStorage.setItem('url', destination);
      history.push('/login');
    } else {
      await this.getMinersData('Loading');
      await this.getNetworkStatsData(saved_token);
      await this.props.performanceDataRequest(saved_token);
    }
  };

  componentDidUpdate(prevprops) {
    const saved_token = window.localStorage.getItem('token');
    if (this.props.rules !== prevprops.rules) {
      if (
        this.props.rules &&
        this.props.rules.features.includes('view_switch_map')
      ) {
        this.setState({ isAdmin: true });
      }
    }
    if (prevprops.match.params.page !== this.props.match.params.page) {
      this.loadPage(this.props.match.params.page);
    }
    if (
      this.state.selected === 'miners' ||
      this.state.selected === 'switchesMap'
    ) {
      clearInterval(this.interval);
      this.interval = setInterval(async () => {
        await this.getMinersData('Updating');
        await this.getNetworkStatsData(saved_token);
      }, config.reloadTime);
    } else {
      clearInterval(this.interval);
    }
  }

  getMinersData = async (alertMsg) => {
    if (alertMsg === 'Loading') {
      this.setState({ isLoading: true });
    }
    let minersData = false;
    const values = queryString.parse(this.props.location.search);
    if (
      !isEmpty(values) &&
      'id' in values &&
      values['id'].match(/^-{0,1}\d+$/) &&
      parseInt(values['id']) > 0
    ) {
      minersData = await this.props.minerWithIdRequest(
        window.localStorage.getItem('token'),
        values.id
      );
      window.history.pushState(null, '');
    } else {
      minersData = await this.props.minerStatusRequest(
        window.localStorage.getItem('token')
      );
    }
    if (minersData) {
      const averageTemperature = (
        ((minersData || {}).json_response || {}).healthyMiners || []
      ).concat(minersData.json_response.brokenMiners);
      this.setState({ minersData: minersData.json_response });
      this.transformAverageTemperature(averageTemperature);
      if (this.state.averageTemperature) {
        swal.close();
        this.setState({ isLoading: false });
      }
    }
  };

  callNetworkStatsData = (
    endpoint,
    state_name,
    token,
    transformCallback = () => {}
  ) => {
    let arr = [];
    axios
      .get(config.urls.nodeServer + endpoint, {
        headers: { Authorization: token },
      })
      .then((res) => {
        let results = res.data;
        arr = map(results, (value, key) => {
          return value;
        });
        // ES6 [state_name] converts state_name string to variable state_name
        this.setState(
          {
            [state_name]: arr,
          },
          () => {
            transformCallback();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getNetworkStatsData = async (token) => {
    if (this.state.isAdmin === true) {
      await this.callNetworkStatsData(
        endpoints['minersOnline'],
        'minersOnline',
        token
      );
      await this.callNetworkStatsData(
        endpoints['currentPowerData'],
        'currentPowerData',
        token
      );
    } else {
      if (
        this.props.rules &&
        this.props.rules.features.includes('view_power_data')
      ) {
        await this.callNetworkStatsData(
          endpoints['currentPowerData'],
          'currentPowerData',
          token
        );
      }
      await this.callNetworkStatsData(
        endpoints['userMinersOnline'],
        'minersOnline',
        token
      );
    }
  };

  transformAverageTemperature = (averageTemperature) => {
    // get res from this.state.average_temperature, and transform to format we want
    let totalTemp = 0;
    let validTempCount = 0;
    let totalTempObj;
    if (averageTemperature) {
      averageTemperature.map((item) => {
        if (!item) {
          return false;
        }
        let temperature;
        if (size(get(item, 'misc.temp', {})) > 0) {
          if (item.misc.temp.hasOwnProperty('pcb')) {
            totalTempObj = { ...item.misc.temp.pcb, ...item.misc.temp.chip };
          }

          temperature = item.misc.temp.hasOwnProperty('pcb')
            ? Math.max(...Object.values(totalTempObj))
            : Math.max(...Object.values(item.misc.temp));
          if (temperature === parseInt(temperature, 10) && temperature !== 0) {
            totalTemp += temperature;
          }
          validTempCount++;
        } else {
          temperature = item.temperature;
          if (temperature === parseInt(temperature, 10) && temperature !== 0) {
            totalTemp += temperature;
            validTempCount++;
          }
        }
        return totalTemp;
      });

      const averageTemp = totalTemp / validTempCount;
      this.setState({
        averageTemperature: [{ averageTemperature: averageTemp }],
      });
    } else swal.close();
  };

  onSelect = (selected) => {
    this.setState({ selected: selected });
    history.push(`/mineManagement/${selected}`);
  };

  onToggle = (expanded) => {
    if (window.innerWidth <= 900) {
      this.setState({ expanded: false });
    } else this.setState({ expanded });
  };

  loadPage(page) {
    switch (page) {
      case 'miners':
        return (
          <Miners
            auth={this.props.auth}
            minersData={this.state.minersData}
            performanceData={this.props.performance_data}
            isLoading={this.state.isLoading}
          />
        );
      case 'switchesMap':
        return (
          <SwitchesMap
            currentPowerData={this.state.currentPowerData}
            isLoading={this.state.isLoading}
          />
        );
      case 'home':
        return (
          <Home
            minersData={this.state.minersData}
            powerData={this.state.powerData}
            isAdmin={this.state.isAdmin}
            currentPowerData={this.state.currentPowerData}
            onSelect={this.onSelect}
          />
        );
      case 'admin':
        return <Admin />;
      case 'report':
        return <Report minersData={null} isAdmin={this.state.isAdmin} />;
      case 'alerts':
        return <Alerts />;
      case 'error':
        return (
          <ErrorBoundary
            stopLoading={() => this.setState({ isLoading: false })}
          ></ErrorBoundary>
        );
      default:
        history.push('/mineManagement');
    }
  }

  render() {
    const { content } = this.props;
    return (
      <div>
        <Helmet>
          <title>{content.cCompanyName}</title>
          <link rel="icon" type="image/png" sizes="16x16" href={content.cFavicon} />
        </Helmet>
        {this.props.rules && (
          <>
            <SizeNavBar
              onSelect={this.onSelect}
              onToggle={this.onToggle}
              expanded={this.state.expanded}
              selected={this.state.selected}
            />
            {this.props.auth && (
              <Main expanded={this.state.expanded}>
                <Header
                  expanded={this.state.expanded}
                  minersData={this.state.minersData}
                  averageTemperature={this.state.averageTemperature}
                />
                <div style={{ margin: `100px 0 0 0px` }}>
                  {this.loadPage(this.props.match.params.page)}
                </div>
              </Main>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  miner_status,
  rules,
  performance_data,
  customers,
  urls,
  slushpool,
  customer_pools,
  content,
}) => {
  return {
    auth,
    miner_status,
    rules,
    customers,
    urls,
    performance_data,
    slushpool,
    customer_pools,
    content,
  };
};

export default connect(mapStateToProps, {
  loginRequest,
  tokenLoginRequest,
  performanceDataRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  getUrls,
  logoutRequest,
  getCustomerPools,
  minerWithIdRequest,
  getStrapiContent,
})(App);
