import React, { Component } from 'react';
import swal from 'sweetalert2';
import axios from 'axios';
import { connect } from 'react-redux';
const config = require('../../../config');

class EditMinerSettingSubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: false,
    };
  }

  handleDeleteRow = async (data) => {
    await axios
      .delete(`${config.urls.nodeServer}/admin/thresholds`, {
        headers: { authorization: this.props.auth.token },
        data: data,
      })
      .then((res) => {
        swal({
          title: 'Successfully deleted miner setting',
        });
      });
  };

  render() {
    const remainder =
      this.props.minerSettingData.index % this.props.tablePageSize;
    return (
      <div>
        <div
          className="editUserSubMenu"
          style={{
            top:
              this.props.tablePage === 0 &&
              this.props.minerSettingData.index < this.props.tablePageSize
                ? `calc(70px + 37px* ${this.props.minerSettingData.index})`
                : `calc(70px + 37px* ${remainder})`,
          }}
        >
          <button
            id="editRowBtn"
            onClick={() => {
              this.props.openEditMinerSettingModal();
            }}
          >
            Edit Miner Setting
          </button>
          <button
            id="editRowBtn"
            onClick={() => {
              this.handleDeleteRow(this.props.minerSettingData.original);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(EditMinerSettingSubMenu);
