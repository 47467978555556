import React, { Component } from 'react';
import _ from 'lodash';
import './Home.scss';
import { connect } from 'react-redux';
import {
  getRulesFeaturesRequest,
  getCustomerPools,
  getCustomers,
} from '../../actions';
import { numberCommaFormat } from '../../helpers';
import DashboardStats from '../DashboardStats';
import Filter from './Filter';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      minersData: null,
      selectedCustomer: [],
      selectedPool: [],
      filteredData: null,
      workersHashrateFilteredData: null,
      data: null,
    };
  }

  componentDidMount = async () => {
    if (_.get(this.props, 'auth.token', false)) {
      await this.getMinersData();
      await this.props.getCustomerPools(this.props.auth);
      await this.props.getCustomers(this.props.auth);
    }
  };

  componentDidUpdate = async (prevprops) => {
    if (this.props.minersData !== prevprops.minersData) {
      await this.getMinersData();
      await this.props.getCustomerPools(this.props.auth);
    }
  };

  getMinersData() {
    if (this.props.minersData) {
      const data = this.props.minersData;
      const issueMinersData = data.issueMiners;
      const healthyMinersData = data.healthyMiners;
      const offlineMinersData = data.offlineMiners;
      const inRepairMinersData = data.inRepairMiners;
      const minersData = issueMinersData.concat(
        healthyMinersData,
        offlineMinersData,
        inRepairMinersData
      );
      this.setState({ minersData, data });
    }
  }

  handleChange = (
    selectedPool,
    selectedCustomer,
    workersHashrateFilteredData = null,
    filteredData = null
  ) => {
    this.setState({
      selectedPool: [...selectedPool],
      selectedCustomer: [...selectedCustomer],
      workersHashrateFilteredData,
      filteredData,
    });
  };

  render() {
    return (
      <div className="chartsSection">
        {this.props.customer_pools && this.state.minersData && (
          <div className="filteredChartsSection">
            <Filter
              handleChange={this.handleChange}
              data={this.state.data}
              workers_hashrate={this.props.workers_hashrate}
            />
            <div className="filteredCharts" id="reportCharts">
              <DashboardStats
                onSelect={this.props.onSelect}
                minersData={
                  this.state.filteredData
                    ? this.state.filteredData
                    : this.state.data
                }
                selectedPool={
                  this.state.selectedPool.length > 0
                    ? this.state.selectedPool
                    : null
                }
              />
            </div>
          </div>
        )}
        {(this.props.isAdmin ||
          (this.props.rules &&
            this.props.rules.features.includes('view_power_data'))) && (
          <div className="noFilteredCharts">
            {/* <PowerDataChart powerData={this.props.powerData} /> */}
            <div className="powerDataCell">
              {this.props.currentPowerData &&
                _.map(this.props.currentPowerData[0], (value, key) => {
                  return (
                    <div key={key} className="unitContainer">
                      <div className="unitTitle">
                        {value.unit !== 'substation'
                          ? 'Unit ' + value.unit
                          : 'Substation'}
                      </div>
                      <div className="unitPowerData">
                        {value.unit !== 'substation'
                          ? numberCommaFormat(value.energy_used.toFixed(0))
                          : numberCommaFormat(value.demand.toFixed(0))}{' '}
                        <span>kW</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  customer_pools,
  rules,
  customers,
  workers_hashrate,
}) => {
  return {
    auth,
    customer_pools,
    rules,
    customers,
    workers_hashrate,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, {
  getCustomerPools,
  getRulesFeaturesRequest,
  getCustomers,
})(Home);
