import { combineReducers } from 'redux';
import auth from './auth';
import miner_status from './miner_status';
import rules from './rules';
import customers from './customers';
import urls from './urls';
import customer_pools from './customer_pools';
import users from './users';
import performance_data from './performance_data';
import workers_hashrate from './workers_hashrate';
import pool_hashrate from './pool_hashrate';
import pool_rewards from './pool_rewards';
import pool_names from './pool_names';
import pools from './pools';
import pool_status from './pool_status';
import content from './content';

export default combineReducers({
  auth,
  miner_status,
  rules,
  customers,
  urls,
  customer_pools,
  users,
  performance_data,
  workers_hashrate,
  pool_hashrate,
  pool_rewards,
  pool_names,
  pools,
  pool_status,
  content,
});
