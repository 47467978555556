import { get } from 'lodash';

const check = (rules, action) => {
  // returns if the requested action is permitted
  const features = get(rules, 'features', []);
  return features.length > 0 && features.includes(action);
};

const CanDisplay = (props) =>
  // check that the user with this role can do this action,
  // execute the appropriate callback function
  check(props.rules, props.perform) ? props.yes() : props.no();

CanDisplay.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default CanDisplay;
