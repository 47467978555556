import React, { Component } from 'react';
import { connect } from 'react-redux';

class EditAlertSettingSubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: false,
    };
  }

  render() {
    const remainder =
      this.props.alertSettingData.index % this.props.tablePageSize;
    return (
      <div>
        <div
          className="editUserSubMenu"
          style={{
            top:
              this.props.tablePage === 0 &&
              this.props.alertSettingData.index < this.props.tablePageSize
                ? `calc(70px + 37px* ${this.props.alertSettingData.index})`
                : `calc(70px + 37px* ${remainder})`,
          }}
        >
          <button
            id="editRowBtn"
            onClick={() => {
              this.props.openEditAlertSettingModal();
            }}
          >
            Edit Alert
          </button>
          <button
            id="editRowBtn"
            onClick={() => {
              this.props.openDeleteAlertModal();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(EditAlertSettingSubMenu);
