import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
const config = require('../../../config');

function DeleteAlertSetting(props) {
  const token = useSelector((state) => state.auth.token);

  async function handleDeleteRow(data) {
    await axios
      .delete(`${config.urls.nodeServer}/user/alerts/settings`, {
        headers: { authorization: token },
        data: data,
      })
      .then((res) => {
        props.onHide();
        props.handleResponseSuccess(res, 'delete');
      })
      .catch((error) => {
        props.onHide();
        props.handleResponseError(error, 'delete');
      });
  }

  return (
    <Modal
      show={props.show}
      data-backdrop="static"
      data-keyboard="false"
      centered
      dialogClassName="deleteAccountModal"
    >
      <Modal.Header>
        <Modal.Title>
          Are you sure you want to delete alert {props.data.name} ?
        </Modal.Title>
      </Modal.Header>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDeleteRow(props.data)}>
          Delete Alert
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAlertSetting;
