import React, { Component } from 'react';
import { connect } from 'react-redux';
import './PoolWorkerStats.scss';
import { transformHashrate } from '../../../helpers';
import { getWorkerHashrates } from '../../../actions';
import CircularProgress from '@material-ui/core/CircularProgress';

class PoolWorkerStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_current_hashrate: null,
      total_last_fifteen_min_hashrate: null,
      total_last_hour_hashrate: null,
      total_last_day_hashrate: null,
      loading: false,
    };
  }

  componentDidMount = async () => {
    if (!this.props.workers_hashrate) {
      this.setState({ loading: true });
      await this.props.getWorkerHashrates(
        this.props.auth.token,
        this.props.selectedPool,
        this.props.selectedCustomer
      );
    }
    this.setState({ loading: false });
    this.filterData();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.selectedPool !== this.props.selectedPool) {
      this.setState({
        loading: true,
      });
      await this.props.getWorkerHashrates(
        this.props.auth.token,
        this.props.selectedPool,
        this.props.selectedCustomer
      );
      this.setState({
        loading: false,
      });
      this.filterData();
    }
  };

  filterData = () => {
    let total_current_hashrate = 0;
    let total_last_fifteen_min_hashrate = 0;
    let total_last_hour_hashrate = 0;
    let total_last_day_hashrate = 0;
    if (this.props.workers_hashrate !== null) {
      this.props.workers_hashrate.data.forEach((worker) => {
        total_current_hashrate += worker.total_current_hashrate;
        total_last_fifteen_min_hashrate +=
          worker.total_last_fifteen_min_hashrate;
        total_last_hour_hashrate += worker.total_last_hour_hashrate;
        total_last_day_hashrate += worker.total_last_day_hashrate;
      });
    }
    this.setState({
      total_current_hashrate: transformHashrate(
        total_current_hashrate / 1000,
        'KH/s'
      ),
      total_last_fifteen_min_hashrate: transformHashrate(
        total_last_fifteen_min_hashrate / 1000,
        'KH/s'
      ),
      total_last_hour_hashrate: transformHashrate(
        total_last_hour_hashrate / 1000,
        'KH/s'
      ),
      total_last_day_hashrate: transformHashrate(
        total_last_day_hashrate / 1000,
        'KH/s'
      ),
    });
  };

  render() {
    return (
      <div className="infoBox">
        <div>
          <p className="infoBoxLabel">Pool Workers Stats</p>
          <div className="tagContainer">
            {this.props.selectedCustomer &&
              this.props.selectedCustomer.map((customer) => {
                return (
                  <div className="filterTag customerTag">{customer.value}</div>
                );
              })}
            {this.props.selectedPool &&
              this.props.selectedPool.map((pool) => {
                return <div className="filterTag poolTag">{pool.value}</div>;
              })}
          </div>
          <div className="flexRow">
            <div
              className="flexColumn"
              style={{
                width: '250px',
                borderRight: '1px solid rgba(0,0,0,0.3)',
                marginTop: '50px',
                paddingRight: '15px',
              }}
            >
              <div>
                <p className="p12">Current Hashrate</p>
                <div className="value mainValue" style={{ marginTop: '20px' }}>
                  {this.state.loading ? (
                    <CircularProgress size="1.5vw" />
                  ) : (
                    this.state.total_current_hashrate
                  )}
                </div>
              </div>
            </div>

            <div style={{ margin: '80px 0px 50px 50px', width: '220px' }}>
              <div className="flexSpaceBetween">
                <p className="p12">Expected Hashrate</p>
                <div className="value">N/A</div>
              </div>
              <div className="flexSpaceBetween">
                <p className="p12">Hashrate Difference</p>
                <div className="value">N/A</div>
              </div>
              <div className="flexSpaceBetween">
                <p className="p12">Hashrate Delta</p>
                <div className="value">N/A</div>
              </div>
            </div>
          </div>

          <hr style={{ marginTop: '40px' }} />

          <div className="flexSpaceBetween valueGroup">
            <div>
              <p className="p12">Last 15 mins</p>
              <div className="value rewardItemValue">
                {this.state.loading ? (
                  <CircularProgress size="1.0vw" />
                ) : (
                  this.state.total_last_fifteen_min_hashrate
                )}
              </div>
            </div>
            <div>
              <p className="p12">Last 1 hour</p>
              <div className="value rewardItemValue">
                {this.state.loading ? (
                  <CircularProgress size="1.0vw" />
                ) : (
                  this.state.total_last_hour_hashrate
                )}
              </div>
            </div>
            <div>
              <p className="p12">Today</p>
              <div className="value rewardItemValue">
                {this.state.loading ? (
                  <CircularProgress size="1.0vw" />
                ) : (
                  this.state.total_last_day_hashrate
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PoolWorkerStats.defaultProps = {
  selectedPool: [],
  worker_hashrate: {},
};

const mapStateToProps = ({ auth, workers_hashrate }) => {
  return {
    auth,
    workers_hashrate,
  };
};

export default connect(mapStateToProps, { getWorkerHashrates })(
  PoolWorkerStats
);
