import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BlockseerPoolHashrate.scss';
import { connect } from 'react-redux';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import reportBlockFound from '../../../assets/reportBlockFound.png';

class BlockseerPoolHashrate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_current_hashrate: null,
      all_pool_hashrate_data: null,
      total_last_fifteen_min_hashrate: null,
      total_last_hour_hashrate: null,
      total_last_day_hashrate: null,
      total_blocks_found: null,
      currentBtcPriceCAD: null,
      currentBtcPriceUSD: null,
    };
  }

  componentDidUpdate = async () => {
    const { pool_hashrate } = this.props;
    const { all_pool_hashrate_data } = this.state;

    if (pool_hashrate && !all_pool_hashrate_data) {
      await this.setData();
    }
  };

  componentDidMount = async () => {
    const { pool_hashrate } = this.props;

    if (pool_hashrate) {
      await this.setData();
    }
    await this.getCurrentBitcoinPrice();
  };

  setData = () => {
    const { pool_hashrate } = this.props;

    this.setState({
      all_pool_hashrate_data: pool_hashrate,
      total_current_hashrate: pool_hashrate.total_current_hashrate,
      total_last_fifteen_min_hashrate:
        pool_hashrate.total_last_fifteen_min_hashrate,
      total_last_hour_hashrate: pool_hashrate.total_last_hour_hashrate,
      total_last_day_hashrate: pool_hashrate.total_last_day_hashrate,
      total_blocks_found: pool_hashrate.total_blocks_found,
    });
  };

  getCurrentBitcoinPrice = async () => {
    try {
      const bitcoinPrice = await axios.get(
        'https://api.coindesk.com/v1/bpi/currentprice/CAD.json'
      );
      if (bitcoinPrice) {
        this.setState({
          currentBtcPriceCAD: bitcoinPrice.data.bpi.CAD.rate,
          currentBtcPriceUSD: bitcoinPrice.data.bpi.USD.rate,
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  render() {
    const {
      total_current_hashrate,
      total_last_fifteen_min_hashrate,
      total_last_hour_hashrate,
      total_last_day_hashrate,
      currentBtcPriceUSD,
      currentBtcPriceCAD,
      total_blocks_found,
    } = this.state;

    const { reportPage, content } = this.props;

    return (
      <div className="infoBox" id="blockseerPoolHashrateContainer">
        <div className="content1">
          <p className="infoBoxLabel">{content.cCompanyName} Hashrate</p>
          <div>
            <p className="p12 mainLabel">Current Hashrate</p>
            <div className="value mainValue">
              {total_current_hashrate === null ? (
                <CircularProgress size="1.5vw" />
              ) : (
                total_current_hashrate
              )}
            </div>
          </div>
          <hr style={{ marginTop: '40px' }} />
          <div className="flexSpaceBetween" style={{ width: '300px' }}>
            <div>
              <p className="p12">15 mins</p>
              <div className="value workerStatsItemValue">
                {total_last_fifteen_min_hashrate === null ? (
                  <CircularProgress size="1.0vw" />
                ) : (
                  total_last_fifteen_min_hashrate
                )}
              </div>
            </div>
            <div>
              <p className="p12">Hour</p>
              <div className="value workerStatsItemValue">
                {total_last_hour_hashrate === null ? (
                  <CircularProgress size="1.0vw" />
                ) : (
                  total_last_hour_hashrate
                )}
              </div>
            </div>
            <div>
              <p className="p12">Day</p>
              <div className="value workerStatsItemValue">
                {total_last_day_hashrate === null ? (
                  <CircularProgress size="1.0vw" />
                ) : (
                  total_last_day_hashrate
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="content2">
          <p className="infoBoxLabel">Network Stats</p>
          <div className="infoBoxValue">
            <div>
              <p className="p12">BTC to USD</p>
              <div className="value workerStatsItemValue">
                {`${currentBtcPriceUSD} USD`}
              </div>
            </div>
            <div>
              <p className="p12">BTC to CAD</p>
              <div className="value workerStatsItemValue">
                {`${currentBtcPriceCAD} CAD`}
              </div>
            </div>
            {!reportPage && (
              <div>
                <p className="p12">BTC / Per Hashrate / Day</p>
                <div className="value workerStatsItemValue">TBD</div>
              </div>
            )}
            {reportPage && (
              <div>
                <p className="p12">Blocks Found</p>
                <div className="value workerStatsItemValue">
                  <img
                    src={reportBlockFound}
                    alt="blocks-icon"
                    className="itemIcon"
                    style={{
                      width: '35px',
                      height: '35px',
                      marginRight: '15px',
                    }}
                  />
                  {total_blocks_found === null ? (
                    <CircularProgress size="1.0vw" />
                  ) : (
                    total_blocks_found
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BlockseerPoolHashrate.propTypes = {
  pool_hashrate: PropTypes.shape({
    total_current_hashrate: PropTypes.string,
    total_last_fifteen_min_hashrate: PropTypes.string,
    total_last_hour_hashrate: PropTypes.string,
    total_last_day_hashrate: PropTypes.string,
    total_blocks_found: PropTypes.string,
  }),
  reportPage: PropTypes.bool,
};

BlockseerPoolHashrate.defaultProps = {
  pool_hashrate: {
    total_current_hashrate: '0',
    total_last_fifteen_min_hashrate: '0',
    total_last_hour_hashrate: '0',
    total_last_day_hashrate: '0',
    total_blocks_found: '0',
  },
  reportPage: false,
};

const mapStateToProps = ({ auth, pool_hashrate, content }) => ({
  auth,
  pool_hashrate,
  content,
});

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps)(BlockseerPoolHashrate);
