import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default class ErrorBoundary extends React.Component {
  componentDidMount() {
    this.props.stopLoading();
  }

  render() {
    // You can render any custom fallback UI
    return (
      <div style={{ textAlign: 'center', marginTop: '25%' }}>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{ fontSize: '50px', marginBottom: '1%' }}
        />
        <h1>Oops!</h1>
        <h1>Something went wrong.</h1>
        <p>We couldn't process your request. Please try again.</p>
      </div>
    );
  }
}
