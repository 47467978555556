const MapLayout = {
  title: '',
  layout: {
    columns: 2,
    rows: 1,
  },
  contents: [
    {
      title: 'PHASE 1',
      type: 'Phase',
      placement: {
        row: 1,
        row_span: 1,
        column: 2,
        column_span: 1,
      },
      layout: {
        rows: 17,
        columns: 12,
      },
      contents: [
        {
          title: 'FDR1-US11',
          type: 'Area',
          placement: {
            column: 1,
            column_span: 4,
            row: 1,
            row_span: 8,
          },
          layout: {
            rows: 2,
            columns: 1,
          },
          contents: [
            {
              title: 'Coquihala',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'X4' },
                    { type: 'Switch', title: 'X3' },
                    { type: 'Switch', title: 'X2' },
                    { type: 'Switch', title: 'X1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'T4' },
                    { type: 'Switch', title: 'T3' },
                    { type: 'Switch', title: 'T2' },
                    { type: 'Switch', title: 'T1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'W4' },
                    { type: 'Switch', title: 'W3' },
                    { type: 'Switch', title: 'W2' },
                    { type: 'Switch', title: 'W1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'S4' },
                    { type: 'Switch', title: 'S3' },
                    { type: 'Switch', title: 'S2' },
                    { type: 'Switch', title: 'S1' },
                  ],
                },
              ],
            },
            {
              title: 'Monashee',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 2,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'V4' },
                    { type: 'Switch', title: 'V3' },
                    { type: 'Switch', title: 'V2' },
                    { type: 'Switch', title: 'V1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'R4' },
                    { type: 'Switch', title: 'R3' },
                    { type: 'Switch', title: 'R2' },
                    { type: 'Switch', title: 'R1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'U4' },
                    { type: 'Switch', title: 'U3' },
                    { type: 'Switch', title: 'U2' },
                    { type: 'Switch', title: 'U1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'Q4' },
                    { type: 'Switch', title: 'Q3' },
                    { type: 'Switch', title: 'Q2' },
                    { type: 'Switch', title: 'Q1' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'FDR1-US12',
          type: 'Area',
          placement: {
            column: 5,
            column_span: 8,
            row: 1,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 2,
          },
          contents: [
            {
              title: 'Surrey Lake',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'P4' },
                    { type: 'Switch', title: 'P3' },
                    { type: 'Switch', title: 'P2' },
                    { type: 'Switch', title: 'P1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'L2' },
                    { type: 'Switch', title: 'L1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'O4' },
                    { type: 'Switch', title: 'O3' },
                    { type: 'Switch', title: 'O2' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'K2' },
                    { type: 'Switch', title: 'K1' },
                  ],
                },
              ],
            },
            {
              title: 'Allison',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'H2' },
                    { type: 'Switch', title: 'H1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'D2' },
                    { type: 'Switch', title: 'D1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'G2' },
                    { type: 'Switch', title: 'G1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'C2' },
                    { type: 'Switch', title: 'C1' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'FDR1-US10',
          type: 'Area',
          placement: {
            column: 5,
            column_span: 8,
            row: 5,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 2,
          },
          contents: [
            {
              title: 'Logan Lake',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'N2' },
                    { type: 'Switch', title: 'N1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'J2' },
                    { type: 'Switch', title: 'J1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'M2' },
                    { type: 'Switch', title: 'M1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'I2' },
                    { type: 'Switch', title: 'I1' },
                  ],
                },
              ],
            },
            {
              title: 'Kicking Horse',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'F4' },
                    { type: 'Switch', title: 'F3' },
                    { type: 'Switch', title: 'F2' },
                    { type: 'Switch', title: 'F1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'B4' },
                    { type: 'Switch', title: 'B3' },
                    { type: 'Switch', title: 'B2' },
                    { type: 'Switch', title: 'B1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'E4' },
                    { type: 'Switch', title: 'E3' },
                    { type: 'Switch', title: 'E2' },
                    { type: 'Switch', title: 'E1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'A4' },
                    { type: 'Switch', title: 'A3' },
                    { type: 'Switch', title: 'A2' },
                    { type: 'Switch', title: 'A1' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Area 1',
          type: 'Core-area',
          switchId: 'ycl1',
          placement: {
            column: 1,
            column_span: 3,
            row: 9,
            row_span: 1,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
        },
        {
          title: 'Area 2',
          type: 'Core-area',
          switchId: 'ycl2',
          placement: {
            column: 4,
            column_span: 3,
            row: 9,
            row_span: 1,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
        },
        {
          title: 'Area 1 Backup',
          type: 'Core-area',
          switchId: 'ycl1',
          placement: {
            column: 7,
            column_span: 3,
            row: 9,
            row_span: 1,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
        },
        {
          title: 'Area 2 Backup',
          type: 'Core-area',
          switchId: 'ycl2',
          placement: {
            column: 10,
            column_span: 3,
            row: 9,
            row_span: 1,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
        },
        {
          title: 'FDR1-US9',
          type: 'Area',
          placement: {
            column: 1,
            column_span: 8,
            row: 10,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 2,
          },
          contents: [
            {
              title: 'Yellow Head',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'UU1' },
                    { type: 'Switch', title: 'UU2' },
                    { type: 'Switch', title: 'UU3' },
                    { type: 'Switch', title: 'UU4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'QQ1' },
                    { type: 'Switch', title: 'QQ2' },
                    { type: 'Switch', title: 'QQ3' },
                    { type: 'Switch', title: 'QQ4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'VV1' },
                    { type: 'Switch', title: 'VV2' },
                    { type: 'Switch', title: 'VV3' },
                    { type: 'Switch', title: 'VV4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'RR1' },
                    { type: 'Switch', title: 'RR2' },
                    { type: 'Switch', title: 'RR3' },
                    { type: 'Switch', title: 'RR4' },
                  ],
                },
              ],
            },
            {
              title: 'Anarchist',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'ZZ1' },
                    { type: 'Switch', title: 'MM1' },
                    { type: 'Switch', title: 'MM2' },
                    { type: 'Switch', title: 'MM3' },
                    { type: 'Switch', title: 'MM4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'II1' },
                    { type: 'Switch', title: 'II2' },
                    { type: 'Switch', title: 'II3' },
                    { type: 'Switch', title: 'II4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'NN1' },
                    { type: 'Switch', title: 'NN2' },
                    { type: 'Switch', title: 'NN3' },
                    { type: 'Switch', title: 'NN4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'JJ1' },
                    { type: 'Switch', title: 'JJ2' },
                    { type: 'Switch', title: 'JJ3' },
                    { type: 'Switch', title: 'JJ4' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'FDR1-US8',
          type: 'Area',
          placement: {
            column: 9,
            column_span: 4,
            row: 10,
            row_span: 8,
          },
          layout: {
            rows: 2,
            columns: 1,
          },
          contents: [
            {
              title: 'Crowsnest',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'EE1' },
                    { type: 'Switch', title: 'EE2' },
                    { type: 'Switch', title: 'EE3' },
                    { type: 'Switch', title: 'EE4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'AA1' },
                    { type: 'Switch', title: 'AA2' },
                    { type: 'Switch', title: 'AA3' },
                    { type: 'Switch', title: 'AA4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'FF1' },
                    { type: 'Switch', title: 'FF2' },
                    { type: 'Switch', title: 'FF3' },
                    { type: 'Switch', title: 'FF4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'BB1' },
                    { type: 'Switch', title: 'BB2' },
                    { type: 'Switch', title: 'BB3' },
                    { type: 'Switch', title: 'BB4' },
                  ],
                },
              ],
            },
            {
              title: 'Sunday',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 2,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'GG1' },
                    { type: 'Switch', title: 'GG2' },
                    { type: 'Switch', title: 'GG3' },
                    { type: 'Switch', title: 'GG4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'CC1' },
                    { type: 'Switch', title: 'CC2' },
                    { type: 'Switch', title: 'CC3' },
                    { type: 'Switch', title: 'CC4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'HH1' },
                    { type: 'Switch', title: 'HH2' },
                    { type: 'Switch', title: 'HH3' },
                    { type: 'Switch', title: 'HH4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'DD1' },
                    { type: 'Switch', title: 'DD2' },
                    { type: 'Switch', title: 'DD3' },
                    { type: 'Switch', title: 'DD4' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'FDR1-US7',
          type: 'Area',
          placement: {
            column: 1,
            column_span: 8,
            row: 14,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 2,
          },
          contents: [
            {
              title: 'Rainbow',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'WW1' },
                    { type: 'Switch', title: 'WW2' },
                    { type: 'Switch', title: 'WW3' },
                    { type: 'Switch', title: 'WW4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'SS1' },
                    { type: 'Switch', title: 'SS2' },
                    { type: 'Switch', title: 'SS3' },
                    { type: 'Switch', title: 'SS4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'XX1' },
                    { type: 'Switch', title: 'XX2' },
                    { type: 'Switch', title: 'XX3' },
                    { type: 'Switch', title: 'XX4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'TT1' },
                    { type: 'Switch', title: 'TT2' },
                    { type: 'Switch', title: 'TT3' },
                    { type: 'Switch', title: 'TT4' },
                  ],
                },
              ],
            },
            {
              title: 'Kootenay',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'OO1' },
                    { type: 'Switch', title: 'OO2' },
                    { type: 'Switch', title: 'OO3' },
                    { type: 'Switch', title: 'OO4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'KK1' },
                    { type: 'Switch', title: 'KK2' },
                    { type: 'Switch', title: 'KK3' },
                    { type: 'Switch', title: 'KK4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'PP1' },
                    { type: 'Switch', title: 'PP2' },
                    { type: 'Switch', title: 'PP3' },
                    { type: 'Switch', title: 'PP4' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'LL1' },
                    { type: 'Switch', title: 'LL2' },
                    { type: 'Switch', title: 'LL3' },
                    { type: 'Switch', title: 'LL4' },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'PHASE 2',
      type: 'Phase',
      placement: {
        row: 1,
        row_span: 1,
        column: 1,
        column_span: 1,
      },
      layout: {
        rows: 17,
        columns: 6,
      },
      contents: [
        {
          title: 'FDR1-US5',
          type: 'Area',
          placement: {
            column: 1,
            column_span: 2,
            row: 1,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
          contents: [
            {
              title: 'Vermillion',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
            },
          ],
        },
        {
          title: 'FDR1-US6',
          type: 'Area',
          placement: {
            column: 3,
            column_span: 4,
            row: 1,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 2,
          },
          contents: [
            {
              title: 'Sinclair',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'P4' },
                    { type: 'Switch', title: 'P3' },
                    { type: 'Switch', title: 'P2' },
                    { type: 'Switch', title: 'P1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'L4' },
                    { type: 'Switch', title: 'L3' },
                    { type: 'Switch', title: 'L2' },
                    { type: 'Switch', title: 'L1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'O4' },
                    { type: 'Switch', title: 'O3' },
                    { type: 'Switch', title: 'O2' },
                    { type: 'Switch', title: 'O1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'K4' },
                    { type: 'Switch', title: 'K3' },
                    { type: 'Switch', title: 'K2' },
                    { type: 'Switch', title: 'K1' },
                  ],
                },
              ],
            },
            {
              title: 'McDonald',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'H4' },
                    { type: 'Switch', title: 'H3' },
                    { type: 'Switch', title: 'H2' },
                    { type: 'Switch', title: 'H1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'D4' },
                    { type: 'Switch', title: 'D3' },
                    { type: 'Switch', title: 'D2' },
                    { type: 'Switch', title: 'D1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'G4' },
                    { type: 'Switch', title: 'G3' },
                    { type: 'Switch', title: 'G2' },
                    { type: 'Switch', title: 'G1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'C4' },
                    { type: 'Switch', title: 'C3' },
                    { type: 'Switch', title: 'C2' },
                    { type: 'Switch', title: 'C1' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'FDR1-US4',
          type: 'Area',
          placement: {
            column: 1,
            column_span: 6,
            row: 5,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 3,
          },
          contents: [
            {
              title: 'Bonanza',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'V4' },
                    { type: 'Switch', title: 'V3' },
                    { type: 'Switch', title: 'V2' },
                    { type: 'Switch', title: 'V1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'U4' },
                    { type: 'Switch', title: 'U3' },
                    { type: 'Switch', title: 'U2' },
                    { type: 'Switch', title: 'U1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'AMDGPU1' }],
                },
              ],
            },
            {
              title: 'Elk',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'M1' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    {
                      type: 'Switch',
                      title: 'J1',
                      placement: {
                        column: 1,
                        column_span: 1,
                        row: 1,
                        row_span: 4,
                      },
                    },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'N1' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'I1' }],
                },
              ],
            },
            {
              title: 'Heckman',
              type: 'Pod',
              placement: {
                column: 3,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'F4' },
                    { type: 'Switch', title: 'F3' },
                    { type: 'Switch', title: 'F2' },
                    { type: 'Switch', title: 'F1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'B4' },
                    { type: 'Switch', title: 'B3' },
                    { type: 'Switch', title: 'B2' },
                    { type: 'Switch', title: 'B1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'E4' },
                    { type: 'Switch', title: 'E3' },
                    { type: 'Switch', title: 'E2' },
                    { type: 'Switch', title: 'E1' },
                  ],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [
                    { type: 'Switch', title: 'A4' },
                    { type: 'Switch', title: 'A3' },
                    { type: 'Switch', title: 'A2' },
                    { type: 'Switch', title: 'A1' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Area 3',
          type: 'Core-area',
          switchId: 'ycl3',
          placement: {
            column: 1,
            column_span: 3,
            row: 9,
            row_span: 1,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
        },
        {
          title: 'Area 3 Backup',
          type: 'Core-area',
          switchId: 'ycl3',
          placement: {
            column: 4,
            column_span: 3,
            row: 9,
            row_span: 1,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
        },
        {
          title: 'FDR1-US1',
          type: 'Area',
          placement: {
            column: 1,
            column_span: 2,
            row: 10,
            row_span: 4,
          },
          contents: [
            {
              title: 'Athabasca',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
            },
          ],
        },
        {
          title: 'FDR1-US1',
          type: 'Area',
          placement: {
            column: 1,
            column_span: 4,
            row: 14,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 2,
          },
          contents: [
            {
              title: 'Cascade',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
            },
            {
              title: 'Rodgers',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
            },
          ],
        },
        {
          title: 'FDR1-US2',
          type: 'Area',
          placement: {
            column: 5,
            column_span: 2,
            row: 14,
            row_span: 4,
          },
          layout: {
            rows: 1,
            columns: 1,
          },
          contents: [
            {
              title: 'Paulson',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                rows: 2,
                columns: 2,
              },
            },
          ],
        },
        {
          title: 'FDR2-US4',
          type: 'Area',
          placement: {
            column: 3,
            column_span: 4,
            row: 10,
            row_span: 4,
          },
          layout: {
            columns: 2,
            rows: 1,
          },
          contents: [
            {
              title: 'Eagle',
              type: 'Pod',
              placement: {
                column: 1,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                columns: 2,
                rows: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'MM1' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'NN1' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'II1' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'II2' }],
                },
              ],
            },
            {
              title: 'Fortress',
              type: 'Pod',
              placement: {
                column: 2,
                column_span: 1,
                row: 1,
                row_span: 1,
              },
              layout: {
                columns: 2,
                rows: 2,
              },
              contents: [
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'FF1' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 1,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'FF2' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 1,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'AA1' }],
                },
                {
                  type: 'Stack',
                  placement: {
                    column: 2,
                    column_span: 1,
                    row: 2,
                    row_span: 1,
                  },
                  contents: [{ type: 'Switch', title: 'AA2' }],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default MapLayout;
