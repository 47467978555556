if (process.env.REACT_APP_ENV) {
  let serverPath;
  let frontEnd;
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      serverPath = 'api';
      frontEnd = 'https://dashboard.dmgblockchain.com';
      break;
    case 'marapool':
      serverPath = 'marapool';
      frontEnd = 'https://www.dashboard.marapool.com';
      break;
    case 'staging':
      serverPath = 'staging';
      frontEnd = 'https://staging.dmgblockchain.com';
      break;
    case 'development':
      serverPath = 'dev';
      frontEnd = 'https://dev.dmgblockchain.com';
      break;
    case 'beta':
      serverPath = 'beta';
      frontEnd = 'https://beta.dmgblockchain.com';
      break;
    case 'testnet':
      serverPath = 'testnet';
      frontEnd = 'https://testnet.dmgblockchain.com';
      break;
    default:
      serverPath = 'dev';
      break;
  }

  if (process.env.REACT_APP_ENV === 'marapool') {
    module.exports = {
      urls: {
        flaskServer: 'https://cluster.dashboard.marapool.com/flask/miners',
        authentication: `https://cluster.dashboard.marapool.com/${serverPath}/auth/token`,
        minerStatus: `https://cluster.dashboard.marapool.com/${serverPath}/user/miners/status`,
        getFeatures: `https://cluster.dashboard.marapool.com/${serverPath}/user/features`,
        createAccount: `https://cluster.dashboard.marapool.com/${serverPath}/admin/users`,
        login: `https://cluster.dashboard.marapool.com/${serverPath}/auth/login`,
        nodeServer: `https://cluster.dashboard.marapool.com/${serverPath}`,
        strapiServer: 'https://strapi.dmgblockchain.com',
        frontEnd: `${frontEnd}`,
      },
      reloadTime: 300000, //5mins
      problemIndicators: {
        fanBroken: 'Fan Broken',
        hashboardBroken: 'Hashboard Broken',
      },
      minerStatus: {
        inRepair: 'In Repair',
        offline: 'Out of inventory/offline',
      },
    };
  } else {
    module.exports = {
      urls: {
        flaskServer: 'https://cluster.dmgblockchain.com/flask/miners',
        authentication: `https://cluster.dmgblockchain.com/${serverPath}/auth/token`,
        minerStatus: `https://cluster.dmgblockchain.com/${serverPath}/user/miners/status`,
        getFeatures: `https://cluster.dmgblockchain.com/${serverPath}/user/features`,
        createAccount: `https://cluster.dmgblockchain.com/${serverPath}/admin/users`,
        login: `https://cluster.dmgblockchain.com/${serverPath}/auth/login`,
        nodeServer: `https://cluster.dmgblockchain.com/${serverPath}`,
        strapiServer: 'https://strapi.dmgblockchain.com',
        frontEnd: `${frontEnd}`,
      },
      reloadTime: 300000, //5mins
      problemIndicators: {
        fanBroken: 'Fan Broken',
        hashboardBroken: 'Hashboard Broken',
      },
      minerStatus: {
        inRepair: 'In Repair',
        offline: 'Out of inventory/offline',
      },
    };
  }
} else {
  module.exports = {
    urls: {
      flaskServer: 'http://localhost:5000',
      nodeServer: 'http://localhost:8082/dev',
      frontEnd: 'http://localhost:3000',
      strapiServer: 'https://strapi.dmgblockchain.com',
    },
    reloadTime: 300000, //5mins
    problemIndicators: {
      fanBroken: 'Fan Broken',
      hashboardBroken: 'Hashboard Broken',
    },
    minerStatus: {
      inRepair: 'In Repair',
      offline: 'Out of inventory/offline',
    },
  };
}
