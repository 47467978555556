import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import NewCustomerModal from './NewCustomerModal.js';
import EditCustomerModal from './EditCustomerModal';
import './CustomerManagement.scss';
import EditCustomerSubMenu from './EditCustomerSubMenu.js';
import CanDisplay from '../../CanDisplay';
import DeleteCustomerModal from './DeleteCustomerModal.js';

class CustomerManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      showMenu: false,
      showSubMenu: false,
      editCustomerData: null,
      page: 0,
      pageSize: 10,
    };
  }

  showMenu = (row) => {
    this.setState({ showMenu: row }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = (event) => {
    this.setState({ showSubMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  handleOpenModal = (modal) => {
    this.setState({ displayCustomerModal: modal });
  };

  handleCloseCustomerModal = () => {
    this.setState({ displayCustomerModal: false });
  };

  handleSearchChange = (event) => {
    this.setState({
      filter: event.target.value === '' ? null : event.target.value,
    });
  };

  render() {
    let data = this.props.customers;
    if (this.state.filter !== null) {
      const filteredSearchData = data.filter((item) => {
        return Object.keys(item).some((key) => {
          if (
            item !== null &&
            key !== null &&
            item[key] !== null &&
            item[key].toString().includes(this.state.filter)
          ) {
            return item[key];
          } else return null;
        });
      });
      data = filteredSearchData;
    }
    let columns = [
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Name
            </div>
          );
        },
        accessor: 'name',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Location
            </div>
          );
        },
        accessor: 'location',
        minWidth: 113,
      },
      {
        id: 'action',
        accessor: '',
        Cell: (row) => {
          return (
            <div>
              <img
                src="/tableHamburger.png"
                id="actionRowBtn"
                alt=""
                onClick={() => {
                  this.showMenu(row);
                  this.setState({ showSubMenu: true });
                }}
              ></img>
            </div>
          );
        },
        Header: '',
        width: 45,
      },
    ];
    return (
      <div>
        <div className="filterContainer">
          <div className="searchInputContainer">
            <form className="inputBoxForm">
              <input
                className="inputBox"
                type="text"
                name="search"
                placeholder="Search for customer(s)"
                onChange={this.handleSearchChange}
              ></input>
            </form>
            <CanDisplay
              rules={this.props.rules}
              perform="add_customers"
              yes={() => (
                <button
                  className="filterAddBtn"
                  onClick={() => {
                    this.handleOpenModal('customer');
                  }}
                >
                  <span>Add Customer</span>
                </button>
              )}
            />
          </div>
          <NewCustomerModal
            isOpen={this.state.displayCustomerModal === 'customer'}
            handleCloseModal={this.handleCloseCustomerModal}
            pools={this.props.pools}
            modalType="add"
          />
        </div>
        <div className="tableContainer">
          <ReactTable
            bordered={false}
            data={data}
            columns={columns}
            defaultPageSize={10}
            pageSize={this.state.pageSize}
            onPageChange={(page) => this.setState({ page })}
            onPageSizeChange={(pageSize, page) =>
              this.setState({ page, pageSize })
            }
          />
          {this.state.showSubMenu && (
            <EditCustomerSubMenu
              customerData={this.state.showMenu}
              closeMenu={this.closeMenu}
              openEditCustomerModal={() => this.handleOpenModal('edit_row')}
              openDeleteCustomerModal={() => this.handleOpenModal('delete_row')}
              onDelete={this.props.reloadData}
              tablePage={this.state.page}
              tablePageSize={this.state.pageSize}
            />
          )}
          {this.state.showMenu.original && (
            <EditCustomerModal
              isOpen={this.state.displayCustomerModal === 'edit_row'}
              handleCloseModal={this.handleCloseCustomerModal}
              header="Customer"
              data={this.state.showMenu.original}
              pools={this.props.pools}
              customers={this.props.customers}
              customerPools={this.props.customerPools}
            />
          )}
        </div>
        {this.state.displayCustomerModal === 'delete_row' && (
          <DeleteCustomerModal
            show={this.state.displayCustomerModal === 'delete_row'}
            onHide={this.handleCloseCustomerModal}
            data={this.state.showMenu.original}
            reloadData={this.props.reloadData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, rules }) => {
  return {
    auth,
    rules,
  };
};

export default connect(mapStateToProps, {})(CustomerManagement);
