import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import TextInput from '../../TextInput';
import { connect } from 'react-redux';
import './AddOrEditPoolModal.scss';
import { createPoolRequest, updatePoolRequest } from '../../../actions';
import schema from './Schema';
import { ERROR_MSG } from '../../../constants';

class AddOrEditPoolModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendRequest = async (formValues) => {
    let newPool = {
      pool_name: formValues.minerGroupName,
      url: formValues.externalUrl,
      payout_address: formValues.payoutAddress,
      payout_threshold: formValues.payoutThreshold,
      payout_fee: formValues.payoutFee || null,
      purge_after_hours: formValues.purgeAfterHours || 0,
      source: 'luxor',
    };
    if (this.props.modalType === 'add') {
      await this.props.createPoolRequest(newPool, this.props.auth);
    } else if (this.props.modalType === 'edit') {
      newPool.pool_id = this.props.data.pool_id;
      await this.props.updatePoolRequest(newPool, this.props.auth);
    }
  };

  render() {
    let initValue = this.props.data || {};
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        className="reactModalOverRide"
        style={{
          overlay: {
            zIndex: 10,
            backgroundColor: '#FFF',
            height: 450,
            width: 576,
            border: '1px solid #4F76DB',
            boxShadow: '0 0 3px 0 #CEDCF2',
            borderRadius: 5,
            boxSizing: 'border-box',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className="modalHeader">
          <span>
            {this.props.modalType === 'add'
              ? 'Add Miner Group'
              : 'Edit Miner Group'}
          </span>
          <img src="/addPoolPopUp.png" id="addPoolIcon" alt=""></img>
          {/* JJ: this image doesnt exist. Remove? */}
        </div>

        <Formik
          initialValues={{
            minerGroupName: initValue.pool_name || '',
            externalUrl: initValue.url || '',
            payoutAddress: initValue.payout_address || '',
            payoutThreshold: initValue.payout_threshold || '',
            payoutFee: initValue.payout_fee || '',
            purgeAfterHours: initValue.purge_after_hours || '0',
            modalType: this.props.modalType,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // Formik-provided lock. When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            try {
              await this.sendRequest(values);
            } catch (e) {
              // TODO: we should have graceful error handling UI, like an error page or modal
              alert(ERROR_MSG);
            }
            resetForm();
            setSubmitting(false);
            this.props.handleCloseModal();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="modalFields">
                <TextInput
                  name="minerGroupName"
                  controlId="pool_name"
                  label="Name *"
                />
                <div className="poolInputContainer">
                  <TextInput
                    name="externalUrl"
                    controlId="url"
                    label="External URL"
                  />
                </div>
              </div>
              <div className="modalFields">
                <TextInput
                  name="payoutAddress"
                  controlId="payout_address"
                  label="Payout Address *"
                />
                <div className="poolInputContainer">
                  <TextInput
                    name="payoutThreshold"
                    controlId="payout_threshold"
                    label="Payout Threshold (BTC) *"
                  />
                </div>
              </div>
              <div className="modalFields">
                <TextInput
                  name="payoutFee"
                  controlId="payout_fee"
                  label="Pool Fee (%)"
                />
                <div className="poolInputContainer">
                  <TextInput
                    name="purgeAfterHours"
                    controlId="purge_after_hours"
                    label="Purge after `n` hours. 0 disables purge."
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  marginRight: 24,
                  marginTop: 25,
                  gap: 12,
                }}
              >
                <Button
                  variant="outline-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="filterBtnModal"
                >
                  Save
                </Button>
                <Button
                  onClick={this.props.handleCloseModal}
                  className="filterBtnModal"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

//TODO: it seems there is no need to connect the API methods. Consider simplifying.
export default connect(mapStateToProps, {
  createPoolRequest,
  updatePoolRequest,
})(AddOrEditPoolModal);
