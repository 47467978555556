import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import TextInput from '../../TextInput';
import SelectInput from '../../SelectInput';
import { connect } from 'react-redux';
import './AddEditAccountModal.scss';
import { createAccountRequest, updateAccountRequest } from '../../../actions';
import PhoneInput from 'react-phone-number-input';
import schema from './Schema';

const roles = [
  'technician',
  'mm-pool-admin',
  'mm-admin',
  'pool-admin',
  'mm-pool-viewer',
  'mm-viewer',
  'pool-viewer',
];
const mfaOptions = ['qr', 'email', 'sms'];

class AddEditAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionValues: [],
    };
  }

  componentDidMount = () => {
    //TODO: this is a slightly cleaned version of previous logic. But still too dirty to work on.
    // we should coordniate with Node side to simplify this "role" property. -JJ
    let permissionValues = [];
    this.props.rules.features.forEach((feature) => {
      let prefix = feature.slice(0, 6),
        postfix = feature.slice(7).replace(/_/g, '-');
      if (prefix === 'create' && roles.includes(postfix)) {
        permissionValues.push(postfix);
      }
    });
    this.setState({
      permissionValues,
    });
  };

  sendRequest = async (formValues) => {
    let newUser = {
      login: formValues.username,
      role_type: formValues.role,
      email: formValues.email,
      name: formValues.name,
      phone: formValues.phone,
      customer: formValues.customerName,
      mfa_type: formValues.mfa,
    };
    if (this.props.modalType === 'add') {
      await this.props.createAccountRequest(newUser, this.props.auth);
    } else if (this.props.modalType === 'edit') {
      newUser.user_id = this.props.data.user_id;
      await this.props.updateAccountRequest(newUser, this.props.auth);
    }
  };
  render() {
    let initValue = this.props.data || {};
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        className="reactModalOverRide"
        style={{
          overlay: {
            zIndex: 10,
            backgroundColor: '#FFF',
            height: 550,
            width: 576,
            border: '1px solid #4F76DB',
            boxShadow: '0 0 3px 0 #CEDCF2',
            borderRadius: 5,
            boxSizing: 'border-box',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className="modalHeader">
          {this.props.modalType === 'add' ? (
            <>
              <span>Add User</span>
              <img src="/addUserPopUp.png" id="addUserIcon" alt=""></img>
            </>
          ) : (
            <>
              <span>Edit User</span>
              <img src="/Edit.png" id="addUserIcon" alt=""></img>
            </>
          )}
        </div>
        <Formik
          initialValues={{
            username: initValue.login || '',
            customerName: initValue.customer_name || '',
            name: initValue.name || '',
            email: initValue.email || '',
            role: initValue.role_type || '',
            phone: initValue.phone || '',
            mfa: initValue.mfa_type || '',
            modalType: this.props.modalType,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            await this.sendRequest(values);
            resetForm();
            setSubmitting(false);
            this.props.reloadData(); // TODO: is this needed?
            this.props.handleCloseModal();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue, // special usage for phone input
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="modalFields">
                <SelectInput
                  name="customerName"
                  controlId="customer"
                  label="Customer *"
                  options={this.props.customers.map((item) => item.name)}
                  placeholder="Please select"
                />
                <div className="userInputContainer">
                  <TextInput name="name" controlId="name" label="Name *" />
                </div>
              </div>
              <div className="modalFields">
                <TextInput
                  name="username"
                  controlId="username"
                  label="Username *"
                />
                <div className="userInputContainer">
                  <TextInput
                    name="email"
                    controlId="email"
                    label="Email Address *"
                  />
                </div>
              </div>
              <div className="modalFields">
                <Form.Group controlId="phone">
                  <Form.Label className="fieldLabel">Phone</Form.Label>
                  <PhoneInput
                    international
                    placeholder="Enter phone number"
                    flagUrl="https://flag.pk/flags/4x3/{xx}.svg"
                    countryCallingCodeEditable={false}
                    defaultCountry="CA"
                    value={values.phone}
                    onChange={(number) => {
                      setFieldValue('phone', number);
                      touched.phone = true;
                    }}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {touched.phone && errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="userInputContainer">
                  <SelectInput
                    name="role"
                    controlId="role"
                    label="Role"
                    options={this.state.permissionValues}
                    placeholder="Please select"
                  />
                </div>
              </div>
              <div className="modalFields">
                <SelectInput
                  name="mfa"
                  controlId="mfa_type"
                  label="2 Factor Authentication"
                  options={mfaOptions}
                  placeholder="Please select"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  marginRight: 24,
                  marginTop: 25,
                  gap: 12,
                }}
              >
                <Button
                  variant="outline-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="filterBtnModal"
                >
                  Save
                </Button>
                <Button
                  onClick={this.props.handleCloseModal}
                  className="filterBtnModal"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {
  createAccountRequest,
  updateAccountRequest,
})(AddEditAccountModal);
