import React, { Component } from 'react';
import './Alerts.scss';
import AlertSettings from './AlertSettings';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  loginRequest,
  tokenLoginRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  logoutRequest,
  getUrls,
  deleteAccountsRequest,
} from '../../actions';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ALERT_TYPE } from '../../constants';
import swal from 'sweetalert2';
const config = require('../../config');

class Alerts extends Component {
  constructor() {
    super();
    this.state = {
      alertSettingsData: null,
      displayMinerSettings: false,
    };
  }

  componentDidMount = async () => {
    await this.getAlertSettingsData();
  };

  getAlertSettingsData = async () => {
    axios
      .get(config.urls.nodeServer + '/user/alerts/settings', {
        headers: {
          Authorization: this.props.auth.token,
        },
      })
      .then((response) => {
        let alertSettingsData = [];
        if ('alert_settings' in response.data) {
          alertSettingsData = response.data.alert_settings;
          alertSettingsData.forEach((data) => {
            let description;
            switch (data.alert_type) {
              case ALERT_TYPE.BLOCK_WON:
                description = 'Any block won';
                break;
              // case ALERT_TYPE.PAYOUT_SENT:
              //   description = 'Any payout sent';
              //   break;
              case ALERT_TYPE.ONLINE_WORKERS:
              case ALERT_TYPE.OFFLINE_WORKERS:
                description = `Any pool's ${data.alert_type.toLowerCase()} drops greater than ${
                  data.worker_threshold
                }% in last hour`;
                break;
              case ALERT_TYPE.HASHRATE:
                description = `Any pool's hashrate drops greater than ${data.hashrate_threshold}% in last hour`;
                break;
              default:
                description = '';
                break;
            }
            data.description = description;
          });
        }
        this.setState({
          alertSettingsData,
        });
      })
      .catch((err) => {
        console.log('Error: ', err.message);
        swal({
          title: 'Failed to retrieve alert settings',
        });
      });
  };

  render() {
    return (
      <div className="adminUI chartsSection">
        <div className="adminHeaderContainer">
          <div id="alertLabel">Alerts Management</div>
        </div>
        <div className="adminContainer">
          {this.state.alertSettingsData && (
            <Tabs defaultActiveKey="alert_settings" className="alertTabs">
              <Tab eventKey="alert_settings" title="Alert Settings">
                <AlertSettings
                  alertSettingsData={this.state.alertSettingsData}
                  reloadData={this.getAlertSettingsData}
                />
              </Tab>
            </Tabs>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, customers, rules }) => {
  return {
    auth,
    rules,
    customers,
  };
};

export default connect(mapStateToProps, {
  loginRequest,
  tokenLoginRequest,
  minerStatusRequest,
  getRulesFeaturesRequest,
  getCustomers,
  getUrls,
  logoutRequest,
  deleteAccountsRequest,
})(Alerts);
