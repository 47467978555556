import React, { Component } from 'react';
import './Profile.scss';
import topBarProfileDefault from '../../assets/TopBar-Profile-Default.png';
import editPencil from '../../assets/Profile-Edit-Default.png';

class ViewProfile extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      confirmSave: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    const { auth, editClick } = this.props;
    return (
      <div>
        <div className="view-profile">
          <div className="profile-pic-and-name">
            <button
              className="profile-picture-button"
              onClick={() => console.log('need to add pics')}
            >
              <img
                className="profile-picture"
                src={topBarProfileDefault}
                alt="profile-icon"
              />
            </button>
            <h4 className="account-name">{auth.name}</h4>
          </div>
          <div className="view-vertical-line"></div>
          <div className="edit-info">
            <div className="edit-title">
              <h4 className="header-label">General Account Information</h4>
              <img
                src={editPencil}
                alt="Edit Profile"
                className="edit-pencil"
                onClick={editClick}
              />
              <p className="edit-label" onClick={editClick}>
                Edit
              </p>
            </div>
            <div className="edit-title">
              <div className="label-input">
                <label className="profile-label">Name</label>
                <input
                  className="username-password-input"
                  id="name"
                  type="username"
                  value={auth.name}
                  onChange={this.handleChange}
                  readonly
                ></input>
              </div>
              <div className="label-input">
                <label className="profile-label">Phone</label>
                <input
                  className="username-password-input"
                  value={auth.phone}
                  type="text"
                  readonly
                ></input>
              </div>
            </div>
            <br></br>
            <div>
              <div className="label-input">
                <label className="profile-label">Email Address</label>
                <input
                  className="email-input"
                  type="text"
                  value={auth.email}
                  readonly
                ></input>
              </div>
            </div>
            <br></br>
            <div>
              <div className="label-input">
                <label className="profile-label">2FA</label>
                <input
                  className="mfa-input"
                  type="text"
                  value={auth.mfa_type}
                  readonly
                ></input>
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewProfile;
