import React, { Component } from 'react';
import './WorkerStatus.scss';
import { connect } from 'react-redux';
import MMWorkerStatus from './Mine Management';
import BlockseerWorkerStatus from './Blockseer';

class WorkerStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="infoBox">
          {this.props.rules.features.includes('view_performance_table') && (
            <BlockseerWorkerStatus
              auth={this.props.auth}
              selectedPool={this.props.selectedPool}
              selectedCustomer={this.props.selectedCustomer}
            ></BlockseerWorkerStatus>
          )}
        </div>
        <div className="infoBox">
          {this.props.rules.features.includes('view_general_table') &&
            this.props.miner_status && (
              <MMWorkerStatus
                minersData={this.props.miner_status}
                selectedPool={this.props.selectedPool}
                selectedCustomer={this.props.selectedCustomer}
              ></MMWorkerStatus>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ miner_status, rules, auth }) => {
  return {
    miner_status,
    rules,
    auth,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, {})(WorkerStatus);
