import * as d3 from 'd3';
import { mobileAndTabletCheck } from '../../../helpers';

const resetGraph = () => {
  d3.select('#poolGraph').selectAll('svg').remove();
  d3.selectAll('#tooltipLineChart').remove();
  d3.selectAll('#tooltipWorkerStatus').remove();
  d3.selectAll('#tooltipBlocksFound').remove();
  d3.selectAll('#noDataPoolGraph').remove();
};

export default (
  data,
  averageHashrate,
  showAverageHashrate,
  showBlocksFound
) => {
  if (!data.length) {
    resetGraph();
    d3.select('#poolGraph')
      .append('div')
      .attr('id', 'noDataPoolGraph')
      .text('No data found');
    return;
  }
  if (!showAverageHashrate) {
    d3.select('#averageHashrateLine').style('opacity', 0);
    d3.select('#averageHashrateLabel').style('opacity', 0);
    return;
  }
  if (!showBlocksFound) {
    d3.selectAll('.blocksFoundLine').style('opacity', 0);
    d3.selectAll('.blocksFoundLabel').style('opacity', 0);
    return;
  }
  if (data) {
    resetGraph();

    var isTooltipClicked = false;
    const isMobileAndTablet = mobileAndTabletCheck();

    var margin = { top: 30, right: 150, bottom: 70, left: 0, topMultiplier: 1.1 },
      width = 1224 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    // Set the ranges
    var x = d3.scaleTime().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);

    // Define the axes
    var xAxis = d3.axisBottom(x).ticks(9);
    var yAxis = d3.axisLeft(y).ticks(10);

    // Define the line
    var graphline = d3
      .line()
      .x(function (d) {
        return x(d.date);
      })
      .y(function (d) {
        return y(d.hashrate);
      });

    var svg = d3
      .select('#poolGraph')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + 80 + ',' + 80 + ')');

    // Scale the range of the data
    x.domain(
      d3.extent(data, function (d) {
        return d.date;
      })
    );
    y.domain([
      0,
      d3.max(data, function (d) {
        return d.hashrate;
      })*margin.topMultiplier,
    ]);

    // Nest the entries by symbol
    var dataNest = d3
      .nest()
      .key(function (d) {
        return 'Hashrate';
      })
      .entries(data);

    dataNest.forEach(function (d, i) {
      svg
        .append('path')
        .transition()
        .duration(750)
        .attr('class', 'line')
        .style('stroke', '#5E89EB')
        .attr('d', graphline(d.values));

      //Add Blocks found
      d.values.forEach((blockData) => {
        if (blockData.totalBlocksFound > 0) {
          svg
            .append('line')
            .attr('class', 'blocksFoundLine')
            .attr('x1', x(blockData.date))
            .attr('y1', 0)
            .attr('x2', x(blockData.date))
            .attr('y2', height)
            .style('stroke-width', 2)
            .style('stroke', '#FAE18A');
        }
      });
    });

    //Add average hashrate
    svg
      .append('g')
      .attr('id', 'averageHashrateLine')
      .attr(
        'transform',
        'translate(0, ' + y(parseFloat(averageHashrate.value)) + ')'
      )
      .append('line')
      .attr('x2', width)
      .style('stroke', '#D3CFFC')
      .style('stroke-width', 2);
    svg
      .append('text')
      .attr('class', 'label2')
      .attr('id', 'averageHashrateLabel')
      .attr('x', 95)
      .attr('y', y(parseFloat(averageHashrate.value)) + 15)
      .attr('text-anchor', 'middle')
      .text(`Average Hashrate: ${averageHashrate.display}`);

    // Add the X Axis
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    // Add the Y Axis
    svg.append('g').attr('class', 'y axis').call(yAxis);

    svg
      .append('text')
      .attr('class', 'label')
      .attr('x', -200)
      .attr('y', -70)
      .attr('transform', 'rotate(-90)')
      .attr('text-anchor', 'middle')
      .text('Hashrate');

    svg
      .append('g')
      .attr('class', 'grid')
      .call(yAxis.tickSize(-width).tickFormat(''));

    //Draw tooltip
    var tooltip = d3
      .select('#poolGraph')
      .append('div')
      .attr('id', 'tooltipLineChart')
      .style('display', 'none');
    var tooltipWorkerStatus = d3
      .select('#poolGraph')
      .append('div')
      .attr('id', 'tooltipWorkerStatus')
      .style('display', 'none');
    var tooltipBlocksFound = d3
      .select('#poolGraph')
      .append('div')
      .attr('id', 'tooltipBlocksFound')
      .style('display', 'none');

    var mouseG = svg.append('g').attr('class', 'mouse-over-effects');

    mouseG.append('path').attr('class', 'mouse-line').style('opacity', '0');

    var mousePerLine = mouseG
      .selectAll('.mouse-per-line')
      .data(dataNest)
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');

    mousePerLine
      .append('circle')
      .attr('r', 7)
      .style('stroke', '#5E89EB')
      .style('fill', 'white')
      .style('stroke-width', '3px')
      .style('opacity', '0');

    mouseG
      .append('svg:rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('click', function () {
        if (!isMobileAndTablet) {
          return (isTooltipClicked = !isTooltipClicked);
        }
      })
      .on('mouseout', function () {
        if (!isTooltipClicked) {
          d3.select('.mouse-line').style('opacity', '0');
          d3.selectAll('.mouse-per-line circle').style('opacity', '0');
          d3.selectAll('.mouse-per-line text').style('opacity', '0');
          d3.selectAll('#tooltipLineChart').style('display', 'none');
          d3.selectAll('#tooltipWorkerStatus').style('display', 'none');
          d3.selectAll('#tooltipBlocksFound').style('display', 'none');
        }
      })
      .on('mouseover', function () {
        d3.select('.mouse-line').style('opacity', '1');
        d3.selectAll('.mouse-per-line circle').style('opacity', '1');
        d3.selectAll('#tooltipLineChart').style('display', 'block');
        d3.selectAll('#tooltipWorkerStatus').style('display', 'block');
        d3.selectAll('#tooltipBlocksFound').style('display', 'block');
      })
      .on('mousemove', function () {
        if (!isTooltipClicked) {
          var mouse = d3.mouse(this);
          d3.selectAll('.mouse-per-line').attr('transform', function (d, i) {
            var xDate = x.invert(mouse[0]);
            var bisect = d3.bisector(function (d) {
              return d.date;
            }).left;
            var idx = bisect(d.values, xDate);
            if (d.values[idx]) {
              d3.select('.mouse-line').attr('d', function () {
                var data = 'M' + x(d.values[idx].date) + ',' + height;
                data += ' ' + x(d.values[idx].date) + ',' + 0;
                return data;
              });
              return (
                'translate(' +
                x(d.values[idx].date) +
                ',' +
                y(d.values[idx].hashrate) +
                ')'
              );
            }
          });
          updateTooltipContent(mouse, dataNest);
        }
      });

    function updateTooltipContent(mouse, dataNest) {
      let sortingObj = [];
      dataNest.map((d) => {
        var xDate = x.invert(mouse[0]);
        var bisect = d3.bisector(function (d) {
          return d.date;
        }).left;
        var idx = bisect(d.values, xDate);
        if (d.values[idx]) {
          sortingObj.push({
            key: d.values[idx].title,
            hashrate: d.values[idx].hashrate,
            date: new Date(d.values[idx].date).toLocaleString(),
            activeWorker: d.values[idx].activeWorker,
          });
        }
        return null;
      });

      sortingObj.sort(function (x, y) {
        return d3.descending(x.hashrate, y.hashrate);
      });

      var sortingArr = sortingObj.map((d) => d.key);

      var dataNest1 = dataNest.slice().sort(function (a, b) {
        return sortingArr.indexOf(a.key) - sortingArr.indexOf(b.key);
      });

      if (sortingObj.length > 0) {
        tooltip
          .html(sortingObj[0].date)
          .style('display', 'block')
          .style(
            'left',
            d3.event.pageX < 1300
              ? d3.event.pageX - 200 + 'px'
              : d3.event.pageX - 470 + 'px'
          )
          .style('top', d3.event.pageY - 100 + 'px')
          .selectAll()
          .data(dataNest1)
          .enter()
          .append('div')
          .attr('class', 'tooltipHashrateValue')
          .html((d) => {
            var xDate = x.invert(mouse[0]);
            var bisect = d3.bisector(function (d) {
              return d.date;
            }).left;
            var idx = bisect(d.values, xDate);
            if (d.values[idx]) {
              return d.values[idx].hashrateDisplay;
            }
          });
        tooltipWorkerStatus
          .html(sortingObj[0].date)
          .style('display', 'block')
          .style(
            'left',
            d3.event.pageX < 1300
              ? d3.event.pageX - 200 + 'px'
              : d3.event.pageX - 470 + 'px'
          )
          .style('top', d3.event.pageY - 10 + 'px')
          .selectAll()
          .data(dataNest1)
          .enter()
          .append('div')
          .attr('class', 'tooltipHashrateValue')
          .html((d) => {
            var xDate = x.invert(mouse[0]);
            var bisect = d3.bisector(function (d) {
              return d.date;
            }).left;
            var idx = bisect(d.values, xDate);
            if (d.values[idx]) {
              return (
                'Healthy workers: ' +
                d.values[idx].activeWorker
              );
            }
          });

        tooltipBlocksFound
          .html(sortingObj[0].date)
          .style('display', 'block')
          .style(
            'left',
            d3.event.pageX < 1300
              ? d3.event.pageX - 200 + 'px'
              : d3.event.pageX - 470 + 'px'
          )
          .style('top', d3.event.pageY + 55 + 'px')
          .selectAll()
          .data(dataNest1)
          .enter()
          .append('div')
          .attr('class', 'tooltipHashrateValue')
          .html((d) => {
            var xDate = x.invert(mouse[0]);
            var bisect = d3.bisector(function (d) {
              return d.date;
            }).left;
            var idx = bisect(d.values, xDate);
            if (d.values[idx]) {
              return 'No of Blocks found: ' + d.values[idx].totalBlocksFound;
            }
          })
          .append('ul')
          .attr('class', 'tooltipHashrateBlocksValue')
          .html((d) => {
            var xDate = x.invert(mouse[0]);
            var bisect = d3.bisector(function (d) {
              return d.date;
            }).left;
            var idx = bisect(d.values, xDate);
            if (d.values[idx]) {
              let htmlString = '';
              d.values[idx].blocksDetails.forEach((block) => {
                htmlString += `<li>Block #${block.height} <br />${new Date(
                  block.time
                ).toLocaleString()}</li>`;
              });
              return htmlString;
            }
          });
      }
    }
  }
};
