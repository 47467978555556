import React, { Component } from 'react';
import './OptionsList.scss';

class OptionsList extends Component {
  render() {
    const navBarWidth = this.props.expanded ? 250 : 150;
    const leftIndent =
      ((window.innerWidth - navBarWidth) / 4 - 10) *
      this.props.optionsListIndex;
    return (
      <div>
        <div
          className="optionsMenu"
          style={{
            left:
              window.innerWidth < 1200
                ? `${170 + leftIndent}px`
                : `${350 + leftIndent}px`,
            top: '70px',
            position: 'fixed',
          }}
        >
          {this.props.data &&
            this.props.data.map((option) => {
              return (
                <div
                  onClick={() => {
                    this.props.handleSelectedOption(
                      this.props.optionsListIndex,
                      option
                    );
                  }}
                >
                  {option.title}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default OptionsList;
