import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

const userManagementSchema = Yup.object().shape({
  modalType: Yup.string().oneOf(['add', 'edit']),
  name: Yup.string()
    .min(2, 'name must be 2-50 characters')
    .max(50, 'name must be 2-50 characters')
    .matches(/^[a-zA-Z\s]*$/, 'Please use only A-Z a-z and space')
    .matches(/^(?!\s).*[^\s]$/, 'Spaces only in between')
    .required('* Required field'),
  username: Yup.string()
    .min(3, 'Username must be a minimum of 3 characters')
    .max(320, 'Username must be 3-320 characters')
    .required('* Required field'),
  email: Yup.string()
    .email('Please put a valid Email format')
    .required('* Required field'),
  phone: Yup.string()
    .min(8, 'Please put a valid phone number')
    .test(
      'isValidPhoneNumber',
      'Please put a valid phone number',
      (x) => x && isValidPhoneNumber(x)
    ),
  customerName: Yup.string().required('* Required field'),
  role: Yup.string().required('* Required field'),
});

export default userManagementSchema;
