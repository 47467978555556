import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import TextInput from '../../TextInput';
import { connect } from 'react-redux';
import {
  createMinerSettingRequest,
  updateMinerSettingRequest,
} from '../../../actions';
import schema from './Schema';

class AddEditMinerSettingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendRequest = async (formValues) => {
    let newMinerSetting = {
      miner_type: formValues.minerType,
      hashrate: formValues.hashrate,
      temperature: formValues.temperature,
      expected_hashrate: formValues.expectedHashrate,
      hashrate_unit: formValues.hashrateUnit,
    };
    if (this.props.modalType === 'add') {
      console.log(newMinerSetting);
      await this.props.createMinerSettingRequest(
        newMinerSetting,
        this.props.auth
      );
    } else if (this.props.modalType === 'edit') {
      await this.props.updateMinerSettingRequest(
        newMinerSetting,
        this.props.auth
      );
    }
  };

  render() {
    let initValue = this.props.data || {};
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        className="reactModalOverRide"
        style={{
          overlay: {
            zIndex: 10,
            backgroundColor: '#FFF',
            height: 450,
            width: 576,
            border: '1px solid #4F76DB',
            boxShadow: '0 0 3px 0 #CEDCF2',
            borderRadius: 5,
            boxSizing: 'border-box',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className="modalHeader">
          {this.props.modalType === 'add' ? (
            <>
              <span>Add Miner Setting</span>
              <img src="/tableCopy.png" id="addUserIcon" alt=""></img>
            </>
          ) : (
            <>
              <span>Edit Miner Setting</span>
              <img src="/Edit.png" id="addUserIcon" alt=""></img>
            </>
          )}
        </div>
        <Formik
          initialValues={{
            minerType: initValue.miner_type || '',
            hashrate: initValue.hashrate || '',
            expectedHashrate: initValue.expected_hashrate || '',
            hashrateUnit: initValue.hashrate_unit || '',
            temperature: initValue.temperature || '',
            modalType: this.props.modalType,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // Formik-provided lock. When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);

            await this.sendRequest(values);
            resetForm();
            setSubmitting(false);
            this.props.handleCloseModal();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="modalFields">
                <TextInput
                  name="minerType"
                  controlId="miner_type"
                  label="Miner Type *"
                  readOnly={this.props.modalType === 'edit'}
                />
                <div className="userInputContainer">
                  <TextInput
                    name="hashrate"
                    controlId="hashrate"
                    label="Hashrate *"
                  />
                </div>
              </div>
              <div className="modalFields">
                <TextInput
                  name="temperature"
                  controlId="temperature"
                  label="Temperature *"
                />
                <div className="userInputContainer">
                  <TextInput
                    name="expectedHashrate"
                    controlId="expected_hashrate"
                    label="Expected Hashrate*"
                  />
                </div>
              </div>
              <div className="modalFields">
                <TextInput
                  name="hashrateUnit"
                  controlId="hashrate_unit"
                  label="Hashrate Unit"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  marginRight: 24,
                  marginTop: 36,
                  gap: 12,
                }}
              >
                <Button
                  variant="outline-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="filterBtnModal"
                >
                  Save
                </Button>
                <Button
                  onClick={this.props.handleCloseModal}
                  className="filterBtnModal"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ReactModal>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {
  createMinerSettingRequest,
  updateMinerSettingRequest,
})(AddEditMinerSettingModal);
