import { LOGIN } from '../actions/types';

const authReducer = (
  state = {
    permission: null,
    user_id: null,
    token: null,
    error: '',
  },
  action
) => {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    default:
      return state;
  }
};

export default authReducer;
