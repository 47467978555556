import React, { Component } from 'react';
import './BlockseerPoolGraph.scss';
import { connect } from 'react-redux';
import { getRulesFeaturesRequest, getCustomerPools } from '../../../actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReportPopup from '../../Popups/ReportPopup';
import ReportExportMenu from '../../ReportExportMenu/ReportExportMenu';
import drawPoolGraph from './drawPoolGraph';
import axios from 'axios';
import {
  transformHashrate,
  convertHashrateValueToSmallerUnit,
} from '../../../helpers';
import Loading from '../../Loading';

const moment = require('moment');
const config = require('../../../config');

class BlockseerPoolGraph extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      openReportPopup: false,
      openReportExportMenu: false,
      graphTime: 'hour',
      reportType: null,
      showAverageHashrate: true,
      showBlocksFound: true,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    const saved_token = window.localStorage.getItem('token');
    await this.getGraphData(saved_token);
  };

  componentDidUpdate = async (prevProps, prevStates) => {
    const saved_token = window.localStorage.getItem('token');
    if (
      this.state.graphTime !== prevStates.graphTime ||
      this.state.showAverageHashrate !== prevStates.showAverageHashrate ||
      this.state.showBlocksFound !== prevStates.showBlocksFound ||
      this.state.startDate !== prevStates.startDate ||
      this.state.endDate !== prevStates.endDate
    ) {
      await this.getGraphData(saved_token);
    }
  };

  getGraphData = async (token) => {
    this.setState({ isLoading: true });
    const startDate = moment(this.state.startDate, 'YYYY-MM-DD')
      .startOf('day')
      .toISOString();
    const endDate = moment(this.state.endDate, 'YYYY-MM-DD')
      .endOf('day')
      .toISOString();
    axios
      .get(
        `${config.urls.nodeServer}/user/pool_graph_data/${this.state.graphTime}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(async (res) => {
        if (res.data) {
          this.setState({ isLoading: false });
        }
        let totalHashrate = 0;
        let data = res.data.all;
        if (data.length > 0) {
          const minHashrate = data.reduce(
            (min, val) => (parseInt(val.sum) < parseInt(min) ? val.sum : min),
            data[0].sum
          );
          let smallestHashrateUnit = transformHashrate(
            minHashrate / 1000,
            'KH/s'
          ).split(' ')[1];
          const graphData = res.data.all.map((data) => {
            const date = new Date(data.dt);
            const hashrateObj = convertHashrateValueToSmallerUnit(
              data.sum,
              smallestHashrateUnit
            );
            totalHashrate += parseInt(data.sum);
            return {
              date,
              hashrate: parseFloat(hashrateObj.value),
              hashrateDisplay: hashrateObj.display,
              activeWorker: data.activeWorker,
              totalBlocksFound: data.blocksDetails.length,
              blocksDetails: data.blocksDetails,
            };
          });
          const averageHashrateObj = convertHashrateValueToSmallerUnit(
            totalHashrate / data.length,
            smallestHashrateUnit
          );
          drawPoolGraph(
            graphData,
            averageHashrateObj,
            this.state.showAverageHashrate,
            this.state.showBlocksFound
          );
        } else {
          drawPoolGraph(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeReportPopup = () => {
    this.setState({ openReportPopup: false });
  };

  closeReportExportMenu = () => {
    this.setState({ openReportExportMenu: false });
  };

  getReportType = (reportType) => {
    this.setState({
      reportType,
      openReportPopup: true,
      openReportExportMenu: false,
    });
  };

  handleCheckedBox = (event) => {
    if (event.target.value === 'averageHashrate') {
      this.setState({ showAverageHashrate: !this.state.showAverageHashrate });
    }
    if (event.target.value === 'blocksFound') {
      this.setState({ showBlocksFound: !this.state.showBlocksFound });
    }
  };

  render() {
    const { content } = this.props;
    return (
      <div className="infoBox graphBox">
        <div style={{ position: 'absolute', left: '-15rem' }}>
          {this.state.isLoading && <Loading />}
        </div>
        {/* Headline: Title + Export btn */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="infoBoxLabel">
            {content.cCompanyName} Hashrate Graph
          </div>
          <div>
            <div className="exportBtnContainer">
              <button className="exportBtn">
                <span>Export</span>
              </button>
              <ReportExportMenu getReportType={this.getReportType} />
            </div>
          </div>
        </div>

        <div className="graphOptionsContainer">
          {/* Date picker */}
          <div className="datepickerOptions">
            <div style={{ marginRight: '20px' }}>
              <div className="datePickerTitle">Start Date</div>
              <DatePicker
                minDate={new Date(this.props.minDateReport)}
                maxDate={this.state.endDate}
                selected={this.state.startDate}
                onChange={(startDate) => {
                  this.setState({ startDate });
                }}
              />
            </div>
            <div>
              <div className="datePickerTitle">End Date</div>
              <DatePicker
                minDate={this.state.startDate}
                maxDate={new Date()}
                selected={this.state.endDate}
                onChange={(endDate) => {
                  this.setState({ endDate });
                }}
              />
            </div>
          </div>
          {/* End Date picker */}

          <div className="intervalOptions">
            <div
              className="graphTime"
              selectedGraphTime={
                this.state.graphTime === '15_min' ? 'true' : 'false'
              }
              onClick={() => this.setState({ graphTime: '15_min' })}
            >
              15 Minutes
            </div>
            <div
              className="graphTime"
              selectedGraphTime={
                this.state.graphTime === 'hour' ? 'true' : 'false'
              }
              onClick={() => this.setState({ graphTime: 'hour' })}
            >
              Hour
            </div>
          </div>
        </div>
        {this.state.openReportPopup && (
          <ReportPopup
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            closeReportPopup={this.closeReportPopup}
            reportType={this.state.reportType}
          />
        )}
        <div id="poolGraph"></div>
        <div className="legend">
          <div className="legendItem">
            <span className="dot" style={{ backgroundColor: '#5E89EB' }}></span>
            Actual Hashrate
          </div>
          <div className="legendItem">
            <span className="dot" style={{ backgroundColor: '#D3CFFC' }}></span>
            <label for="averageHashrate">Average Hashrate</label>
            <input
              type="checkbox"
              id="averageHashrate"
              name="averageHashrate"
              value="averageHashrate"
              onChange={this.handleCheckedBox}
              checked={this.state.showAverageHashrate}
            />
          </div>
          <div className="legendItem">
            <span className="dot" style={{ backgroundColor: '#FAE18A' }}></span>
            <label for="blocksFound">Blocks Found</label>
            <input
              type="checkbox"
              id="blocksFound"
              name="blocksFound"
              value="blocksFound"
              onChange={this.handleCheckedBox}
              checked={this.state.showBlocksFound}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, customer_pools, rules, content }) => {
  return {
    auth,
    customer_pools,
    rules,
    content,
  };
};

export default connect(mapStateToProps, {
  getCustomerPools,
  getRulesFeaturesRequest,
})(BlockseerPoolGraph);
