import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPasswordRequest, getStrapiContent } from '../../actions';
import './ResetPasswordForm.scss';
import { isEmail } from 'validator';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class ResetPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      emailValid: false,
      content: {},
    };
  }

  componentDidMount = async () => {
    await this.props.getStrapiContent();
    this.setState({
      content: this.props.content,
    });
  };

  validateEmail = (email) => {
    return isEmail(email.toLowerCase());
  };

  onChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      emailValid: this.validateEmail(e.target.value),
    });
  };

  getErrorMessage = () => {
    let errorMessage = true;
    if (this.state.email && !this.state.emailValid) {
      errorMessage = 'Invalid email address';
    }
    return errorMessage;
  };

  validateForm = () => {
    const checkErrors = this.getErrorMessage();
    return this.state.email.length > 0 && checkErrors;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    resetPasswordRequest(this.state.email);
    this.setState({
      email: '',
    });
  };

  render() {
    const { content } = this.state;
    return (
      <div id="forget-password">
        <Helmet>
          <title>{content?.cCompanyName}</title>
          <link rel="icon" type="image/png" sizes="16x16" href={content?.cFavicon} />
        </Helmet>
        <div>
          {content?.cLogo && (
            <img
              src={content?.cLogo}
              alt="Logo-full-cropped"
              className="logoFullRestPasswordForm"
            />
          )}
        </div>
        <div className="p20">
          <p>Enter your email address below to reset your password</p>
        </div>
        <div className="input-fields">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="email">
              <Form.Control
                autoFocus
                type="email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </Form.Group>
            <Button
              type="submit"
              disabled={!this.validateForm()}
              className="loginButton"
            >
              Submit
            </Button>
            <br></br>
            <br></br>
            <Link className="link" to="/login">
              Back to Login
            </Link>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ content }) => ({
  content,
});

export default connect(mapStateToProps, {
  resetPasswordRequest,
  getStrapiContent,
})(ResetPasswordForm);
