import React, { Component } from 'react';
import * as d3 from 'd3';
import './SwitchesMapDetailsView.scss';

const colorScale = d3.scaleSequential(d3.interpolateRdYlGn).domain([100, 60]);
const performanceColorScale = d3
  .scaleSequential(d3.interpolateRdYlGn)
  .domain([0, 100]);

class SwitchesMapDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInDetailView: props.isInDetailView,
      isInDetailPhysicalView: true,
      clickedSwitchedData: props.clickedSwitchedData,
      clickedPortData: {},
      showTooltip: false,
      clickedCoreSwitchedData: props.clickedCoreSwitchedData,
      isCoreSwitchClicked: props.isCoreSwitchClicked,
      selectedFilter: props.selectedFilter,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      clickedSwitchedData: nextProps.clickedSwitchedData,
      clickedCoreSwitchedData: nextProps.clickedCoreSwitchedData,
    });
  }

  drawTooltip() {
    const portId = this.state.clickedPortData.id;
    const portData = this.state.clickedPortData.data;
    return (
      <ul
        className="portTooltip"
        physical-view={this.state.isInDetailPhysicalView ? 'true' : 'false'}
        hasData={portData.temperature ? 'true' : 'false'}
      >
        <li>
          <b>Id: </b> {portId}
        </li>
        <li>
          <b>MAC: </b> {portData.nodename}
        </li>
        <li>
          <b>IP address: </b>
          {portData.ip}
        </li>
        <li>
          <b>Customer: </b>
          {portData.customer_name !== null &&
          portData.customer_name !== undefined
            ? portData.customer_name
            : 'N/A'}{' '}
        </li>
        <li>
          <b>Type: </b> {portData.type}
        </li>
        <li>
          <b>Temperature: </b>{' '}
          {`${
            portData.temperature !== null && portData.temperature !== undefined
              ? portData.temperature
              : 'N/A'
          }\xB0C`}
        </li>
        <li>
          <b>Hashrate: </b>
          {portData.display_hashrate}
        </li>
        <li>
          <b>Expected hashrate: </b>
          {portData.expected_hashrate}
        </li>
        <li>
          <b>Performance: </b>
          {portData.hashrate_performance} %
        </li>
        <li>
          <b>No Api response: </b>
          {portData.no_api_response ? 'true' : 'false'}
        </li>
      </ul>
    );
  }

  handleOnClickedPort(portId, portData) {
    this.setState({ isPortClicked: !this.state.isPortClicked });
    if (
      this.state.clickedPortData &&
      this.state.clickedPortData.id === portId
    ) {
      this.setState({ showTooltip: !this.state.showTooltip });
    } else {
      this.setState({
        showTooltip: true,
        clickedPortData: {
          id: portId,
          data: portData,
        },
      });
    }
  }

  drawPortContent(switchId) {
    const {
      clickedSwitchedData,
      clickedCoreSwitchedData,
      isInDetailPhysicalView,
      isCoreSwitchClicked,
      selectedFilter,
    } = this.state;
    let ports = [];
    let portIndexArr = [...Array(48).keys()];
    if (
      isCoreSwitchClicked ||
      (!isInDetailPhysicalView && !isCoreSwitchClicked)
    ) {
      portIndexArr = [...Array(50).keys()];
    }
    if (!isCoreSwitchClicked) {
      const switchIdSplit = switchId.split('-');
      const switchIdChunkNumber = switchId.match(/\d+/g);
      const switchIdChunk = switchId.match(/[a-zA-Z]+/g);
      let switchIdLettersOnly = switchIdChunk[0] + '-' + switchIdChunk[1] + '1';
      if (switchIdSplit.length > 2) {
        switchIdLettersOnly = `${switchIdSplit[0]}-${switchIdSplit[1]}-${switchIdChunk[1]}1`;
      }
      let adjust =
        switchIdChunkNumber.length > 1
          ? Number(switchIdChunkNumber[1] - 1) * 64
          : Number(switchIdChunkNumber[0] - 1) * 64;

      ports = portIndexArr.map((portIndex) => {
        const i = portIndex + 1 + adjust;
        const portData =
          ((clickedSwitchedData || {})[switchId] || {})[
            `${switchIdLettersOnly}_${i}`
          ] || {};
        let portColor;
        if (portData.no_api_response) portColor = '#C0C0C0';
        else {
          if (selectedFilter === 'avgTemp' || selectedFilter === 'maxTemp')
            portColor = colorScale(portData.temperature);
          else if (
            selectedFilter === 'avgPerformance' ||
            selectedFilter === 'lowestPerformance'
          )
            portColor = performanceColorScale(portData.hashrate_performance);
        }
        return (
          <div
            key={i}
            style={{
              background: portColor ? portColor : '#eaeaea',
              color: portColor ? 'black' : '#rgba(0, 0, 0, 0.4)',
            }}
            onClick={() => {
              this.handleOnClickedPort(`${switchIdLettersOnly}_${i}`, portData);
            }}
          >
            {i - adjust}
          </div>
        );
      });
    }
    //Load core switch ports data
    else {
      ports = portIndexArr.map((portIndex) => {
        const i = portIndex + 1;
        let portColor;
        const portInfo = (clickedCoreSwitchedData || {}).port_info || {};
        if (portInfo) {
          if (portInfo.single_macs && portInfo.single_macs.includes(i - 1)) {
            portColor = 'rgb(48, 160, 84)';
          } else if (portInfo.switches && portInfo.switches.includes(i - 1)) {
            portColor = '#51A7FF';
          }
        }
        return (
          <div
            key={i}
            style={{
              background: portColor ? portColor : '#eaeaea',
              color: portColor ? 'black' : '#rgba(0, 0, 0, 0.4)',
            }}
          >
            {i}
          </div>
        );
      });
    }
    //Draw switch view (for both regular and core switch)
    if (
      isCoreSwitchClicked ||
      (!isInDetailPhysicalView && !isCoreSwitchClicked)
    ) {
      const ports1 = ports.slice(0, 16).sort((a, b) => {
        return (b.key % 2) - (a.key % 2);
      });
      const ports2 = ports.slice(16, 32).sort((a, b) => {
        return (b.key % 2) - (a.key % 2);
      });
      const ports3 = ports.slice(32, 48).sort((a, b) => {
        return (b.key % 2) - (a.key % 2);
      });
      const ports4 = ports.slice(48, 50);
      return (
        <div
          className="portContent"
          physical-view={'false'}
          core-switch={'false'}
          switch-view={'true'}
        >
          <div className="portCol">{ports1}</div>
          <div className="portCol">{ports2}</div>
          <div className="portCol">{ports3}</div>
          <div className="portCol4">{ports4}</div>
        </div>
      );
    }
    return (
      <div
        className="portContent"
        physical-view={isInDetailPhysicalView ? 'true' : 'false'}
      >
        {ports}
      </div>
    );
  }

  drawPortStacks() {
    const { clickedSwitchedData, clickedCoreSwitchedData } = this.state;
    if (
      clickedCoreSwitchedData &&
      Object.entries(clickedCoreSwitchedData).length !== 0 &&
      clickedCoreSwitchedData.constructor === Object
    ) {
      return (
        <div className="port" key={clickedCoreSwitchedData.switch}>
          <div className="portTitle">{clickedCoreSwitchedData.switch}</div>
          {this.drawPortContent(clickedCoreSwitchedData)}
        </div>
      );
    }
    if (
      clickedSwitchedData &&
      Object.entries(clickedSwitchedData).length !== 0 &&
      clickedSwitchedData.constructor === Object
    ) {
      let result = [];
      Object.keys(clickedSwitchedData).forEach((switchId, index) => {
        result.push(
          <div className="port" key={`Port_${switchId}`}>
            <div className="portTitle">{`Port_${switchId}`}</div>
            {this.drawPortContent(switchId)}
          </div>
        );
      });
      return result;
    }
  }

  render() {
    const {
      clickedSwitchedData,
      isCoreSwitchClicked,
      isInDetailView,
      selectedFilter,
      isInDetailPhysicalView,
    } = this.state;
    return (
      <div style={{ width: '1050px' }}>
        <div
          className="radioBtn"
          style={{ visibility: isInDetailView ? 'visible' : 'hidden' }}
        >
          <span>View By : </span>
          <form>
            <label className="radio">
              <input
                type="radio"
                className="hidden"
                checked={isInDetailPhysicalView === true}
                onChange={() => {
                  this.setState({
                    isInDetailPhysicalView: true,
                    showTooltip: false,
                  });
                }}
              />
              <span className="label"></span>
              <span
                className="labelText"
                isChecked={isInDetailPhysicalView ? 'true' : 'false'}
              >
                Physical View
              </span>
            </label>
            <label className="radio">
              <input
                type="radio"
                value=""
                className="hidden"
                checked={isInDetailPhysicalView === false}
                onChange={() => {
                  this.setState({
                    isInDetailPhysicalView: false,
                    showTooltip: false,
                  });
                }}
              />
              <span className="label"></span>
              <span
                className="labelText"
                isChecked={!isInDetailPhysicalView ? 'true' : 'false'}
              >
                Switch View
              </span>
            </label>
          </form>
        </div>
        <div
          className="radioBtn"
          style={{ visibility: isInDetailView ? 'visible' : 'hidden' }}
        >
          <span>Show By : </span>
          <form>
            <label className="radio">
              <input
                type="radio"
                name="filterOptions"
                className="hidden"
                value="avgTemp"
                checked={
                  selectedFilter === 'avgTemp' || selectedFilter === 'maxTemp'
                }
                onChange={(e) => {
                  this.setState({ selectedFilter: 'avgTemp' });
                  this.props.updateLegend(e);
                }}
              />
              <span className="label"></span>
              <span
                className="labelText"
                isChecked={
                  selectedFilter === 'maxTemp' || selectedFilter === 'avgTemp'
                    ? 'true'
                    : 'false'
                }
              >
                Temperature
              </span>
            </label>
            <label className="radio">
              <input
                type="radio"
                name="filterOptions"
                className="hidden"
                value="avgPerformance"
                checked={
                  this.state.selectedFilter === 'avgPerformance' ||
                  this.state.selectedFilter === 'lowestPerformance'
                }
                onChange={(e) => {
                  this.setState({ selectedFilter: 'avgPerformance' });
                  this.props.updateLegend(e);
                }}
              />
              <span className="label"></span>
              <span
                className="labelText"
                isChecked={
                  selectedFilter === 'avgPerformance' ||
                  selectedFilter === 'lowestPerformance'
                    ? 'true'
                    : 'false'
                }
              >
                Performance
              </span>
            </label>
          </form>
        </div>
        {clickedSwitchedData && !isCoreSwitchClicked ? (
          <div
            className="portView1"
            physical-view={this.state.isInDetailPhysicalView ? 'true' : 'false'}
          >
            <div className="portStacks">{this.drawPortStacks()}</div>
            <div className="tooltip1">
              {this.state.showTooltip && this.drawTooltip()}
            </div>
          </div>
        ) : (
          <div className="portView1" physical-view="false">
            <div className="portStacks" style={{ margin: '0px auto' }}>
              {this.drawPortStacks()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SwitchesMapDetailsView;
