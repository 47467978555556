import React, { Component } from 'react';
import { Route , Router , Switch} from 'react-router-dom';
import App from './App';
import ResetPassword from './components/ResetPassword';
import LoginModal from './LoginModal'
import ResetPasswordForm from './components/ResetPasswordForm';
import history from './history';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import LockedAccount from './components/Errors/LockedAccount';

class AppRouter extends Component{
    render(){
        return (
            <div>
                <Router history={history}>
                    <div>
                        <Switch>
                            <Route path='/requestResetPassword/:token' component={ResetPassword} />
                            <Route path='/requestResetPassword' component={ResetPasswordForm} />
                            <Route path='/login' component={LoginModal} />
                            <Route path='/error' component={ErrorBoundary} />
                            <Route path ='/locked' component={LockedAccount} /> 
                            <Route path='/mineManagement/:page' component={App} />
                            <Route path='/' component={LoginModal} />
                        </Switch>
                    </div>
                </Router>
            </div>
        )
    }
}

export default AppRouter;
