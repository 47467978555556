import React, { useState, useEffect } from 'react';
import './ReportModal.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
const config = require('../../config');

const ReportModal = (props) => {
  const reportDate = new Date();
  const [reportType, setReportType] = useState('');
  const [reportData, setReportData] = useState([]);
  const [filtered, setFiltered] = useState({...props.filtered})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFiltered(props.filtered)
  }, [props.filtered])

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    const user_id = window.localStorage.getItem('user_id');
    const getReportData = async (user_id, token) => {
      let bodyRequest = { reportType: reportType };
      if (reportType !== '') {
        let endpointUrl = `${config.urls.nodeServer}/user/reports/issues_workers/?issue=${reportType}`;
        if (reportType === 'power_data') {
          endpointUrl = `${config.urls.nodeServer}/user/reports/${reportType}`;
        }
        else{
          props.filtered.forEach((filter) => {
            endpointUrl += `&${filter.id}=${filter.value}`
          });
        }
        await axios
          .get(endpointUrl, {
            headers: { Authorization: token, user_id: user_id },
            data: bodyRequest,
          })
          .then((res) => {
            setReportData(res.data);
            setIsLoading(false);
          });
      }
    };
    setReportType(reportType);
    getReportData(user_id, token);
  }, [reportType, props.filter, props.filtered]);

  const handleChange = (event) => {
    setReportType(event.target.value);
    setIsLoading(true);
  };
  
  const renderReportButton = () => {
    if(isLoading){
      return <CircularProgress size="1.5vw" />
    }
    else {
      if(reportData.length === 0 || reportData[0] === null){
        return (
          <>
            <div style={{ color: `rgba(0, 0, 0, 0.4)` }}> No miners match selected criteria. </div>
            <Button variant="btn disabledBtn" >
              Export
            </Button>
          </>
        )
      }
      else {
        return (
          <CSVLink
            data={reportData}
            filename={`${reportDate.toLocaleDateString()}_${reportDate.toLocaleTimeString()}_${reportType}.csv`}
            className="btn btn-primary"
            target="_blank"
            onClick={props.hideModal}
          >
            Export
          </CSVLink>
        )
      }
    }
  }

  return (
    <>
      <Modal show={props.showModal} onHide={props.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Export Report</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label as="legend">
                <h5>Which report would you like to export?</h5>
              </Form.Label>
              <Form.Check
                type="radio"
                label="All miners with issues"
                name="reportTypes"
                value="all-issues"
                id="allMiners"
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                label="Low hashrate miners"
                name="reportTypes"
                value="Hashrate"
                id="hashrate"
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                label="High temperature miners"
                name="reportTypes"
                value="Temperature"
                id="temperature"
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                label="Fan broken miners"
                name="reportTypes"
                value="Fan-broken"
                id="fanBroken"
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                label="Non-responsive miners"
                name="reportTypes"
                value="Non-responsive"
                id="nonResponsive"
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                label="Monthly energy usage"
                name="reportTypes"
                value="power_data"
                id="powerData"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {renderReportButton()}
          <Button variant="secondary" onClick={props.hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportModal;
