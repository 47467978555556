import * as Yup from 'yup';

const customerManagementSchema = Yup.object().shape({
  modalType: Yup.string().oneOf(['add', 'edit']),
  customerName: Yup.string()
    .min(4, 'Types must be 4-20 characters')
    .max(20, 'Types must be 4-20 characters')
    .matches(/^[a-zA-Z0-9\s]*$/, 'Please use only A-Z a-z 0-9 and space')
    .matches(/^(?!\s).*[^\s]$/, 'Spaces only in between')
    .required('* Required field'),
  location: Yup.string()
    .min(2, 'Location must be 2-20 characters')
    .max(20, 'Location must be 2-20 characters')
    .matches(/^[a-zA-Z0-9_\s]*$/, 'Please use only A-Z a-z 0-9 _ and space'),
});

export default customerManagementSchema;
