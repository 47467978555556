import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import './SideNavBarSmall.scss';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  padding: 3rem 2rem 2rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #cedcf2;

  a {
    font-size: 18px;
    padding: 0.5rem 0;
    color: rgba(0, 0, 0, 0.4);
    text-decoration: none;
    text-align: center;
    transition: color 0.3s linear;

    &:hover {
      color: #343078;
    }
  }
`;
const SideNavBarSmall = ({ open }) => {
  const userRules = useSelector((state) => state.rules);
  return (
    <StyledMenu open={open}>
      <a href="/mineManagement/home">Dashboard</a>
      <a href="/mineManagement/miners">Workers</a>
      <a href="/mineManagement/report">Report</a>
      <a href="/mineManagement/alerts">Alerts</a>
      {userRules.features && userRules.features.includes('view_switch_map') && (
        <a href="/mineManagement/switchesMap">Map</a>
      )}
      {userRules.features && userRules.features.includes('view_admin_page') && (
        <a href="/mineManagement/admin">Admin</a>
      )}
    </StyledMenu>
  );
};

export default SideNavBarSmall;
