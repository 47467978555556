import React, { Component } from 'react';
import ReactTable from 'react-table';
import EditAlertSettingSubMenu from './EditAlertSettingSubMenu.js';
import { connect } from 'react-redux';
import AddOrEditAlertSettingModal from './AddOrEditAlertSettingModal.js';
import DeleteAlertSetting from './DeleteAlertSetting.js';
import swal from 'sweetalert2';
import { ALERT_TYPE } from '../../../constants';

import './AlertSettings.scss';

class AlertSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertSettingsData: props.alertSettingsData,
      alertTypeOptions: {
        [ALERT_TYPE.BLOCK_WON]: {
          value: ALERT_TYPE.BLOCK_WON,
          label: ALERT_TYPE.BLOCK_WON,
        },
        [ALERT_TYPE.HASHRATE]: {
          value: ALERT_TYPE.HASHRATE,
          label: ALERT_TYPE.HASHRATE,
        },
        [ALERT_TYPE.ONLINE_WORKERS]: {
          value: ALERT_TYPE.ONLINE_WORKERS,
          label: ALERT_TYPE.ONLINE_WORKERS,
        },
        [ALERT_TYPE.OFFLINE_WORKERS]: {
          value: ALERT_TYPE.OFFLINE_WORKERS,
          label: ALERT_TYPE.OFFLINE_WORKERS,
        },
      },
      userOptions: [],
      filteredData: null,
      filter: null,
      disabledValue: true,
      showMenu: false,
      showSubMenu: false,
      page: 0,
      pageSize: 10,
    };
  }

  componentDidMount = () => {
    this.setUserOptions();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.alertSettingsData !== this.props.alertSettingsData) {
      this.setState(
        {
          alertSettingsData: this.props.alertSettingsData,
        },
        () => {
          this.setUserOptions();
        }
      );
    }
  };

  setUserOptions = () => {
    let userAlertTypes = [];
    let userOptions = [];
    this.state.alertSettingsData.forEach((setting) => {
      if (setting['alert_type'] in this.state.alertTypeOptions) {
        userAlertTypes.push(setting['alert_type']);
      }
    });
    Object.entries(this.state.alertTypeOptions).forEach(([key, val]) => {
      if (!userAlertTypes.includes(key)) {
        userOptions.push(val);
      }
    });
    this.setState({
      userOptions,
    });
  };

  showMenu = (row) => {
    this.setState({ showMenu: row }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ showSubMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  handleOpenModal = (modal) => {
    this.setState({ displayAlertSettingModal: modal });
  };

  handleCloseAlertSettingModal = () => {
    this.setState({ displayAlertSettingModal: false });
  };

  handleSearchChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  handleResponseSuccess = (res, type) => {
    if (res.status === 200) {
      this.props.reloadData();
      swal({
        title: `Successfully ${
          type === 'edit' ? `${type}ed` : `${type}d`
        } alert settings`,
      });
    } else {
      swal({
        title: `Failed to ${type} alert settings`,
      });
    }
  };

  handleResponseError = (error, type) => {
    if (error.response) {
      console.log(error.response.data);
      if (error.response.data && 'message' in JSON.parse(error.response.data)) {
        swal({
          title: `${error.response.data.message}`,
        });
      } else {
        swal({
          title: `Unable to ${type} alert settings due to server error`,
        });
      }
      console.log(error.response.status);
    } else {
      swal({
        title: `Unable to ${type} alert settings due to server error`,
      });
      console.log('Error', error.message);
    }
  };

  render() {
    let data = null;
    const lowercasedFilter = this.state.filter ? this.state.filter : null;
    if (!this.state.filteredData) {
      data = this.state.alertSettingsData;
    } else {
      data = this.state.filteredData;
    }
    const filteredSearchData = data.filter((item) => {
      return Object.keys(item).some((key) => {
        if (
          item !== null &&
          key !== null &&
          item[key] !== null &&
          item[key].toString().includes(lowercasedFilter) &&
          lowercasedFilter !== null
        ) {
          return item[key];
        } else return null;
      });
    });
    if (filteredSearchData.length !== 0) {
      data = filteredSearchData;
    }
    let columns = [
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Name
            </div>
          );
        },
        accessor: 'alert_name',
        className: 'alertName',
        headerClassName: 'alertName',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Alert
              type
            </div>
          );
        },
        accessor: 'alert_type',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Description
            </div>
          );
        },
        accessor: 'description',
        minWidth: 250,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Last
              Updated
            </div>
          );
        },
        accessor: 'last_updated',
        minWidth: 150,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Notification Channels
            </div>
          );
        },
        accessor: 'notification_channels',
        Cell: (row) => {
          return <div>{row.original.notification_channels.join(',  ')}</div>;
        },
        minWidth: 150,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Enabled
            </div>
          );
        },
        accessor: 'enabled',
        Cell: (row) => {
          if (row.original.enabled === true) {
            return <i className="fas fa-check" id="enabledIcons"></i>;
          } else {
            return <i className="fas fa-times" id="enabledIcons"></i>;
          }
        },
        width: 113,
      },
      {
        id: 'action',
        accessor: '',
        Cell: (row) => {
          return (
            <div>
              <img
                src="/tableHamburger.png"
                id="actionRowBtn"
                alt=""
                onClick={() => {
                  this.showMenu(row);
                  this.setState({ showSubMenu: true });
                }}
              ></img>
            </div>
          );
        },
        Header: '',
        width: 45,
      },
    ];
    return (
      <div>
        <div className="filterContainer">
          <div className="searchInputContainer">
            <form className="inputBoxForm">
              <input
                className="inputBox"
                type="text"
                name="search"
                placeholder="Search for setting(s)"
                onChange={this.handleSearchChange}
              ></input>
            </form>
            <button
              className="filterAddBtn"
              disabled={this.state.userOptions.length === 0}
              onClick={() => {
                this.handleOpenModal('alert_setting');
              }}
            >
              <span>Add alert</span>
            </button>
          </div>
          <AddOrEditAlertSettingModal
            modalType="add"
            isOpen={this.state.displayAlertSettingModal === 'alert_setting'}
            userOptions={this.state.userOptions}
            handleCloseModal={this.handleCloseAlertSettingModal}
            handleResponseSuccess={this.handleResponseSuccess}
            handleResponseError={this.handleResponseError}
          />
        </div>
        <div className="tableContainer">
          <ReactTable
            bordered={false}
            data={data}
            columns={columns}
            defaultPageSize={10}
            onPageChange={(page) => this.setState({ page })}
            onPageSizeChange={(pageSize, page) =>
              this.setState({ page, pageSize })
            }
          />
          {this.state.showSubMenu && (
            <EditAlertSettingSubMenu
              alertSettingData={this.state.showMenu}
              closeMenu={this.closeMenu}
              openEditAlertSettingModal={() => this.handleOpenModal('edit_row')}
              openDeleteAlertModal={() => this.handleOpenModal('delete_row')}
              tablePage={this.state.page}
              tablePageSize={this.state.pageSize}
            />
          )}
          {this.state.showMenu.original && (
            <AddOrEditAlertSettingModal
              modalType="edit"
              isOpen={this.state.displayAlertSettingModal === 'edit_row'}
              userOptions={this.state.userOptions}
              alertTypeOptions={this.state.alertTypeOptions}
              handleCloseModal={this.handleCloseAlertSettingModal}
              data={this.state.showMenu.original}
              handleResponseSuccess={this.handleResponseSuccess}
              handleResponseError={this.handleResponseError}
            />
          )}
          {this.state.displayAlertSettingModal === 'delete_row' && (
            <DeleteAlertSetting
              show={this.state.displayAlertSettingModal === 'delete_row'}
              onHide={this.handleCloseAlertSettingModal}
              data={this.state.showMenu.original}
              handleResponseSuccess={this.handleResponseSuccess}
              handleResponseError={this.handleResponseError}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(AlertSettings);
