import * as Yup from 'yup';

const minerSettingSchema = Yup.object().shape({
  modalType: Yup.string().oneOf(['add', 'edit']),
  minerType: Yup.string()
    .min(4, 'Types must be 4-20 characters')
    .max(20, 'Types must be 4-20 characters')
    .matches(
      /^[a-zA-Z0-9-_.\s]*$/,
      'Please use only A-Z a-z 0-9 - _ . and space'
    )
    .matches(/^(?!-_.\s).*[^-_.\s]$/, 'Symbols only in between')
    .required('* Required field'),
  hashrate: Yup.string()
    .required('* Required field')
    .matches(/^[1-9][0-9]*$/, 'Please put a positive integer'),
  expectedHashrate: Yup.string()
    .required('* Required field')
    .matches(/^[1-9][0-9]*$/, 'Please put a positive integer'),
  hashrateUnit: Yup.string()
    .max(8, 'Please put a unit in valid format')
    .matches(/^[a-zA-Z0-9]+\/[a-zA-Z]+$/, 'Please put a unit in valid format'),
  temperature: Yup.string()
    .required('* Required field')
    .matches(/^([0]|[1-9][0-9]*)$/, 'Please put a positive integer or 0'),
});

export default minerSettingSchema;
