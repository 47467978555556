import React, { Component } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import './ReportPopup.scss';
import _ from 'lodash';
const config = require('../../config');
const Swal = require('sweetalert2');

class ReportPopup extends Component {
  constructor() {
    super();
    this.state = {
      reportType: _.get(this.props, 'reportType', null),
      startDate: _.get(this.props, 'startDate', new Date()),
      endDate: _.get(this.props, 'endDate', new Date()),
      reportData: [],
    };
    this.csvLink = React.createRef();
  }

  componentDidMount = async () => {
    const saved_token = window.localStorage.getItem('token');
    await this.getReportData(saved_token);
  };

  componentDidUpdate = async (prevProps) => {
    const saved_token = window.localStorage.getItem('token');
    if (this.props.reportType !== prevProps.reportType) {
      await this.getReportData(saved_token);
    }
  };

  getReportData = async (token) => {
    Swal({
      title: 'Retrieving report data..',
    });
    Swal.showLoading();
    const startDate =
      this.props.startDate.getFullYear() +
      '-' +
      (this.props.startDate.getMonth() + 1) +
      '-' +
      this.props.startDate.getDate();
    const endDate =
      this.props.endDate.getFullYear() +
      '-' +
      (this.props.endDate.getMonth() + 1) +
      '-' +
      this.props.endDate.getDate();
    axios
      .get(
        `${config.urls.nodeServer}/user/reports/${this.props.reportType}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(async (res) => {
        this.setState({ reportData: res.data }, () => {
          this.csvLink.current.link.click();
          Swal.close();
          this.props.closeReportPopup();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <CSVLink
          data={this.state.reportData}
          className="popupBtn"
          filename={`${this.props.reportType}_report.csv`}
          target="_blank"
          ref={this.csvLink}
        >
          Yes
        </CSVLink>
      </div>
    );
  }
}

export default ReportPopup;
