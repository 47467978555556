import * as Yup from 'yup';
import { ALERT_TYPE, ALERT_TYPES } from '../../../constants';

const alertSettingsSchema = Yup.object().shape({
  modalType: Yup.string().oneOf(['add', 'edit']),
  alertName: Yup.string()
    .min(4, 'Names must be 4-20 characters')
    .max(20, 'Names must be 4-20 character')
    .matches(/^[a-z0-9-]*$/, 'Please use only a-z 0-9 -')
    .required('* Required field'),
  alertType: Yup.string().oneOf(ALERT_TYPES).required('* Required field'),
  hashrateThreshold: Yup.number()
    .test(
      'range',
      'Please put a value between 5-100',
      (x) => (!x && x !== 0) || (x >= 5 && x <= 100)
    )
    .when('alertType', {
      is: ALERT_TYPE.HASHRATE,
      then: Yup.number().required('* Required field'),
    }),
  workerThreshold: Yup.number()
    .test(
      'range',
      'Please put a value between 1-100',
      (x) => (!x && x !== 0) || (x >= 1 && x <= 100)
    )
    .when('alertType', {
      is: (alertType) =>
        alertType === ALERT_TYPE.ONLINE_WORKERS ||
        alertType === ALERT_TYPE.OFFLINE_WORKERS,
      then: Yup.number().required('* Required field'),
    }),
  timeThreshold: Yup.string()
    .oneOf(['1 hours'])
    .when('alertType', {
      is: (alertType) => alertType !== ALERT_TYPE.BLOCK_WON,
      then: Yup.string().required('* Required field'),
    }),
  notificationChannels: Yup.array()
    .of(
      Yup.string().oneOf(
        ['SMS', 'Email'],
        'Please select one of the given options'
      )
    )
    .min(1, '* Please select at least one of the options'),
  phoneNumber: Yup.string().when('notificationChannels', {
    is: (value) => value[0] === 'SMS' || value[1] === 'SMS',
    then: Yup.string().required('* Please add phone number to profile'),
  }),
  enabled: Yup.boolean().oneOf([true, false]),
});

export default alertSettingsSchema;
