import React, { Component } from "react";
import QRCode from "qrcode.react";
import { getMfaType, getMfaRequest, verifyMfaRequest, logoutRequest, lockedAccountRequest } from "./actions";
import swal from "sweetalert2";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

class MfaModal extends Component {
    constructor() {
        super();
        this.state = {
            invalidCode: false,
            verification_code: "",
            mfaUri: "",
            showMfa: false,
            mfa_type: null,
            loading: false,
            progress: 0
        };
    };

    componentDidMount = async () => {
        if (!this.state.mfa_type) {
            getMfaType(this.props.loginData.token).then((data) => {
                if(data.type) {
                    this.setState({
                        mfa_type: data.type
                    }, () => {
                        this.getMfaData();
                    });
                };
            }).catch((err) => {
                console.log(err);
            });
        };
    };

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    };

    handleResendVerificationButton = async (event) => {
        event.preventDefault();
        try {
            await getMfaRequest(this.props.loginData.token, this.state.mfa_type);
            this.setState({
                loading: true
            }, () => {
                const increaseProgress = window.setInterval(() => {
                    this.setState({
                        progress: this.state.progress + 3.33
                    });
                }, 1000);
                window.setTimeout(() => {
                    this.setState({
                        loading: false,
                        progress: 0
                    }, clearInterval(increaseProgress));
                }, 30000);
            });
        } catch(err) {
            swal({
                title: "Server error, please try again later"
            });
            this.setState({invalidCode: false, loading: false, progress: 0});
        };           
    };

    handleMFASubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await this.props.verifyMfaRequest(this.state.verification_code, this.props.loginData);
            if (response.result && response.response.status === 200) {
                this.setState({invalidCode: false});
            }
        } catch(err) {
            if (!err.result && err.response.response.status === 403 && !err.response.response.data.locked_account) {
                this.setState({invalidCode: true});
            } else if (!err.result && err.response.response.status === 403 && err.response.response.data.locked_account) {
                this.props.lockedAccountRequest();
            } else {
                swal({
                    title: "Server error, please try again later"
                });
                this.setState({invalidCode: false});
            };
        };
    };

    validateMFAForm = () => {
        return this.state.verification_code.length > 0;
    };

    getMfaData = async () => {
        try {
            if (this.state.mfa_type === "qr") {
                const mfa = await getMfaRequest(this.props.loginData.token, this.state.mfa_type);
                if (mfa) {
                    this.setState({
                        mfaUri: mfa.uri
                    });
                } else {
                    throw new Error('Server error');
                };
            } else if (this.state.mfa_type === "sms" || this.state.mfa_type === "email") {
                await getMfaRequest(this.props.loginData.token, this.state.mfa_type);
            } else {
                throw new Error('Invalid 2FA type');
            };
        } catch(err) {
            swal({
                title: "Server error, please try again later"
            });
            this.props.logoutRequest();
        };
    };

    render() {
        return (
            <div style={{textAlign: 'center'}}>
                {this.state.mfa_type === "qr" &&
                    <div>
                        <p>Scan the QR code on your authenticator app</p>
                        <QRCode value={this.state.mfaUri} />
                    </div>
                }
                {(this.state.mfa_type === "sms" || this.state.mfa_type === "email") &&
                    <p>You received your verification code by {this.state.mfa_type}</p>
                }
                <Form>
                    <Form.Group controlId="verification_code">
                    <Form.Label><p className="p20" style={{marginTop: "40%"}}> Verification Code</p></Form.Label>
                    <Form.Control 
                        type="text"
                        value={this.state.verification_code} 
                        onChange={this.handleChange} 
                    />
                        <div style={{display: 'flex', justifyContent: (!this.state.invalidCode)? 'flex-end' : 'space-between', height: '41px'}}>
                            {this.state.invalidCode && 
                                <p className="errorText">Incorrect Code</p>
                            }
                        </div>
                    </Form.Group>
                    {(this.state.mfa_type === "sms" || this.state.mfa_type === "email") &&
                        <div className='mfaWrapper'>
                            <Button
                                disabled={this.state.loading}
                                onClick={this.handleResendVerificationButton}
                                className='resendMfaButton'
                            >
                                Resend verification code
                            </Button>
                            {this.state.loading && <CircularProgress variant="determinate" value={this.state.progress} thickness="5" classes={{ root: 'mfaProgressCircle' }}/>}
                        </div>
                    }
                    <Button
                        type="submit"
                        disabled={!this.validateMFAForm()}
                        onClick={this.handleMFASubmit}
                        className='mfaButton'
                    >Sign In</Button>
                    <a href='/login' style={{marginTop: "3em", fontSize: "0.8em", color: "grey"}} onClick={this.props.logoutRequest()}>Logout</a>
                </Form>
            </div>
        )
    };
};

const mapStateToProps = ({ auth }) => {
    return {
        auth
    };
};

export default connect(mapStateToProps, {verifyMfaRequest, lockedAccountRequest, logoutRequest})(MfaModal);
