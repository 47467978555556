import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import './DeleteAccountModal.scss';
import axios from 'axios';
const config = require('../../../config');

function DeleteAccountModal(props) {
  const token = useSelector((state) => state.auth.token);

  async function handleDeleteRow(data) {
    await axios
      .delete(`${config.urls.nodeServer}/admin/users`, {
        headers: { authorization: token },
        data: data,
      })
      .then((res) => {
        props.onHide();
        if (res.status === 200) {
          props.reloadData();
          swal({
            title: 'Successfully deleted account',
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status >= 400) {
          swal({
            title: err.response.data.message,
          });
        } else {
          swal({
            title: 'Failed to delete account',
          });
        }
      });
  }

  return (
    <Modal
      {...props}
      data-backdrop="static"
      data-keyboard="false"
      centered
      dialogClassName="deleteAccountModal"
    >
      <Modal.Header>
        <Modal.Title>
          Are you sure you want to delete account {props.data.name} ?
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          If you delete this account, you will permanently lose all data related
          to this account. We can't recover them once you delete.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDeleteRow(props.data)}>
          Delete Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAccountModal;
