import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import './ReportFilter.scss';
import { connect } from 'react-redux';
import {
  getRulesFeaturesRequest,
  getCustomerPools,
  getCustomers,
} from '../../../actions';

class ReportFilter extends Component {
  constructor() {
    super();
    this.state = {
      filtered: [],
      selectedPool: [],
      filteredData: null,
      workersHashrateFilteredData: null,
      isFilterApplied: false,
    };
  }

  componentDidMount = async () => {
    if (_.get(this.props, 'auth.token', false)) {
      await this.props.getCustomerPools(this.props.auth);
      await this.props.getCustomers(this.props.auth);
    }
    if (_.get(this.props, 'data', false)) {
      this.setState({ data: this.props.data }); // TODO: this is never executed? -JJ
    }
  };

  displayPoolsOptions = (pools) => {
    let optionsList = [];
    Object.values(pools).forEach((pool, id) => {
      const new_pool = {};
      new_pool['id'] = id + 1;
      new_pool['value'] = new_pool['label'] = pool['pool_name']
        ? pool['pool_name']
        : pool;
      optionsList.push(new_pool);
    });
    return optionsList;
  };

  displayCustomerOptions = (customers) => {
    let optionsList = [];
    Object.values(customers).forEach((customer, id) => {
      const new_customer = {};
      new_customer['id'] = id + 1;
      new_customer['value'] = new_customer['label'] = customer['name']
        ? customer['name']
        : customer;
      optionsList.push(new_customer);
    });
    return optionsList;
  };

  handleFilterChange = (entry) => {
    let filtered = this.state.filtered;

    this.setState(
      { selectedPool: _.union(this.state.selectedPool, entry) },
      () => {
        let isInsertNewFilter = true;
        const poolNames = entry.map((o) => o.value) || [];
        const accessor = 'pool';

        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter['id'] === accessor) {
              filter['value'] = poolNames; // when no selections, keep value an empty [] without removing the filter
              isInsertNewFilter = false;
            }
          });
        }

        if (isInsertNewFilter) {
          filtered.push({ id: accessor, value: poolNames });
        }
      }
    );

    this.setState({ filtered });
  };

  clearFilter = async () => {
    this.setState({
      filtered: [],
      selectedPool: [],
      filteredData: null,
      workersHashrateFilteredData: null,
      isFilterApplied: false,
    });
  };

  removeFilterOption = (value, accessor) => {
    let { filtered, selectedPool } = this.state;
    filtered.forEach((filter, i) => {
      if (filter.id === accessor && filter.value.includes(value)) {
        const valueIndex = filter.value.indexOf(value);
        filter.value.splice(valueIndex, 1);
        if (filter.id === 'pool') selectedPool.splice(valueIndex, 1);
      }
      if (filter.value.length === 0) filtered.splice(i, 1);
    });
    this.setState({ filtered, selectedPool });
  };

  updateDataSetBasedonFilter = () => {
    this.setState({ isFilterApplied: true });
    let filtered = this.state.filtered;

    if (filtered.length < 1) {
      this.setState({
        filteredData: null,
        workersHashrateFilteredData: null,
      });
      this.updateProps();
      return;
    }

    let workersHashrateFilteredData = null;
    //Filter worker hashrate
    const workers_hashrate = this.props.workers_hashrate;
    if (
      workers_hashrate &&
      workers_hashrate.data &&
      workers_hashrate.data.length > 0
    ) {
      const resultArr = [];
      workers_hashrate.data.forEach((item) => {
        filtered.forEach((filter) => {
          const id = filter.id;
          if (item[id] !== undefined) {
            if (filter.value.indexOf(item[id]) > -1) {
              resultArr.push(item);
            }
          }
        });
      });
      workersHashrateFilteredData = _.uniq(resultArr, 'pool');
    }
    this.setState({ workersHashrateFilteredData });

    this.updateProps();
  };

  updateProps = () => {
    this.props.handleChange(
      this.state.selectedPool,
      this.state.workersHashrateFilteredData,
      this.state.filteredData
    );
  };

  render() {
    return (
      <div>
        {this.props.customer_pools && this.props.customers && (
          <div className="filtersContainer">
            <strong>Filters</strong>

            {/* Filters */}
            <div className="minemanagementFiltersSection">
              <div className="minemanagementFilter col-2 filterOption">
                {this.state.selectedPool.length > 0 && (
                  <div className="filterNumber" id="poolFilterNumber">
                    {this.state.selectedPool.length}
                  </div>
                )}
                <Select
                  value={this.state.selectedPool}
                  onChange={(entry) => this.handleFilterChange(entry)}
                  isMulti
                  options={this.displayPoolsOptions(this.props.customer_pools)}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  placeholder="Miner Group"
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>

              <div
                className="minemanagementFilter col-3"
                style={{ marginLeft: '20px' }}
              >
                <div className="minemanagementFilterActions">
                  <div
                    className="appBtn selectedBtn"
                    onClick={() => this.updateDataSetBasedonFilter()}
                  >
                    Apply Filters
                  </div>

                  <div
                    className={
                      this.state.selectedPool.length > 0
                        ? 'appBtn clearBtn'
                        : 'appBtn disabledBtn'
                    }
                    style={{ marginLeft: '12px' }}
                    onClick={() => {
                      this.clearFilter().then(() => {
                        this.props.handleChange(this.state.selectedPool);
                      });
                    }}
                  >
                    Clear Filters
                  </div>
                </div>
              </div>
            </div>

            {/* Filters'tags */}
            {this.state.selectedPool.length > 0 && (
              <div className="tagContainer">
                <div className="tagContainer">
                  {this.state.selectedPool.map((pool) => {
                    return (
                      <div
                        className="filterTag poolTag"
                        is_filter_applied={
                          this.state.isFilterApplied ? 'true' : 'false'
                        }
                        onClick={() =>
                          this.removeFilterOption(pool.value, 'pool')
                        }
                      >
                        {pool.value} &nbsp; x
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, customer_pools, rules, customers }) => {
  return {
    auth,
    customer_pools,
    rules,
    customers,
  };
};

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, {
  getCustomerPools,
  getRulesFeaturesRequest,
  getCustomers,
})(ReportFilter);
