import React, { Component } from 'react';
import { uniqBy } from 'lodash';
import ReactTable from 'react-table';
import AddEditMinerSettingModal from './AddEditMinerSettingModal.js';
import EditMinerSettingSubMenu from './EditMinerSettingSubMenu.js';
import { connect } from 'react-redux';

class MinerSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minerSettingsData: props.minerSettingsData,
      filteredData: null,
      filter: null,
      disabledValue: true,
      showMenu: false,
      page: 0,
      pageSize: 10,
    };
  }

  displayOptions = (category) => {
    const data = this.state.minerSettingsData;
    let arr = data.map((val) => {
      return val[`${category}`];
    });
    const uniqueArr = uniqBy(arr, (e) => {
      return e;
    });
    const optionsList = uniqueArr.map((val, i) => {
      return { id: i, value: val, label: val };
    });

    return optionsList;
  };

  showMenu = (row) => {
    this.setState({ showMenu: row }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ showSubMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  handleOpenModal = (modal) => {
    this.setState({ displayMinerSettingModal: modal });
  };

  handleCloseMinerSettingModal = () => {
    this.setState({ displayMinerSettingModal: false });
  };

  handleSearchChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  render() {
    let data = null;
    const lowercasedFilter = this.state.filter ? this.state.filter : null;
    if (!this.state.filteredData) {
      data = this.state.minerSettingsData;
    } else {
      data = this.state.filteredData;
    }
    const filteredSearchData = data.filter((item) => {
      return Object.keys(item).some((key) => {
        if (
          item !== null &&
          key !== null &&
          item[key] !== null &&
          item[key].toString().includes(lowercasedFilter) &&
          lowercasedFilter !== null
        ) {
          return item[key];
        } else return null;
      });
    });
    if (filteredSearchData.length !== 0) {
      data = filteredSearchData;
    }
    let columns = [
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Miner
              Type
            </div>
          );
        },
        accessor: 'miner_type',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Temperature
            </div>
          );
        },
        accessor: 'temperature',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Hashrate
            </div>
          );
        },
        accessor: 'hashrate',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Expected Hashrate
            </div>
          );
        },
        accessor: 'expected_hashrate',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>
              Hashrate Unit
            </div>
          );
        },
        accessor: 'hashrate_unit',
        minWidth: 113,
      },
      {
        Header: () => {
          return (
            <div>
              <img id="sortIcon" src="/tableSortDefault.png" alt=""></img>Last
              Updated
            </div>
          );
        },
        accessor: 'last_updated',
        minWidth: 113,
      },
      {
        id: 'action',
        accessor: '',
        Cell: (row) => {
          return (
            <div>
              <img
                src="/tableHamburger.png"
                id="actionRowBtn"
                alt=""
                onClick={() => {
                  this.showMenu(row);
                  this.setState({ showSubMenu: true });
                }}
              ></img>
            </div>
          );
        },
        Header: '',
        width: 45,
      },
    ];
    return (
      <div>
        <div className="filterContainer">
          <div className="searchInputContainer">
            <form className="inputBoxForm">
              <input
                className="inputBox"
                type="text"
                name="search"
                placeholder="Search for setting(s)"
                onChange={this.handleSearchChange}
              ></input>
            </form>
            <button
              className="filterAddBtn"
              onClick={() => {
                this.handleOpenModal('miner_setting');
              }}
            >
              <span>Add Miner Setting</span>
            </button>
          </div>
          <AddEditMinerSettingModal
            isOpen={this.state.displayMinerSettingModal === 'miner_setting'}
            handleCloseModal={this.handleCloseMinerSettingModal}
            modalType="add"
          />
        </div>
        <div className="tableContainer">
          <ReactTable
            bordered={false}
            data={data}
            columns={columns}
            defaultPageSize={10}
            onPageChange={(page) => this.setState({ page })}
            onPageSizeChange={(pageSize, page) =>
              this.setState({ page, pageSize })
            }
          />
          {this.state.showSubMenu && (
            <EditMinerSettingSubMenu
              minerSettingData={this.state.showMenu}
              closeMenu={this.closeMenu}
              openEditMinerSettingModal={() => this.handleOpenModal('edit_row')}
              tablePage={this.state.page}
              tablePageSize={this.state.pageSize}
            />
          )}
          <AddEditMinerSettingModal
            isOpen={this.state.displayMinerSettingModal === 'edit_row'}
            handleCloseModal={this.handleCloseMinerSettingModal}
            data={this.state.showMenu.original}
            modalType="edit"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(MinerSettings);
