import React, { Component } from 'react';
import './Header.scss';
import { connect } from 'react-redux';
import {
  getRulesFeaturesRequest,
  getWorkerHashrates,
  getPoolHashrates,
  getBlockseerWorkerStatus,
  logOut,
} from '../../actions';
import workerDefault from '../../assets/Navigation-Workers-Default.png';
import topBarPoolHashrateDefault from '../../assets/TopBar-PoolHashrate-Default.png';
import topBarWorkerStatusDefault from '../../assets/TopBar-WorkerStatus-Default.png';
import topBarUserProfile from '../../assets/user.png';
import topBarProfileDefault from '../../assets/TopBar-Profile-Default.png';
import topBarProfileHover from '../../assets/TopBar-Profile-Hover.png';
import topBarEdit from '../../assets/TopBar-EditTopBar-Default.png';
import topBarEditHover from '../../assets/TopBar-EditTopBar-Hover.png';
import topBarLogout from '../../assets/TopBar-Logout-Default.png';
import topBarLogoutHover from '../../assets/TopBar-Logout-Hover.png';
import topBarShowOption from '../../assets/TopBar-ShowOption-Default.png';
import topBarShowOptionSelected from '../../assets/TopBar-ShowOption-Selected.png';
import Profile from '../Profile';
import OptionsList from './OptionsList';
import Burger from '../SideNavBurger';
import SideNavBarSmall from '../SideNavBarSmall';
import CircularProgress from '@material-ui/core/CircularProgress';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      isHover: '',
      isEditModeOn: false,
      optionsListIndex: null,
      optionsList: [
        {
          title: 'Workers Hashrate (Current)',
          value: <CircularProgress size="1.0vw" />,
          icon: workerDefault,
        },
        {
          title: 'Workers Hashrate (15 mins)',
          value: <CircularProgress size="1.0vw" />,
          icon: workerDefault,
        },
        {
          title: 'Workers Hashrate (Hour)',
          value: <CircularProgress size="1.0vw" />,
          icon: workerDefault,
        },
        {
          title: 'Workers Hashrate (Day)',
          value: <CircularProgress size="1.0vw" />,
          icon: workerDefault,
        },
        {
          title: 'Pool Hashrate (Current)',
          value: <CircularProgress size="1.0vw" />,
          icon: topBarPoolHashrateDefault,
        },
        {
          title: 'Pool Hashrate (15 mins)',
          value: <CircularProgress size="1.0vw" />,
          icon: topBarPoolHashrateDefault,
        },
        {
          title: 'Pool Hashrate (Hour)',
          value: <CircularProgress size="1.0vw" />,
          icon: topBarPoolHashrateDefault,
        },
        {
          title: 'Pool Hashrate (Day)',
          value: <CircularProgress size="1.0vw" />,
          icon: topBarPoolHashrateDefault,
        },
        {
          title: 'Reward Earned',
          value: <CircularProgress size="1.0vw" />,
          icon: topBarWorkerStatusDefault,
        },
        {
          title: 'Online Workers',
          value: <CircularProgress size="1.0vw" />,
          icon: workerDefault,
        },
      ],
      selectedOptions: {
        0: {
          title: 'Online Workers',
          value: <CircularProgress size="1.0vw" />,
          icon: workerDefault,
        },
        1: {
          title: 'Pool Hashrate (Current)',
          value: <CircularProgress size="1.0vw" />,
          icon: topBarPoolHashrateDefault,
        },
        2: {
          title: 'Worker Hashrate (Current)',
          value: <CircularProgress size="1.0vw" />,
          icon: workerDefault,
        },
      },
      openHamburgerMenu: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = async () => {
    document.addEventListener('click', this.handleClickOutside);
    if (this.props.pool_hashrate === null) {
      await this.props.getPoolHashrates(this.props.auth.token);
    }
    if (this.props.pool_status === null) {
      await this.props.getBlockseerWorkerStatus(this.props.auth.token);
    }
    if (this.props.workers_hashrate === null) {
      await this.props.getWorkerHashrates(this.props.auth.token);
    }
    const total_reward =
      this.props.pool_rewards === null
        ? 0
        : await this.getTotalRewards(this.props.pool_rewards);
    this.setState((state) => {
      let optionsList = state.optionsList;
      let selectedOptions = state.selectedOptions;
      optionsList[0].value = selectedOptions[2].value =
        this.props.workers_hashrate.total_user_current_hashrate;
      optionsList[1].value =
        this.props.workers_hashrate.total_user_last_fifteen_min_hashrate;
      optionsList[2].value =
        this.props.workers_hashrate.total_user_last_hour_hashrate;
      optionsList[3].value =
        this.props.workers_hashrate.total_user_last_day_hashrate;
      optionsList[4].value = selectedOptions[1].value =
        this.props.pool_hashrate.total_current_hashrate;
      optionsList[5].value =
        this.props.pool_hashrate.total_last_fifteen_min_hashrate;
      optionsList[6].value = this.props.pool_hashrate.total_last_hour_hashrate;
      optionsList[7].value = this.props.pool_hashrate.total_last_day_hashrate;
      optionsList[8].value = total_reward;
      optionsList[9].value = selectedOptions[0].value =
        this.props.rules.features.includes('view_performance_table')
          ? this.props.pool_status.data.healthyWorkers.length
          : this.props.minersData.healthyMiners.length;
      return {
        optionsList,
        selectedOptions,
      };
    });
  };

  componentDidUpdate(prevprops) {
    if (prevprops.workers_hashrate !== this.props.workers_hashrate) {
      this.setState((state) => {
        let optionsList = state.optionsList;
        let selectedOptions = state.selectedOptions;
        optionsList[0].value = selectedOptions[2].value =
          this.props.workers_hashrate.total_user_current_hashrate;
        optionsList[1].value =
          this.props.workers_hashrate.total_user_last_fifteen_min_hashrate;
        optionsList[2].value =
          this.props.workers_hashrate.total_user_last_hour_hashrate;
        optionsList[3].value =
          this.props.workers_hashrate.total_user_last_day_hashrate;
        return {
          optionsList,
          selectedOptions,
        };
      });
    }
    if (prevprops.pool_hashrate !== this.props.pool_hashrate) {
      this.setState((state) => {
        let optionsList = state.optionsList;
        let selectedOptions = state.selectedOptions;
        optionsList[4].value = selectedOptions[1].value =
          this.props.pool_hashrate.total_current_hashrate;
        optionsList[5].value =
          this.props.pool_hashrate.total_last_fifteen_min_hashrate;
        optionsList[6].value =
          this.props.pool_hashrate.total_last_hour_hashrate;
        optionsList[7].value = this.props.pool_hashrate.total_last_day_hashrate;
        return {
          optionsList,
          selectedOptions,
        };
      });
    }
    if (prevprops.averageTemperature !== this.props.averageTemperature) {
      this.setState((state) => {
        let optionsList = state.optionsList;
        let selectedOptions = state.selectedOptions;
        //Check if mm-users
        if (
          this.props.rules &&
          this.props.rules.features.includes(
            'view_topbar_average_temperature'
          ) &&
          optionsList.indexOf((ele) => ele.title === 'Average Temperature') < 0
        ) {
          const averageTemperature =
            this.props.averageTemperature[0].averageTemperature.toFixed(1) +
            '\xB0C';
          optionsList.push({
            title: 'Average Temperature',
            value: averageTemperature,
            icon: topBarWorkerStatusDefault,
          });
        }
        return {
          optionsList,
          selectedOptions,
        };
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  getTotalRewards = async (rewards) => {
    let totalReward = 0;
    for (var j = 0; j < rewards.length; j++) {
      let pool_name = Object.keys(rewards[j])[0];
      let reward = rewards[j][pool_name]['totalReward'];
      if (reward) {
        totalReward += reward;
      } else {
        continue;
      }
    }
    return totalReward / 100000000;
  };

  handleClickOutside(event) {
    if (event.target.className === 'profilePictureHeader') {
      return;
    }
    if (this.state.showDetails) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.setState({
          showDetails: false,
        });
      }
    }
  }

  //Option: {title: 'Managed Workers Hashrate (Current)', value: ''}
  //optionsListIndex: 0 / 1 / 2
  handleSelectedOption = (optionsListIndex, option) => {
    let { selectedOptions } = this.state;
    //set new option value
    selectedOptions[optionsListIndex] = option;
    this.setState({ selectedOptions, optionsListIndex: null });
  };

  render() {
    let { isHover, isEditModeOn, optionsListIndex, selectedOptions } =
      this.state;
    const { content } = this.props;
    return (
      <div>
        <div className="headerBar">
          {this.props.auth && (
            <div
              className="fixedTop"
              id="headerbar"
              expanded={this.props.expanded ? 'true' : 'false'}
            >
              {selectedOptions &&
                Object.keys(selectedOptions).map((i) => {
                  return (
                    <div
                      className="headerBarItem col-3"
                      key={i}
                      id="headerBarStats"
                    >
                      <img
                        src={selectedOptions[i].icon}
                        alt="worker-icon"
                        className="itemIcon"
                      />
                      <p className="itemTitle">{selectedOptions[i].title}</p>

                      <p className="itemValue">{selectedOptions[i].value}</p>
                      {/* Dropdown menu */}
                      {isEditModeOn && (
                        <img
                          src={
                            optionsListIndex === i
                              ? topBarShowOptionSelected
                              : topBarShowOption
                          }
                          alt="worker-icon"
                          className="dropdownIcon"
                          onClick={() => {
                            if (optionsListIndex === i) {
                              this.setState({ optionsListIndex: null });
                            } else this.setState({ optionsListIndex: i });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              <div
                className="appBtn selectedBtn"
                style={{
                  margin: 'auto 20px',
                  visibility: isEditModeOn ? 'visible' : 'hidden',
                }}
                onClick={() => {
                  this.setState({
                    isHover: '',
                    isEditModeOn: false,
                    optionsListIndex: null,
                  });
                }}
              >
                Save Changes
              </div>

              <div className="hamburgerMenu">
                <Burger
                  open={this.state.openHamburgerMenu}
                  setOpen={() => {
                    this.setState({
                      openHamburgerMenu: !this.state.openHamburgerMenu,
                    });
                  }}
                />
                <SideNavBarSmall open={this.state.openHamburgerMenu} />
              </div>
              <img
                src={content.cLogo}
                alt="Logo-full"
                className="logoFullMobile"
              />

              <button
                id="userInfo"
                disabled={isEditModeOn}
                ref={this.wrapperRef}
                onClick={() => {
                  this.setState({ showDetails: !this.state.showDetails });
                }}
              >
                <p id="greetingText">{`${this.props.auth.name}`}</p>
                <img
                  className="profilePictureHeader"
                  src={topBarUserProfile}
                  alt="profile-icon"
                />
              </button>

              {/* Detail Menu Lists */}
              {this.state.showDetails && (
                <div className="headerBarDetailList" ref={this.wrapperRef}>
                  <div
                    className="headerBarItem"
                    style={{ paddingTop: '10px' }}
                    onMouseEnter={() => this.setState({ isHover: 'account' })}
                    onMouseLeave={() => this.setState({ isHover: '' })}
                    onClick={() => {
                      this.setState({
                        viewProfile: true,
                        showDetails: false,
                      });
                    }}
                  >
                    <img
                      src={
                        isHover === 'account'
                          ? topBarProfileHover
                          : topBarProfileDefault
                      }
                      alt="account-icon"
                      className="itemIcon"
                      style={{
                        width: '22px',
                        height: '22px',
                        opacity: isHover === 'account' ? '100%' : '40%',
                      }}
                    ></img>
                    <p>Account</p>
                  </div>
                  <div
                    className="headerBarItem"
                    id="editTopbar"
                    onMouseEnter={() => this.setState({ isHover: 'edit' })}
                    onMouseLeave={() => this.setState({ isHover: '' })}
                    onClick={() => {
                      this.setState({ isEditModeOn: true, showDetails: false });
                    }}
                  >
                    <img
                      src={isHover === 'edit' ? topBarEditHover : topBarEdit}
                      alt="account-icon"
                      className="itemIcon"
                      style={{
                        width: '18px',
                        height: '18px',
                        opacity: isHover === 'edit' ? '100%' : '40%',
                      }}
                    ></img>
                    <p>Edit Top Bar</p>
                  </div>
                  <div
                    className="headerBarItem"
                    style={{ marginBottom: '10px' }}
                    onClick={async () => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('user_id');
                      this.props.logOut();
                    }}
                    onMouseEnter={() => this.setState({ isHover: 'logout' })}
                    onMouseLeave={() => this.setState({ isHover: '' })}
                  >
                    <img
                      src={
                        isHover === 'logout' ? topBarLogoutHover : topBarLogout
                      }
                      alt="account-icon"
                      className="itemIcon"
                      style={{
                        width: '20px',
                        height: '20px',
                        opacity: isHover === 'logout' ? '100%' : '40%',
                      }}
                    ></img>
                    <p>Log Out</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.optionsListIndex !== null && (
          <OptionsList
            data={this.state.optionsList}
            optionsListIndex={this.state.optionsListIndex}
            expanded={this.props.expanded}
            handleSelectedOption={this.handleSelectedOption}
          />
        )}

        <Profile
          show={this.state.viewProfile}
          auth={this.props.auth}
          onHide={() => this.setState({ viewProfile: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  pool_hashrate,
  pool_rewards,
  rules,
  pool_status,
  workers_hashrate,
  content,
}) => {
  return {
    auth,
    pool_hashrate,
    pool_rewards,
    rules,
    pool_status,
    workers_hashrate,
    content,
  };
};

export default connect(mapStateToProps, {
  getRulesFeaturesRequest,
  getPoolHashrates,
  getBlockseerWorkerStatus,
  getWorkerHashrates,
  logOut,
})(Header);
