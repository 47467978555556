/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  getRulesFeaturesRequest,
  getCustomerPools,
  getCustomers,
} from '../../../actions';

import '../Home.scss';
import './Filter.scss';

const ENABLE_CUSTOMER_FILTER = false;

class Filter extends Component {
  constructor() {
    super();
    this.state = {
      filtered: [],
      selectedCustomer: [],
      selectedPool: [],
      filteredData: null,
      workersHashrateFilteredData: null,
      isFilterApplied: false,
    };
  }

  componentDidMount = async () => {
    const { auth, data } = this.props;
    if (_.get(this.props, 'auth.token', false)) {
      await getCustomerPools(auth);
      await getCustomers(auth);
    }
    if (_.get(this.props, 'data', false)) {
      this.setState({ data });
    }
  };

  displayPoolsOptions = (pools) => {
    const optionsList = [];
    Object.values(pools).forEach((pool, id) => {
      const new_pool = {};
      const poolName = pool.pool_name ? pool.pool_name : pool;
      new_pool.id = id + 1;
      new_pool.value = poolName;
      new_pool.label = poolName;
      optionsList.push(new_pool);
    });
    return optionsList;
  };

  displayCustomerOptions = (customers) => {
    const optionsList = [];
    Object.values(customers).forEach((customer, id) => {
      const new_customer = {};
      const customerName = customer.name ? customer.name : customer;
      new_customer.id = id + 1;
      new_customer.value = customerName;
      new_customer.label = customerName;
      optionsList.push(new_customer);
    });
    return optionsList;
  };

  handleFilterChange = (entry, filterGroup) => {
    const { filtered, selectedCustomer, selectedPool } = this.state;

    const setFilter = (accessor) => {
      let isInsertNewFilter = true;
      const customerNames = entry.map((o) => o.value) || [];
      // const accessor = 'customer_name';

      if (filtered.length) {
        filtered.forEach((filter) => {
          if (filter.id === accessor) {
            filter.value = customerNames;
            isInsertNewFilter = false;
          }
        });
      }

      if (isInsertNewFilter) {
        filtered.push({ id: accessor, value: customerNames });
      }
    };

    if (filterGroup === 'customer') {
      this.setState(
        {
          selectedCustomer: _.union(selectedCustomer, entry),
        },
        setFilter('customer_name')
      );
    }

    if (filterGroup === 'pools') {
      this.setState(
        {
          selectedPool: _.union(selectedPool, entry),
        },
        setFilter('pool')
      );
    }

    this.setState({ filtered });
  };

  clearFilter = () => {
    this.setState(
      {
        filtered: [],
        selectedPool: [],
        selectedCustomer: [],
        filteredData: null,
        workersHashrateFilteredData: null,
        isFilterApplied: false,
      },
      () => {
        this.updateAllProps();
      }
    );
  };

  removeFilterOption = (value, accessor) => {
    const { filtered, selectedPool, selectedCustomer } = this.state;
    filtered.forEach((filter, i) => {
      if (filter.id === accessor && filter.value.includes(value)) {
        const valueIndex = filter.value.indexOf(value);
        filter.value.splice(valueIndex, 1);
        if (filter.id === 'customer_name')
          selectedCustomer.splice(valueIndex, 1);
        if (filter.id === 'pool') selectedPool.splice(valueIndex, 1);
      }
      if (filter.value.length === 0) filtered.splice(i, 1);
    });

    this.setState({ filtered, selectedCustomer, selectedPool });
  };

  updateDataSetBasedonFilter = () => {
    this.setState({ isFilterApplied: true });
    const { filtered, data } = this.state;
    const { workers_hashrate } = this.props;

    if (filtered.length < 1) {
      this.setState({
        filteredData: null,
        workersHashrateFilteredData: null,
      });
      this.updateAllProps();
      return;
    }

    if (data) {
      const allMinersObj = data.allMiners;
      const filterDict = {};
      filtered.forEach((item) => {
        filterDict[item.id] = item.value;
      });

      let resultObj = {};

      resultObj = allMinersObj.filter((miner) => {
        if (
          filterDict.customer_name &&
          filterDict.customer_name.includes(miner.customer_name)
        )
          return true;
        if (filterDict.pool && filterDict.pool.includes(miner.pool))
          return true;
        return false;
      });

      this.setState({ filteredData: resultObj });
    }

    let workersHashrateFilteredData = null;
    // Filter worker hashrate
    if (
      workers_hashrate &&
      workers_hashrate.data &&
      workers_hashrate.data.length > 0
    ) {
      const resultArr = [];
      workers_hashrate.data.forEach((item) => {
        filtered.forEach((filter) => {
          const { id } = filter;
          if (item[id] !== undefined) {
            if (filter.value.indexOf(item[id]) > -1) {
              resultArr.push(item);
            }
          }
        });
      });
      workersHashrateFilteredData = _.uniq(resultArr, 'pool');
    }
    this.setState({ workersHashrateFilteredData });

    this.updateAllProps();
  };

  updateAllProps = () => {
    const {
      selectedPool,
      selectedCustomer,
      workersHashrateFilteredData,
      filteredData,
    } = this.state;

    const { handleChange } = this.props;

    handleChange(
      selectedPool,
      selectedCustomer,
      workersHashrateFilteredData,
      filteredData
    );
  };

  render() {
    const { customer_pools, customers, rules } = this.props;

    const { selectedCustomer, selectedPool, isFilterApplied } = this.state;
    return (
      <div>
        {customer_pools && customers && (
          <div className="filtersContainer">
            <strong>Filters</strong>

            {/* Filters */}
            <div className="minemanagementFiltersSection">
              {ENABLE_CUSTOMER_FILTER && rules && rules.features.includes('create_technician') && (
                <div
                  className="minemanagementFilter col-2 filterOption"
                  style={{ marginRight: '12px' }}
                >
                  {selectedCustomer.length > 0 && (
                    <div className="filterNumber" id="customerFilterNumber">
                      {selectedCustomer.length}
                    </div>
                  )}
                  <Select
                    value={selectedCustomer}
                    onChange={(entry) =>
                      this.handleFilterChange(entry, 'customer')
                    }
                    isMulti
                    options={this.displayCustomerOptions(customers)}
                    backspaceRemovesValue={false}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    placeholder="Customer"
                    tabSelectsValue={false}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#CEDCF2',
                        primary: '#CEDCF2',
                      },
                    })}
                  />
                </div>
              )}

              <div className="minemanagementFilter col-2 filterOption">
                {selectedPool.length > 0 && (
                  <div className="filterNumber" id="poolFilterNumber">
                    {selectedPool.length}
                  </div>
                )}
                <Select
                  value={selectedPool}
                  onChange={(entry) => this.handleFilterChange(entry, 'pools')}
                  isMulti
                  options={this.displayPoolsOptions(customer_pools)}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  placeholder="Miner Group"
                  tabSelectsValue={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#CEDCF2',
                      primary: '#CEDCF2',
                    },
                  })}
                />
              </div>

              <div
                className="minemanagementFilter col-3"
                style={{ marginLeft: '20px' }}
              >
                <div className="minemanagementFilterActions">
                  <div
                    className="appBtn selectedBtn"
                    onClick={() => this.updateDataSetBasedonFilter()}
                    onKeyUp={() => this.updateDataSetBasedonFilter()}
                  >
                    Apply Filters
                  </div>

                  <div
                    className={
                      selectedCustomer.length > 0 || selectedPool.length > 0
                        ? 'appBtn clearBtn'
                        : 'appBtn disabledBtn'
                    }
                    style={{ marginLeft: '12px' }}
                    onClick={() => this.clearFilter()}
                    onKeyUp={() => this.clearFilter()}
                  >
                    Clear Filters
                  </div>
                </div>
              </div>
            </div>

            {/* Filters'tags */}
            {(selectedCustomer.length > 0 || selectedPool.length > 0) && (
              <div className="tagContainer">
                <div className="tagContainer">
                  {selectedCustomer.map((customer) => (
                    <div
                      className="filterTag customerTag"
                      key={customer.value}
                      is_filter_applied={isFilterApplied ? 'true' : 'false'}
                      onClick={() =>
                        this.removeFilterOption(customer.value, 'customer_name')
                      }
                      onKeyUp={() =>
                        this.removeFilterOption(customer.value, 'customer_name')
                      }
                    >
                      {customer.value} &nbsp; x
                    </div>
                  ))}
                </div>
                <div className="tagContainer">
                  {selectedPool.map((pool) => (
                    <div
                      className="filterTag poolTag"
                      key={pool.value}
                      is_filter_applied={isFilterApplied ? 'true' : 'false'}
                      onClick={() =>
                        this.removeFilterOption(pool.value, 'pool')
                      }
                      onKeyUp={() =>
                        this.removeFilterOption(pool.value, 'pool')
                      }
                    >
                      {pool.value} &nbsp; x
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Filter.propTypes = {
  auth: PropTypes.shape({}),
  data: PropTypes.shape({}),
  customer_pools: PropTypes.shape({}),
  customers: PropTypes.shape({}),
  rules: PropTypes.shape({
    features: PropTypes.arrayOf([]),
  }),
  handleChange: PropTypes.func.isRequired,
  workers_hashrate: PropTypes.shape({
    data: PropTypes.arrayOf([]),
  }),
};

Filter.defaultProps = {
  auth: {},
  data: {},
  customer_pools: {},
  customers: {},
  rules: {
    features: [],
  },
  workers_hashrate: {
    data: [],
  },
};

const mapStateToProps = ({ auth, customer_pools, rules, customers }) => ({
  auth,
  customer_pools,
  rules,
  customers,
});

// connects the global state to the component
// assign the info to the component's prop
export default connect(mapStateToProps, {
  getCustomerPools,
  getRulesFeaturesRequest,
  getCustomers,
})(Filter);
