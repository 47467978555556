import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap'; // to be retired soon!

/* Intended usage:
<SelectInput
  name={someKey}   name is the key in a form. You need this to access the field value 
  label={someName}    the label or title of the field
  controlId={someId}>  react-bootstrap legacy, to be retired
  options={[item1, (item2 ...)]}   here we accept 2 forms: array of string ['QC', 'ON', 'NB'], or array of objects [{value: 'QC', label: 'Quebec'}]
  placeholder={someString}
  onChange={e=>yourCallback(e)}    optional, when you want to customize behavior after editing
/>
*/
const SelectInput = ({
  controlId,
  label,
  name,
  options,
  placeholder,
  disabled,
  as,
  md,
  onChange,
}) => {
  let selectOptions = [
    <option key="" value="">
      {placeholder || ''}
    </option>,
  ];
  if (options && options.length) {
    if (typeof options[0] === 'string') {
      selectOptions = selectOptions.concat(
        options.map((item, index) => <option key={index}>{item}</option>)
      );
    } else if (options[0].value && options[0].label) {
      selectOptions = selectOptions.concat(
        options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))
      );
    }
  }
  return (
    <Field
      name={name}
      render={({
        field, // { name, value, onChange, onChange }
        form: { touched, errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const hasError = !!errors[field.name];
        const isTouched = touched[field.name];
        const chainedCallback = (e) => {
          handleChange(e);
          if (typeof onChange === 'function') {
            onChange(e);
          }
        };
        return (
          <Form.Group as={as} md={md} controlId={controlId}>
            <Form.Label className="fieldLabel">{label}</Form.Label>
            <Form.Control
              {...field}
              as="select"
              isValid={isTouched && !hasError}
              isInvalid={isTouched && hasError}
              feedback={errors[field.name]}
              className="inputBoxModal"
              disabled={disabled}
              onChange={chainedCallback}
            >
              {selectOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid" className="d-block">
              {isTouched && errors[field.name]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      }}
    />
  );
};

export default SelectInput;
