import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap'; // to be retired soon!

/* Intended usage:
<TextInput
  name={someA}    name is the 'key' of this field on the form
  label={someB}   label for display purpose
  controlId={someC}   bootstrap stuff, to be retired
  readOnly    optional, true/false
  onBlur={e=>yourCallback(e)}    optional, when you want to customize behavior after editing
>
*/
// the bootstrap Form based components are to be retired and replaced. But overall code structure is forward compatible.
// https://formik.org/docs/api/field

const TextInput = ({ controlId, label, name, readOnly, as, md, onBlur }) => {
  return (
    <Field
      name={name}
      render={({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const hasError = !!errors[field.name];
        const isTouched = touched[field.name];
        const chainedCallback = (e) => {
          handleBlur(e);
          if (typeof onBlur === 'function') {
            onBlur(e);
          }
        };
        return (
          <Form.Group as={as} md={md} controlId={controlId}>
            <Form.Label className="fieldLabel">{label}</Form.Label>
            <Form.Control
              {...field}
              type="text"
              isValid={isTouched && !hasError}
              isInvalid={isTouched && hasError}
              feedback={errors[field.name]}
              className="inputBoxModal"
              readOnly={readOnly}
              onBlur={chainedCallback}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {isTouched && errors[field.name]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      }}
    />
  );
};

export default TextInput;
